import { Row, Col, Card, Collapse } from "antd"
import "../addMedia.scss"
import { FormMediaUpload } from "./formUploadMedia";
import { FormMedia } from "./formMedia";

export const ListForm = ({ id, filename, activeComponent }) => {
    const { Panel } = Collapse;


    return (
        <Row>
            <Card style={
                { width: "100%" }
            }>
                <Col span={24}>
                    <Collapse defaultActiveKey={
                        ["1"]
                    }>
                        <Panel key={1}
                            header={activeComponent === "1" ? "Sélection de vignettes" : "image.png"}>
                            {activeComponent === "1" ? <FormMediaUpload filename={filename}
                                id={id} /> : <FormMedia />}



                        </Panel>
                    </Collapse>
                </Col>
            </Card>
        </Row>

    )
}