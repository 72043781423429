import PlayListReducer from "./features/PlayListsSlice";
import AddEditReducer from "./features/AddEditSlice";
import PaginationReducer from "./features/PaginationSlice";
import PlayerVideoReducer from "./features/PlayerVideo";
const slices = {
  playlist: PlayListReducer,
  addEdit: AddEditReducer,
  Pagination: PaginationReducer,
  PlayerVideo: PlayerVideoReducer,
};

export default slices;
