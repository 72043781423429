import { createSlice } from "@reduxjs/toolkit";
import { getPlayLists } from "../actions";
export const RecentPlaylistSlice = createSlice({
  name: "RecentPlaylist",
  initialState: {
    name: "Playlist",
    RecentPlaylistData: {},
    totalRecentPlaylistNumber: 0,
    action: false,
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getPlayLists.pending]: (state) => {
      state.action = true;
    },
    [getPlayLists.fulfilled]: (state, { payload }) => {
      state.action = false;
      state.RecentPlaylistData = payload.data;
      state.totalRecentPlaylistNumber = payload.recordsTotal;
    },
    [getPlayLists.rejected]: (state, { payload }) => {
      state.action = false;
    },
  },
});
export const {} = RecentPlaylistSlice.actions;
export default RecentPlaylistSlice.reducer;
