import history from "./router/history";
import Media from "./Medias/Media";
import ExportMedia from "./ExportMedias/ExportMedia";
import InfosMedia from "./InfosMedias/InfosMedia";
import Podcast from "./Podcast/Pages/Podcast";
import { Switch, Route, Redirect, BrowserRouter as Router } from "react-router-dom";
import "./i18n";
import { addMedia } from "./addMedia/addMedia";
import { AddPendingVideo } from "./addMedia/components/addPendingVideo";
import { EditVideo } from "./addMedia/components/editVideo";
import EditPodcast from "./Podcast/Components/addEdit/EditPodcast";
import AddPodcast from "./Podcast/Components/addEdit/AddPodcast";
import AddPodcastMain from "./Podcast/Components/addEdit/AddPodcastMain";
import ChangeVideoSource from "./addMedia/components/ChangeVideoSource";
import { AddAudioDescription } from "./AddAudioDescription/AddAudioDescription";
import { ControleMedia } from "./addMedia/controleMedia";
import PlayList from "./PlayList/PlayList";
import Dashboard from "./Dashboard/Dashboard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserData } from "./userData/userDataActions";
import i18next from 'i18next';
import { ControleUrllMedia } from "./addMedia/ControleUrllMedia";

function App() {
	const dispatch = useDispatch();
	const urlParams = new URLSearchParams(window.location.search);
	useEffect(()=>{
		const lang = urlParams.get('lang') 
	  
		  if(lang==="en" || lang==="fr"){
			i18next.changeLanguage(lang)
		  }
		},[urlParams.get('lang')])
	useEffect(() => {
		dispatch(getUserData())
	}, [])
	return (
		<div className="App">
			<Router history={history}>
				<Switch>
					<Redirect exact from="/medias" to="/ws-media/Medias" />
					<Redirect exact from="/ws-media" to="/ws-media/Medias" />
					<Redirect exact from="/" to="/ws-media/Medias" />
					<Redirect exact from="/exportMedia" to="/ws-media/exportMedia" />
					<Redirect exact from="/video/:filename/:id/add" to="/ws-media/video/:filename/:id/add" />
					<Redirect
						exact
						from="/video/change-video-source/:filename/:id/:mediaid"
						to="/ws-media/video/change-video-source/:filename/:id/:mediaid"
					/>
					<Redirect
						exact
						from="/video/:filename/:id/edit"
						to="/ws-media/video/:filename/:id/edit"
					/>
					<Redirect exact from="/video/infosMedia" to="/ws-media/video/infosMedia" />
					<Redirect
						exact
						from="/secondpageupload/video/:filename/:id"
						to="/ws-media/secondpageupload/video/:filename/:id"
					/>
					<Redirect exact from="/AddMedia" to="/ws-media/AddMedia" />
					<Redirect
						exact
						from="/addAudioDescription/:videoId/:mediaId"
						to="/ws-media/addAudioDescription/:videoId/:mediaId"
					/>
					<Redirect
						exact
						from="/editAudioDescription/:videoId/:mediaId"
						to="/ws-media/editAudioDescription/:videoId/:mediaId"
					/>
					<Redirect exact from="/secondPageAdd" to="/ws-media/secondPageAdd" />
					<Redirect exact from="/secondPageUpload" to="/ws-media/secondPageUpload" />
					<Redirect exact from="/podcast/upload" to="/ws-media/podcast/upload" />
					<Redirect exact from="/upload" to="/ws-media/upload" />
					<Redirect exact from="/edit/:params" to="/ws-media/edit/:params" />
					<Redirect exact from="/podcasts" to="/ws-media/podcasts" />
					<Redirect exact from="/playList" to="/ws-media/playList" />
					<Redirect exact from="/Dashboard" to="/ws-media/Dashboard" />
					<Route path="/ws-media/Dashboard" component={Dashboard} />
					<Route path="/ws-media/playList" component={PlayList} />
					<Route path="/ws-media/Medias" component={Media} />
					<Route path="/ws-media/podcasts/" component={Podcast} />
					<Route exact path="/ws-media/Medias" component={Media} />
					<Route exact path="/ws-media/exportMedia" component={ExportMedia} />
					<Route path="/ws-media/video/:filename/:id/edit" component={EditVideo} />
					<Route exact path="/ws-media/video/:filename/:id/add" component={AddPendingVideo} />
					<Route
						exact
						path="/ws-media/video/change-video-source/:filename/:id/:mediaid"
						component={ChangeVideoSource}
					/>
					<Route exact path="/ws-media/infosMedia" component={InfosMedia} />
					<Route
						exact
						path="/ws-media/secondpageupload/video/:filename/:id"
						component={ControleMedia}
					/>
					<Route
						exact
						path="/ws-media/pageuploadurl/video/:filename"
						component={ControleUrllMedia}
					/>

					<Route exact path="/ws-media/AddMedia" component={addMedia} />
					<Route
						exact
						path="/ws-media/addAudioDescription/:videoId/:mediaId"
						component={AddAudioDescription}
					/>
					<Route
						exact
						path="/ws-media/editAudioDescription/:videoId/:mediaId"
						component={AddAudioDescription}
					/>
					<Route exact path="/ws-media/pageuploadurl" component={ControleUrllMedia} />
					<Route exact path="/ws-media/secondPageAdd" component={ControleMedia} />
					<Route exact path="/ws-media/secondPageUpload" component={ControleMedia} />
					<Route exact path="/ws-media/podcast/upload" component={AddPodcast} />
					<Route exact path="/ws-media/upload" component={AddPodcastMain} />
					<Route exact path="/ws-media/edit/:params" component={EditPodcast} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
