import {SET_MEDIA_ID,SET_VIDEO_ID,SET_TYPE} from "./types";

const INITIAL_STATE = {
    videoId:null,
    mediaId:null,
    type:''
}

function reducerAddAudioDescription(state=INITIAL_STATE , action){

    switch (action.type){
            case SET_VIDEO_ID:
                return{
                  ...state,
                    videoId: action.payload
                }
            case SET_MEDIA_ID:
                return {
                    ...state,
                    mediaId:action.payload

                }
            case SET_TYPE:
                return{
                    ...state,
                    type:action.payload
                }    

            default:{
                return state
            }


    }

}

export default reducerAddAudioDescription;