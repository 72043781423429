import React from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import BarChartIcon from "@mui/icons-material/BarChart";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import ChatIcon from "@mui/icons-material/Chat";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const LivesActions = ({url}) => {
  const { t } = useTranslation();
  const handleClick = (path) => {
    const parentUrl = window.parent.location.href;
    const newUrl = parentUrl.replace('/dashboard', `/${path}`);
    window.parent.location.href = newUrl;
  };
  return (
    <div className="action actionModification " id="livesLong">
      <Tooltip placement="top" title={t("Dashboard.edit")}>
        <ModeEditIcon
          color="action"
          fontSize="small"
          className={"icon_action"}
          onClick={() => handleClick(url)}

        />
      </Tooltip>
      <Tooltip placement="top" title={t("Dashboard.sendInvitation")}>
        <ForwardToInboxIcon
          color="action"
          fontSize="small"
          className={"icon_action"}
          onClick={() => handleClick(url)}

        />
      </Tooltip>
      <Tooltip placement="top" title={t("Dashboard.details")}>
        <RemoveRedEyeIcon
          color="action"
          fontSize="small"
          className={"icon_action"}
          onClick={() => handleClick(url)}

        />
      </Tooltip>
      <Tooltip placement="top" title={t("Dashboard.delete")}>
        <DeleteIcon color="action" fontSize="small" className={"icon_action"} onClick={() => handleClick(url)} />
        
      </Tooltip>
      <Tooltip placement="top" title={t("Dashboard.stats")}>
        <BarChartIcon
          color="action"
          fontSize="small"
          className={"icon_action"}
          onClick={() => handleClick(url)}
        />
      </Tooltip>

      <Tooltip placement="top" title={t("Dashboard.link")}>
        <InsertLinkIcon
          color="action"
          fontSize="small"
          className={"icon_action"}
          onClick={() => handleClick(url)}
        />
      </Tooltip>
      <Tooltip placement="top" title={t("Dashboard.info")}>
        <InfoIcon color="action" fontSize="small" className={"icon_action"} onClick={() => handleClick(url)} />
      </Tooltip>
      <Tooltip placement="top" title={t("Dashboard.comments")}>
        <ChatIcon color="action" fontSize="small" className={"icon_action"} onClick={() => handleClick(url)}/>
      </Tooltip>
      <Tooltip placement="top" title={t("Dashboard.sendMail")}>
        <EmailIcon color="action" fontSize="small" className={"icon_action"} onClick={() => handleClick(url)} />
      </Tooltip>
    </div>
  );
};

export default LivesActions;
