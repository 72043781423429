import { useMutation } from "@apollo/client";
import { graphQL_shema } from "./graphQL";
import { useDispatch } from "react-redux";
import { getListVideos } from "../store/mediasActions";
export const GraphQLFetchData = () => {
	const dispatch = useDispatch();

	const [GetListMedias, { data: dataGroupsUpdated }] = useMutation(graphQL_shema().Get_All_Medias, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data) => {},
	});
	const [GetListVideos] = useMutation(graphQL_shema().Get_All_Video, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data) => {
			dispatch(getListVideos(data?.Videos.edges));
		},
	});

	const [createSubGroup] = useMutation(graphQL_shema().createGroup, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data) => {},
	});

	const [deleteSubGroup] = useMutation(graphQL_shema().deleteGroup, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data) => {},
	});

	return {
		GetListVideos,
		GetListMedias,
		dataGroupsUpdated,
		createSubGroup,
		deleteSubGroup,
	};
};
