import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { ModalConfirm } from "@empreinte/components.modal-confirm";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { DataPLaylist } from "../../Utils/Data";
import useDataTableMedia from "./DataTable";
import { Hooks } from "../../Utils/Hooks";
import { SearchBar } from "@empreinte/components.search-bar";
import { DeleteMultiple } from "../../Redux/actions";
import { useTranslation } from "react-i18next";
import { Helpers } from "../../../utils/helpers";
import { CurrentChange } from "../../Redux/features/PaginationSlice";
import { GraphQLFetchDataPlaylist } from "../../Utils/graphQLFetchData";
import { TYPES } from "../../../Podcast/utils/types";
const PlayListTable = ({
	setSearchText,
	searchText,
	setCurrentPage,
	currentPage,
	setPageSize,
	pageSize,
	setSearchWord,
	SearchWord,
	setisLoading,
	Authorized,
	setPagination,
	setTest,
}) => {
	const { elementsSelected } = useSelector((state) => ({
		...state.Pagination,
	}));
	const { acces_send } = Helpers();
	const { columns, data } = DataPLaylist();
	const dispatch = useDispatch();
	const [confirmationMultipleDelete, setConfirmationMultipleDelete] = useState(false);
	const { t } = useTranslation();
	const { DataTable } = useDataTableMedia({
		columns: columns,
		dataSource: data,
		updateEntityPath: "update-product",
	});
	const { GetPlaylists } = GraphQLFetchDataPlaylist();
	const { current } = useSelector((state) => ({ ...state.Pagination }));

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			if (Authorized) {
				setCurrentPage(1);
				dispatch(CurrentChange(1));
				setSearchWord(e.target.value);
				setPagination(false)
				dispatch({ type: TYPES.SET_SEARCH_WORD, payload: e.target.value });
				GetPlaylists({
					variables: {
						where: {
							titleContains: e.target.value,
						},
						pagination: {
							limit: pageSize,
							offset: 0,
						},
						orderBy: {
							direction: "DESC",
							field: "ID",
						},
					},
				}).finally((res) => {
					setisLoading(false);
					
				});
			}
		}
	};

	const handleSearch = (e) => {
		setSearchText(e.target.value);
		setCurrentPage(1);
		dispatch(CurrentChange(1));
	};
	useEffect(() => {
		acces_send("playlist_list_dtable_action");
	}, []);
	return (
		<div className="allllll">
			<div className="headerAlign">
				{elementsSelected?.length === 0 || elementsSelected === undefined ? null : (
					<div className="messageDelete">
						<IconButton
							onClick={() => {
								acces_send("playlist_delete_multi_action");
								setConfirmationMultipleDelete(true);
							}}
							has-permission="parameters_theme_delete_multi"
							type="submit"
							aria-label="search"
							className="delete_number"
							style={{ display: "grid", marginTop: "1rem" }}
						>
							<DeleteIcon className="gg" sx={{ cursor: "pointer", color: "black" }} />
						</IconButton>
						<div className="suppText">
							{elementsSelected?.length} {t("élément(s) sélectionné(s)")}
						</div>
					</div>
				)}
				<div className="Filter">
					{confirmationMultipleDelete ? (
						<div className="blacker">
							<ModalConfirm
								open={confirmationMultipleDelete}
								fullWidth
								onClose={() => setConfirmationMultipleDelete(false)}
								title={t("PlayList.DeleteConfirmation")}
								content={t("PlayList.DeleteConfirmationContentM")}
								CancelBtnName={t("PlayList.no")}
								onCancel={() => setConfirmationMultipleDelete(false)}
								ConfirmBtnName={t("PlayList.yes")}
								onConfirm={() => {
									dispatch(CurrentChange(1));
									dispatch(DeleteMultiple({ id: elementsSelected }));

									setConfirmationMultipleDelete(false);
								}}
							/>
						</div>
					) : (
						<></>
					)}

					<SearchBar
						className="search-bar-Ip-main"
						name={"search"}
						placeholder={t("Podcast.Search...")}
						setSearchText={(e) => setSearchText(e.target.value)}
						value={searchText}
						onKeyDown={handleKeyDown}
					></SearchBar>
				</div>
			</div>
			<DataTable
				SearchWord={SearchWord}
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				setPageSize={setPageSize}
				pageSize={pageSize}
				setPagination={setPagination}
			/>
		</div>
	);
};
export default PlayListTable;
