import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  GetRowIdOnMultiDelete,
  SetOnDelete,
  setSearchResult,
  setOnDeleteOff,
  AreTheyDoneTranscoding,
} from "../utils/action";
import "../../index.css";
import "./table.css";
import Icons from "./Icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import { Label } from "@empreinte/components.label";
import { Datagrid } from "@empreinte/components.datagrid";
import { GridNorows } from "@empreinte/components.grid-norows";
import { ClipLoader } from "@empreinte/components.clip-loader";
import { PodcastGraphQlFetchData } from "../graph-ql-podcast/GraphQlFetchData";
import {useWindowSize} from "../utils/resizeEvent.js"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
export default function ToolbarGrid({
  setCurrentPage,
  currentPage,
  pageSize,
  setPageSize,
}) {
  const { t } = useTranslation();
  const resizeWindow = useWindowSize()
  const totalPodcastData = useSelector(
    (state) => state.ReducerGetPodcastData.paginationProps.totalCounts
  );
  const MultiDeleteId = useSelector(
    (state) => state.ReducerGetPodcastData.MultiDeleteId
  );
  const handleIconClick = (e, y) => {
    // dispatch(changeOrder(e));
    // dispatch(changeDir(y));
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      key: 1,
      width: 100,
      sortable: false,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">ID</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      field: "Vignette",
      headerName: t("Podcast.Preview"),
      width: 300,
      key: 2,
      editable: false,
      sortable: false,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Podcast.Preview")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
      renderCell: (params) => (
        <img
          style={{
            width: "100px",
            height: "50px",
            imageRendering: "auto",
            margin: "7px"
          }}
          key={params.id}
          src={params.row.Vignette}
          alt=""
        />
      ),
    },
    {
      field: "Title",
      headerName: t("Podcast.Title"),
      width: 200,
      key: 3,
      editable: false,
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Podcast.Title")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      field: "Themes",
      headerName: t("Podcast.add-edit.Category"),
      width: 350,
      key: 4,
      flex: 2,
      editable: false,
      sortable: false,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Podcast.add-edit.Category")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
      renderCell: (params) => {
        return params.row?.Themes ? (
          <div className="themesPodcastAligns">
            {params.row.Themes?.map((theme) => (
              <div className="themesPodcastGrid" key={theme.ThemeId}>{theme.ThemeTitle}</div>
            ))}
          </div>
        ) : (
          "--"
        );
      },
    },
    {
      field: "Actions",
      headerName: t("Podcast.Actions"),
      sortable: false,
      width: 390,
      key: "action",
      flex: 1,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">Actions</span>
        </>
      ),
      renderCell: (params) =>
      params.row.IsReady ? (
        <Icons key={params.id} record={params.row} />
      ) : (
        <ClipLoader color="inherit" thickness={1.6} />
      ),
    },
  ];

  const mobileColum = [
    {
      field: "id",
      headerName: "ID",
      width: 60,
      key: 1,
      sortable: false,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">ID</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      field: "Vignette",
      headerName: t("Podcast.Preview"),
      width: 80,
      key: 2,
      editable: false,
      sortable: false,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Podcast.Preview")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
      renderCell: (params) => (
        <img
          style={{
            width: "50px",
            height: "30px",
            imageRendering: "auto",
            margin: "5px"

          }}
          key={params.id}
          src={params.row.Vignette}
          alt=""
        />
      ),
    },
    {
      field: "Title",
      headerName: t("Podcast.Title"),
      width: 120,
      key: 3,
      editable: false,
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Podcast.Title")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      field: "Actions",
      headerName: t("Podcast.Actions"),
      sortable: false,
      width: 390,
      key: "action",
      flex: 1,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">Actions</span>
        </>
      ),
      renderCell: (params) =>
        params.row.IsReady ? (
          <DropdownActions params={params} />
        ) : (
          <ClipLoader color="inherit" thickness={1.6} />
        ),
    },
  ];
  const tabletteColum = [
    {
      field: "id",
      headerName: "ID",
      width: 190,
      key: 1,
      sortable: false,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">ID</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      field: "Vignette",
      headerName: t("Podcast.Preview"),
      width: 300,
      key: 2,
      editable: false,
      sortable: false,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Podcast.Preview")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
      renderCell: (params) => (
        <img
          style={{
            width: "50px",
            height: "30px",
            imageRendering: "auto",
            margin: "5px"
          }}
          key={params.id}
          src={params.row.Vignette}
          alt=""
        />
      ),
    },
    {
      field: "Title",
      headerName: t("Podcast.Title"),
      width: 200,
      key: 3,
      editable: false,
      sortable: false,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Podcast.Title")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      field: "Themes",
      headerName: t("Podcast.add-edit.Category"),
      width: 350,
      key: 4,
      flex: 2,
      editable: false,
      sortable: false,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Podcast.add-edit.Category")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
      renderCell: (params) => {
        return params.row?.Themes ? (
          <div className="themesPodcastAlignsmob">
            {params.row.Themes?.map((theme) => (
              <div className="themesPodcastGrid" key={theme.ThemeId}>{theme.ThemeTitle}</div>
            ))}
          </div>
        ) : (
          "--"
        );
      },
    },
    {
      field: "Actions",
      headerName: t("Podcast.Actions"),
      sortable: false,
      width: 390,
      key: "action",
      flex: 1,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">Actions</span>
        </>
      ),
      renderCell: (params) =>
        params.row.IsReady ? (
          <DropdownActions params={params} />
        ) : (
          <ClipLoader color="inherit" thickness={1.6} />
        ),
    },
  ];

  const dispatch = useDispatch();
  const podcast_data = useSelector(
    (state) => state.ReducerGetPodcastData.podcast_data
  );
  const podcastdata = useSelector(
    (state) => state.ReducerGetPodcastData.podcastData
  );

  const podcastData = useSelector(
    (state) => state.ReducerGetPodcastData.podcastData
  );

  const podcastIdList = useSelector(
    (state) => state.ReducerGetPodcastData.IdList
  );

  const modules = useSelector((state) => state.ReducerUserData.modules);
  const isSuperAdmin = useSelector(
    (state) => state.ReducerUserData.isSuperAdmin
  );
  const { GETPODCASTLIST } = PodcastGraphQlFetchData();

  useEffect(() => {
    if (podcastIdList.length > 0) {
      dispatch(AreTheyDoneTranscoding(podcastIdList,GETPODCASTLIST));
    }
  }, [podcastIdList]);

  useEffect(() => {
    dispatch(setSearchResult(podcast_data));
  }, [podcast_data]);
  return (
    <div style={{height:"100%"}}>
      <div className="data-grid">
        {totalPodcastData === 0 ? (
          <GridNorows content={t("Podcast.No matching records found")} />
        ) : (
          <Datagrid
            getRowId={(row) => row.id}
            rows={podcastData?.map((el) => el.node)}
            columns={resizeWindow > 1379 ? columns : resizeWindow > 999 ? tabletteColum : mobileColum}
            EntriesName={`${t("Podcast.entries")}`}
            OfPageName={`${t("Podcast.of")}`}
            ShowName={`${t("Podcast.Show")}`}
            totalEntities={totalPodcastData}
            height={"100%"}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPageSize={setPageSize}
            selectionModel={MultiDeleteId}
            pageSize={pageSize}
            checkboxSelection={modules?.podcast_multi_delete || isSuperAdmin}
            checkboxSelect={(id) => {
              if (id.length >= 1) {
                dispatch(SetOnDelete());
              }
              if (id.length < 1) {
                dispatch(setOnDeleteOff());
              }
              dispatch(GetRowIdOnMultiDelete(id));
            }}
          />
        )}
      </div>
    </div>
  );
}

const DropdownActions = ({ params }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  return (
    <>
      <Label>
        <MoreVertIcon onClick={handleClick} />
      </Label>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Icons key={params.id} record={params.row} />
      </Menu>
    </>
  );
};
