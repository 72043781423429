import axios from "axios";
const search = window.location.search;
const token = localStorage.getItem("authToken");

const headers = {
    Authorization: `Bearer ${token}`,
}


const url = window.process.env.API_HOST_GLOBAL;
export const PostData = async (path, data) => {
    return await axios.post(`${url}${path}`, data, {headers});
};
export const GetData = async (path) => {
    return await axios.get(`https:${url}${path}`, {headers});
};
export const DeleteData = async (path) => {
    return await axios.delete(`${url}${path}`, {headers});
};
export const UpdateData = async (path, data) => {
    return await axios.put(`${url}${path}`, data, {headers});
};
export const upload = async (path, data) => {
    return await axios.post(`${url}${path}`, data, {headers});
};
