import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "./loader.css";
import { useTranslation } from "react-i18next";
const Loader = ({ loaderStatus }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={loaderStatus}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <br />
        <h4 className="loader-text" data-text={`${t("video.Video_processed")}`}>
          {t("video.Video_processed")}
        </h4>
      </DialogContent>
    </Dialog>
  );
};

export default Loader;
