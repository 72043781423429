import "../Dashboard.scss";
import { useTranslation } from "react-i18next";

const Stat = ({ img, color, desc, num, textColor }) => {
  const { t } = useTranslation();
  return (
    <div className={`stat ${color}`}>
      <img className="stat-img" src={`${img}`} alt={desc} />
      <div className="stat-desc">
        <p className={`stat-title ${textColor}`}>
          {t(`Dashboard.total${desc}`)}
        </p>
        <p className="stat-num">{num}</p>
      </div>
    </div>
  );
};

export default Stat;
