import React, { useEffect, useState } from 'react';
import UseActionMenu from "./actionsUserTable";
import {GridNorows} from "@empreinte/components.grid-norows"
import {Datagrid} from "@empreinte/components.datagrid"
import { useTranslation } from "react-i18next";
import { useDispatch,useSelector } from "react-redux"
import {CurrentChange,pageSizeChange,elementsSelectedChange,changeOrder, changeDir} from "../../Redux/features/PaginationSlice"
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useMediaQuery } from '@material-ui/core';
import {useWindowSize} from '../../Utils/resizeEvent'
import { useAppSelector } from '../../../redux/hooks';

function useDataTableMedia({ columns, dataSource, updateEntityPath }:any) {
  const windowSize = useWindowSize()
  const hideColumn = () => {
    return  windowSize < 950
  };
  const hideColumn2 = () => {
    return  windowSize < 700
  };
  const hideColumn3 = () => {
    return  windowSize < 550
  };
  const showPhoneColumns = useMediaQuery('(min-width:757px)');
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const { totalCount } = useSelector((state:any) => ({ ...state.Pagination }));
  const [playlistRows, setplaylistRows] = useState<any>([]);
  const dispatch = useDispatch();
  
  const handleIconClick = (e:any, y:any) => {
    dispatch(changeOrder(e));
    dispatch(changeDir(y));
  };
  useEffect(()=>{
  if(typeof dataSource?.content !== 'undefined'  ){
    const arr = Array.from(dataSource?.content,(({node} : any)=>node))
    setplaylistRows(arr)
  }
  },[dataSource?.content])

  const updatedColumns = [
    ...columns,
    {
        headerName: 'ID',
        field: 'Id',
        key: 0,
        width:showPhoneColumns ? 250 : 80,
        flex: 1,
        renderHeader: () => (
          
          <>
            <span className="colomunHeaderUnique">ID</span>
            <div className="toghetherAllArrows">
              <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
              <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            </div>
          </>
        ),
    },
    {
        headerName: t("PlayList.title"),
        field: 'title',
        key: 1,
        width:showPhoneColumns ? 400 : 200,
        flex: 1,
        hide:hideColumn2(),
        renderHeader: () => (
          <>
            <span className="colomunHeaderUnique">{t("PlayList.title")}</span>
            <div className="toghetherAllArrows">
              <ArrowDropUpIcon onClick={()=>handleIconClick(1,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
              <ArrowDropDownIcon onClick={()=>handleIconClick(1,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            </div>
          </>
        ),
    },
    {
        headerName: t("PlayList.Durationofbroadcast"),
        field: 'maxDuration',
        key: 2,
        width:showPhoneColumns ? 800 : 200,
        flex: 1,
        hide:hideColumn(),
        renderHeader: () => (
          <>
            <span className="colomunHeaderUnique">{t("PlayList.Durationofbroadcast")}</span>
            <div className="toghetherAllArrows">
              <ArrowDropUpIcon onClick={()=>handleIconClick(2,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
              <ArrowDropDownIcon onClick={()=>handleIconClick(2,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            </div>
          </>
        ),
    },
    {
        headerName: t("PlayList.Readinloop"),
        field: 'loop',
        key: 3,
        width:showPhoneColumns ? 850 : 200,
        flex: 1,
        hide:hideColumn3(),
        renderCell: (value:any) => value.row.loop ? <CheckIcon className="vert"/> : <CancelIcon className="rouge"/>,
        renderHeader: () => (
          <>
            <span className="colomunHeaderUnique">{t("PlayList.Readinloop")}</span>
            <div className="toghetherAllArrows">
              <ArrowDropUpIcon onClick={()=>handleIconClick(3,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
              <ArrowDropDownIcon onClick={()=>handleIconClick(3,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            </div>
          </>
        ),
    },
    {
        headerName: "Actions",
        key: "action",
        renderCell: (record: any, action: any) => {
            return <UseActionMenu record={record} />;
        },
        flex:1,
        width: 200,
        renderHeader: () => (
            <>
              <span className="colomunHeaderUnique">Actions</span>
            </>
        )
    },
];

  const DataTable = ({
		setCurrentPage,
		currentPage,
		setPageSize,
		pageSize,
		setPagination,
	}: any) => {
		const { current } = useSelector((state: any) => ({ ...state.Pagination }));
		return (
			<div className="DataTable">
				{playlistRows?.length === 0 ? (
					<div className="noDataGridStylingCenter">
						<GridNorows content={t("PlayList.Aucun enregistrement correspondant trouvé")} />
					</div>
				) : (
					<Datagrid
						rows={playlistRows}
						columns={updatedColumns}
						totalEntities={totalCount}
						selectionModel={selectedRowKeys}
						checkboxSelect={(id) => {
							setSelectedRowKeys(id);
							dispatch(elementsSelectedChange(id));
						}}
						checkboxSelection={true}
						EntriesName={`${t("PlayList.entries")}`}
						height="calc(100vh - 185px)"
						setCurrentPage={(e) => {
							setCurrentPage(e);
							dispatch(CurrentChange(e));
              setPagination(true)
						}}
						currentPage={current}
						setPageSize={(e) => {
							setPageSize(e);
							dispatch(pageSizeChange(e));
						}}
						pageSize={pageSize}
						className="data-grid-bit"
					/>
				)}
			</div>
		);
	};

  const DataTableWrapper = ({
		setCurrentPage,
		currentPage,
		setPageSize,
		pageSize,
		setPagination,
	}: any) => {
		return (
			<DataTable
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				setPageSize={setPageSize}
				pageSize={pageSize}
				setPagination={setPagination}
				handleIconClick={handleIconClick}
			/>
		);
	};

  return {
    DataTable: DataTableWrapper,
    selectedRowKeys,
  };
}

export default useDataTableMedia;
