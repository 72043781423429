import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { SendAccessStat } from "../Podcast/utils/action";
export const Helpers = () => {
	const dispatch = useDispatch();
	const guidGenerator = () => {
		let S4 = function () {
			return Math.random().toString(36).substring(2, 11);
		};
		return "_" + S4() + S4() + S4() + S4();
	};

	const acces_send = (action) => {
		let currentDate = new Date();
		let expireDate = new Date(currentDate.getTime() + 20 * 60 * 1000);
		let sessionId = Cookies.get("session_acces");
		if (sessionId) {
			dispatch(SendAccessStat(sessionId, action));
		} else {
			sessionId = guidGenerator();
			Cookies.set("session_acces", sessionId, { expires: expireDate });
			dispatch(SendAccessStat(sessionId, action));
		}
	};
	return {
		acces_send,
	};
};
