import  { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom'
import { useAppSelector } from "../../redux/hooks";
  import Hooks from "../utils/hooks";
import { Spin } from 'antd';

const spinnerStyles:any = {
    position:"absolute",
    left: 0,
    right: 0, 
    top:'40%',
    marginLeft: "auto", 
    marginRight: "auto",
  }
const RichMedia = () => {
  const params = useParams<any>()
  const [loadingMedia, setLoadingMedia] = useState(true)
  const {  getRichMediaUrl } = Hooks();
  const urlrichMedia = useAppSelector((state) => state.MediaReducer.urlMedia);
  useEffect(()=>{
    (async()=>{
        await getRichMediaUrl(params?.id);
        setLoadingMedia(false)
    })()
  },[params?.id])
  return (
    <>
        {loadingMedia 
      ? <Spin style={spinnerStyles} size="large" />
      : <iframe
          title={urlrichMedia}
          className="iframeVideo"
          id="iframe_list"
          allowFullScreen
          frameBorder="0"
          src={urlrichMedia}
        ></iframe>
      }
    </>
  )
}

export default RichMedia