import { useEffect, useState } from "react";
import "./PlayList.scss";
import PlayListTable from "./Components/DataList/playListTable";
import PlayListHeader from "./Components/playListHeader";
import AddEdit from "./Components/Add-Edit";
import { Hooks } from "./Utils/Hooks";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "./Components/loadingScreen";
import Notfound from "./Components/Notfound";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import { getPlayList, getModules } from "./Redux/actions";
import { getWithoutText } from "./Redux/features/PaginationSlice";
import { Loader } from "@empreinte/components.loader";
import Player from "./Components/Player";
import { setOffVideo } from "./Redux/features/PlayerVideo";
import { OnCancelAddEdit } from "./Redux/features/AddEditSlice";
import { CurrentChange } from "./Redux/features/PaginationSlice";
import eventHub from "../Event";
import {GraphQLFetchDataPlaylist} from "./Utils/graphQLFetchData"
import { useDebounce } from "./Utils/use-debounce";
import { DataPLaylist } from "./Utils/Data";
const PlayList = ({ submenu }) => {
	const { action, isLoadingData } = useSelector((state) => ({
		...state.playlist,
	}));
	const { GlobalLoading, Authorized, desc, order } = useSelector((state) => ({
		...state.Pagination,
	}));
	const { AddingPlayList, EditingPlayList } = useSelector((state) => ({
		...state.addEdit,
	}));
	const { LoadingToWatch, watching, Videos } = useSelector((state) => ({
		...state.PlayerVideo,
	}));
	const { usePrevious } = Hooks();
	const [searchText, setSearchText] = useState("");
	   const [SearchWord, setSearchWord] = useState("");

	const debouncedSearchQuery = useDebounce(searchText, 600);
	const { current } = useSelector((state) => ({ ...state.Pagination }));
	const [currentPage, setCurrentPage] = useState(current );

	const [pageSize, setPageSize] = useState(10);
	const [isLoading, setisLoading] = useState(true);
	const { search } = useLocation();
	const [liveNoLive, seLiveNoLive] = useState("s");
	const obj = new URLSearchParams(search);
	const dispatch = useDispatch();
	const {GetPlaylists} = GraphQLFetchDataPlaylist()
      const searchPlaylist = useSelector((state) => state.ReducerGetPodcastData.searchPlaylist);
	  const [pagination, setPagination] = useState(false);
	const { columns, data } = DataPLaylist();
	useEffect(() => {
		dispatch(getModules());
	}, []);
	useEffect(() => {
		if (Authorized) {
			if (searchPlaylist === "" || pagination === true) {
				const offset = (current - 1) * pageSize;
				const params = { pageSize, offset, searchText, liveNoLive, desc, order };
				getWithoutText();
				// dispatch(getPlayList({ params }));
				GetPlaylists({
					variables: {
						where: {
							titleContains: searchPlaylist,
						},
						pagination: {
							limit: pageSize,
							offset: offset,
						},
						orderBy: {
							direction: "DESC",
							field: "ID",
						},
					},
				}).finally((res) => {
					setisLoading(false);
				});
			}

		}
	}, [currentPage, current, pageSize, action, Authorized, desc, order]);

	const prevSearchText = usePrevious(searchText);

	useEffect(() => {
		const lang = obj.get("lang");
		if (lang === "en" || lang === "fr") {
			i18next.changeLanguage(lang);
		}
		    window.addEventListener("message", function (event) {
					if (event.data?.info === "updateLang") {
						i18next.changeLanguage(event.data.lang);
						localStorage.setItem("lang", event.data.lang);
					}
					
				});
	}, [obj.get("lang")]);
	useEffect(() => {
		window.addEventListener("message", function (event) {
			if (event.data?.info === "redirectHomepage") {
				dispatch(OnCancelAddEdit());
			}
		});
	}, []);

	return (
		<>
			{GlobalLoading ? (
				<LoadingScreen />
			) : Authorized ? (
				<div className={"divGlobalTablePlayListe"}>
					{!AddingPlayList && !EditingPlayList ? (
						<div>
							<Loader isOpen={LoadingToWatch} />
							{watching ? (
								LoadingToWatch ? (
									""
								) : (
									<Player Videos={Videos} setOffVideo={setOffVideo} />
								)
							) : (
								<></>
							)}
							<PlayListHeader />
							{isLoading && !searchText ? (
								<Loader isOpen={isLoading} />
							) : (
								<PlayListTable
									SearchWord={SearchWord}
									setSearchWord={setSearchWord}
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									setPageSize={setPageSize}
									pageSize={pageSize}
									setSearchText={setSearchText}
									searchText={searchText}
									setisLoading={setisLoading}
									Authorized={Authorized}
									setPagination={setPagination}
								/>
							)}
						</div>
					) : (
						<div>
							<form>
								<PlayListHeader />
								<AddEdit />
							</form>
						</div>
					)}
				</div>
			) : (
				<Notfound />
			)}
		</>
	);
};
export default PlayList;
