import { GetData, upload } from "../../APIcalls";
import { TYPES } from "./types";
import {
  GetDataSSR,
  DeletePodcastData,
  SoftDeletePodcast,
  GetDetails,
  UpdateDataPodcast,
  getVideo,
  Get_Podcast_ById,
  multiDelete,
  getSelectChoices,
  RestoreDeletedPodcast,
  AretheyDoneTranscoding,
  UploadThumbnail,
  uploadSecondThumb,
  PostPodcastData,
  multisoftDelete,
  SendAccess,
  getPlayerPodcastById
} from "./PodcastAPI_CALLS";
import { PodcastGraphQlFetchData } from "../graph-ql-podcast/GraphQlFetchData";

export const GetPodcastsData = (e) => {
  return {
    type: TYPES.GET_PODCASTS_DATA,
    payload: e,
  };
};

export const GetExportLinks = (e) => {
  return {
    type: TYPES.GET_EXPORT_LINKS,
    payload: e,
  };
};

export const UpdatePodcastData = (e) => {
  return {
    type: TYPES.UPDATE_PODCASTS_DATA,
    payload: e,
  };
};

export const GetPlayerByID = (id) => async(dispatch) => {
  getPlayerPodcastById(id).then((response) => {
    if (response.data.code === 200){
      dispatch({
        type: TYPES.GET_PLAYER_BY_ID,
        payload: response.data,
      });
      dispatch({ type: TYPES.ON_LOAD_PREVIEW, payload: false });
    }
  });
};

export const PodcastCondition = (e) => {
  return {
    type: TYPES.SET_PODCAST_CONDITION,
    payload: e,
  };
};

export const setPaginationProps = (e) => {
  return {
    type: TYPES.SET_PAGINATION_PROPS,
    payload: e,
  };
};

export const PodcastConditionAdd = (e) => {
  return {
    type: TYPES.SET_PODCAST_CONDITION_ADD,
    payload: e,
  };
};

export const setPodcastThemes = (e) => {
  return {
    type: TYPES.SET_PODCAST_THEMES,
    payload: e,
  };
};

export const setPodcastItems = (e) => {
  return {
    type: TYPES.SET_PODCAST_ITEMS,
    payload: e,
  };
};

export const PodcastExportLinks = (e) => {
  return {
    type: TYPES.SET_PODCAST_EXPORT_LINK,
    payload: e,
  };
};

export const PodcastExportPodcast = (e) => {
  return {
    type: TYPES.SET_PODCAST_EXPORT_PODCAST,
    payload: e,
  };
};

export const AddPodcast = (e) => {
  return {
    type: TYPES.ADD_PODCAST_ITEM,
    payload: e,
  };
};

export const getVideoToken = (e) => {
  return {
    type: TYPES.GET_VIDEO_TOKEN,
    payload: e,
  };
};

export const uploadPodcast = (data) => async (dispatch) => {
  await PostPodcastData(data)
    .then((res) => {
      if (res.data.code === 200) {
        dispatch({ type: TYPES.redirectMainPage, payload: true });
      }
    })
    .then(() => {
      setTimeout(() => {
        dispatch({ type: TYPES.redirectMainPage, payload: false });
      }, 3000);
    })
    .catch((err) => {
      throw `upload failed, ${err}`;
    });
};

export const cleanThumbs = () => async (dispatch) => {
  dispatch({ type: TYPES.ON_UPLOAD_THUMB, payload: "" });
  dispatch({ type: TYPES.SET_PODCAST_THUMBNAIL, payload: "" });
};

export const toggleFormConstant = () => async (dispatch) => {
  dispatch({ type: TYPES.TOGGLE_CONSTANT, payload: true });
};

export const toggleFormConstantinit = () => async (dispatch) => {
  dispatch({ type: TYPES.TOGGLE_CONSTANT, payload: false });
};

export const Automatic_upload_validation = (data) => async (dispatch) => {
  dispatch({ type: TYPES.ON_AUTOMATIC_ADD, payload: data });
};

export const UploadThumb = (data) => async (dispatch) => {
  await UploadThumbnail(data).then((res) => {
    dispatch({
      type: TYPES.SET_PODCAST_THUMBNAIL,
      payload: res.data.location.path,
    });
    dispatch({
      type: TYPES.SET_PODCAST_THUMBNAIL,
      payload: res.data.location.url,
    });
  });
};

export const getPodcastVideo_onUpload = (data) => async (dispatch) => {
  dispatch({ type: TYPES.GET_PODCAST_VIDEO_ON_UPLOAD, payload: data });
};

export const uploadSecondThumbnail = (data) => async (dispatch) => {
  await uploadSecondThumb(data).then((res) => {
    dispatch({
      type: TYPES.SET_PODCAST_THUMBNAIL,
      payload: res.data.location.path,
    });
    dispatch({
      type: TYPES.SET_PODCAST_THUMBNAIL,
      payload: res.data.location.url,
    });
  });
};

export const getCategorySelect = (data) => async (dispatch) => {
  dispatch({ type: TYPES.GET_CATEGORY_SELECT_VALUE, payload: data });
};

export const GetSelectChoices = () => async (dispatch) => {
  await getSelectChoices().then((res) => {
    dispatch({ type: TYPES.GET_SELECT_CHOICES, payload: res.data });
  });
};

export const SetDelete = () => (dispatch) => {
  dispatch({ type: TYPES.is_Deleted, payload: true });
};

export const mutliDeletePodcast = (path) => async (dispatch) => {
  await multiDelete(path).then((res) => {
    dispatch({ type: TYPES.ON_MULTI_DELETE, payload: res.data });
    dispatch({ type: TYPES.ON_MULTI_DELETE_DATA, payload: res.data });
    if (res.data.code === 200) {
      dispatch({ type: TYPES.is_Deleted, payload: false });
      dispatch({ type: TYPES.done_Delete, payload: true });
      dispatch({ type: TYPES.ON_MULTI_DELETE_DATA, payload: [] });
      dispatch({ type: TYPES.MULTI_DELETE_ID, payload: [] });
    }
  });
};
export const multisoftDeletePodcast = (path) => async (dispatch) => {
  await multisoftDelete(path).then((res) => {
    dispatch({ type: TYPES.ON_MULTI_DELETE, payload: res.data });
    dispatch({ type: TYPES.ON_MULTI_DELETE_DATA, payload: res.data });
    if (res.data.code === 200) {
      dispatch({ type: TYPES.is_Deleted, payload: false });
      dispatch({ type: TYPES.done_Delete, payload: true });
      dispatch({ type: TYPES.ON_MULTI_DELETE_DATA, payload: [] });
      dispatch({ type: TYPES.MULTI_DELETE_ID, payload: [] });
    }
  });
};
export const triggerMultiDeleteModal = () => async (dispatch) => {
  dispatch({ type: TYPES.done_Delete, payload: false });
};

export const GetRowIdOnMultiDelete = (data) => async (dispatch) => {
  dispatch({ type: TYPES.MULTI_DELETE_ID, payload: data });
};

export const SetOnDelete = () => async (dispatch) => {
  dispatch({ type: TYPES.ON_MULTI_DELETE_BOOLEAN, payload: true });
};
export const setOnDeleteOff = () => (dispatch) => {
  dispatch({ type: TYPES.ON_MULTI_DELETE, payload: false });
};
export const UploadPodcast = (file) => async (dispatch) => {
  await upload(file).then((res) => {
    dispatch({ type: TYPES.UPLOAD_FILE_PROGRESS, payload: res.data.data });
    dispatch({
      type: TYPES.GET_EMP_PARAMETERS_FORM,
      payload: [
        {
          title: res.data.file_name,
          audio: res.data.file_name,
        },
      ],
    });
  });
};

export const uploadTus = (data) => async (dispatch) => {
    let lastDotPosition = data.lastIndexOf(".");
    if (!data.includes(".") || lastDotPosition < 0) {
      data += ".mp3"
    }
    dispatch({ type: TYPES.GET_PODCAST_AUDIO, payload: data })
}


export const getTusId = (data) => async (dispatch) => {
  dispatch({ type: TYPES.ON_SEND_TUS_ID, payload: data });
};

export const getExtraPodcastParameters =
  (title, description, vignette, audio, video, id) => async (dispatch) => {
    let empParameters = {};
    empParameters.title = video;
    empParameters.description = description;
    empParameters.vignette = vignette;
    empParameters.audio = audio;
    empParameters.video = audio;
    empParameters.id = id;

    dispatch({ type: TYPES.ON_ADD_PODCASTITEM, payload: empParameters });
  };

export const setListPodcast = (data) => async (dispatch) => {
  dispatch({ type: TYPES.GET_PODCAST_DATA, payload: data });
};

export const SetLoading = (loading) => async (dispatch) => {
  dispatch({ type: TYPES.SET_LOADING, payload: loading });
};

export const setSearchKeyWord = (keyword) => async (dispatch) => {
  dispatch({ type: TYPES.GET_BY_SEARCHKEYWORD, payload: keyword });
};
export const setFilter = (filter) => async (dispatch) => {
  dispatch({ type: TYPES.Filter, payload: filter });
};
export const setSearchResult = (data) => async (dispatch) => {
  dispatch({ type: TYPES.SET_SEARCH_RESULT, payload: data });
};

export const Set_Id = (id) => async (dispatch) => {
  dispatch({ type: TYPES.SET_ID, payload: id });
};

export const setDetailsLoading = () => async (dispatch) => {
  dispatch({ type: TYPES.ON_LOAD_DETAILS, payload: true });
};

export const GetdetailsPodcast = (id) => async (dispatch) => {
  await GetDetails(id).then((res) => {
    dispatch({ type: TYPES.PODCAST_INFO, payload: res.data });
    if (res.status === 200) {
      dispatch({ type: TYPES.ON_LOAD_DETAILS, payload: false });
    }
  });
};

export const GetVideoFrame = (id) => async (dispatch) => {
  await getVideo(id).then((res) => {
    dispatch({ type: TYPES.GET_VIDEO_SRC, payload: res.data.link });
    if (res.status === 200) {
      dispatch({ type: TYPES.ON_LOAD_PREVIEW, payload: false });
    }
  });
};
export const loadPreview = () => async (dispatch) => {
  dispatch({ type: TYPES.ON_LOAD_PREVIEW, payload: true });
};
export const getVideLinkMissingPath = (id) => async (dispatch) => {
  await getVideo(id).then((res) => {
    dispatch({
      type: TYPES.GET_VIDEO_DOWNLOAD_LINK,
      payload: res.data.link.slice(52, res.data.link.length),
    });
  });
};

export const DeletePodcast = (id) => async (dispatch) => {
  await DeletePodcastData(id).then((res) => {
    dispatch({ type: TYPES.DELETE_PODCAST, payload: res.data });
    if (res.data.code === 200) {
      dispatch({ type: TYPES.single_podcast_delete, payload: true });
      dispatch({ type: TYPES.DELETE_PODCAST, payload: [] });
    }
  });
};
//SoftDeletePodcast
export const softDeletePodcast = (id) => async (dispatch) => {
  await SoftDeletePodcast(id).then((res) => {
    dispatch({ type: TYPES.DELETE_PODCAST, payload: res.data });
    if (res.data.code === 200) {
      dispatch({ type: TYPES.single_podcast_delete, payload: false });
      dispatch({ type: TYPES.DELETE_PODCAST, payload: [] });
    }
  });
};
// restoreDeletedPodcast
export const restoreDeletedPodcast = (id) => async (dispatch) => {
  await RestoreDeletedPodcast(id).then((res) => {
    dispatch({ type: TYPES.RESTORE_DELETE_PODCAST, payload: res.data });
    if (res.data.code === 200) {
      dispatch({ type: TYPES.single_podcast_delete, payload: false });
      dispatch({ type: TYPES.DELETE_PODCAST, payload: [] });
    }
  });
};
export const SetSingleDelete = () => async (dispatch) => {
  dispatch({ type: TYPES.single_podcast_delete, payload: true });
};
let stoId;
export const AreTheyDoneTranscoding =
(payload,GETPODCASTLIST) => async (dispatch, getState) => {
  const transcodeIds =
  getState().ReducerGetPodcastData.areTheyDoneTranscoding;
  await AretheyDoneTranscoding({ list: payload }).then((res) => {
      clearTimeout(stoId);
      if (res?.data?.podcasts?.length > 0) {
        GETPODCASTLIST({
          variables: {
            pagination: {
              offset: 0,
              limit: 10
            },where: { isArchived: false },
          orderBy: { direction: "DESC" },
          }
        })
      }

      if (res?.data?.podcasts.length > 0 || transcodeIds.length > 0) {
        dispatch({
          type: TYPES.ARE_THEY_DONE_TRANSCODING,
          payload: res.data.podcasts,
        });
        stoId = setTimeout(() => {
          dispatch(AreTheyDoneTranscoding(transcodeIds, GETPODCASTLIST));
        }, 20000);
      }
    });
  };

export const UpdatePodcast = (path, data) => async (dispatch) => {
  if (!data?.empParametersPodcastForm?.vignette) {
    data.empParametersPodcastForm.vignette = "default_podcast.jpg";
  }
  dispatch({ type: TYPES.ON_LOADING_SCREEN, payload: true });
  UpdateDataPodcast(path, data).then((res) => {
    dispatch({ type: TYPES.UPDATE_PODCAST_DATA, payload: res.data });
    if (res.status === 200) {
      dispatch({ type: TYPES.GET_PODCAST_BY_ID, payload: [] });
    }
  });
};

export const GetSSRLink = (id) => async (dispatch) => {
  await GetDataSSR(id).then((res) => {
    dispatch({ type: TYPES.GET_RRS_LINK, payload: res.data });
    if (res.data.code === 200) {
      dispatch({ type: TYPES.ON_SUCCES_RSS, payload: false });
    }
  });
};

export const setLoadRss = () => async (dispatch) => {
  dispatch({ type: TYPES.ON_SUCCES_RSS, payload: true });
};

export const languageSelect = (value) => async (dispatch) => {
  dispatch({ type: TYPES.GET_LANGUAGE_SELECT_VALUE, payload: value });
};

export const GetDataById = (id) => async (dispatch) => {
  return await Get_Podcast_ById(id).then((res) => {
    let data = res.data.podcastItems.map((p) => p.video);
    let fileName = data.join("");
    if (!fileName.includes(".")) {
      fileName += ".mp3";
    }
    dispatch({ type: TYPES.GET_PODCAST_BY_ID, payload: res.data });
    dispatch({
      type: TYPES.ON_GET_PODCAST_ITEMS_BYID,
      payload: res.data.podcastItems,
    });
    if (res.status === 200) {
      dispatch({ type: TYPES.ON_LOAD_EDIT, payload: false });
      dispatch({ type: TYPES.ON_LOAD_DOWNLOAD, payload: false });
    }
    return fileName;
  });
};

export const setLoadingForDownload = () => async (dispatch) => {
  dispatch({ type: TYPES.ON_LOAD_DOWNLOAD, payload: true });
};

export const setLoadingOnEdit = () => async (dispatch) => {
  dispatch({ type: TYPES.ON_LOAD_EDIT, payload: true });
};

export const handleUploadCancel = (id) => async (dispatch) => {
  return await DeletePodcastData(id).then((res) => {
    if (res.status === 200) {
      dispatch({ type: TYPES.ON_REDIRECT, payload: true });
      dispatch({ type: TYPES.ON_SEND_TUS_ID, payload: "" });
      dispatch({ type: TYPES.GET_PODCAST_AUDIO, payload: "" });
      dispatch({ type: TYPES.GET_PODCAST_VIDEO_ON_UPLOAD, payload: "" });
      setTimeout(() => {
        dispatch({ type: TYPES.ON_REDIRECT, payload: false });
      }, 5000);
    }
  });
};

// cleaning redux store functions

export const cleanDataById = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_PODCAST_BY_ID, payload: [] });
};

export const cleanRrsLink = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_RRS_LINK, payload: "" });
};

export const cleanPodcastItems = () => async (dispatch) => {
  dispatch({ type: TYPES.ON_ADD_PODCASTITEM, payload: [] });
};

export const cleanSrc = () => async (dispatch) => {
  dispatch({ type: TYPES.SET_PODCAST_THUMBNAIL, payload: "" });
  dispatch({ type: TYPES.ON_UPLOAD_THUMB, payload: [] });
};

export const cleanSecondThumbSrc = () => async (dispatch) => {
  dispatch({ type: TYPES.MAIN_PAGE_SECOND_THUMB, payload: "" });
  dispatch({ type: TYPES.UPLOAD_MAIN_PAGE_SECOND_THUMB, payload: "" });
};

export const cleanPodcastVideo = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_PODCAST_VIDEO, payload: "" });
};

export const cleanId = () => async (dispatch) => {
  dispatch({ type: TYPES.SET_ID, payload: null });
};

export const cleanTusId = () => async (dispatch) => {
  dispatch({ type: TYPES.ON_SEND_TUS_ID, payload: "" });
};

export const cleanPodcastItemDataById = () => async (dispatch) => {
  dispatch({ type: TYPES.ON_GET_PODCAST_ITEMS_BYID, payload: [] });
};

export const cleanPodcastItemData = () => async (dispatch) => {
  dispatch({ type: TYPES.ON_ADD_PODCASTITEM, payload: [] });
};

export const cleanPodcastitems = () => async (dispatch) => {
  dispatch({ type: TYPES.CLEAR_PODCAST_CONDITION });
};

export const cleanPodcastThemes = () => async (dispatch) => {
  dispatch({ type: TYPES.CLEAR_PODCAST_THEMES });
};

export const cleanExportLinks = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_EXPORT_LINKS, payload: "" });
};

export const cleanMultiDeleteIds = () => async(dispatch) => {
  dispatch({ type: TYPES.MULTI_DELETE_ID, payload: [] });
  dispatch({ type: TYPES.ON_MULTI_DELETE_DATA, payload: [] });
}

export const SendAccessStat = (sessionId, action) => async (dispatch) =>{    
  await SendAccess(sessionId, action).then((res) => {
      dispatch({ type: TYPES.POST_ACCESS_STAT, payload: res.data })
  })
}
