const langues = [
    "Français",
    "Albanian",
    "Afrikaans",
    "Arabic",
    "Basque",
    "Bulgarian",
    "Byelorussian",
    "Catalan",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "English",
    "Esperanto",
    "Estonian",
    "Faroese",
    "Finnish",
    "Anglais",
    "Galician",
    "German",
    "Greek",
    "Hebrew",
    "Hungarian",
    "Icelandic",
    "Irish",
    "Italian",
    "Japanese",
    "Korean",
    "Latvian",
    "Lithuanian",
    "Macedonian",
    "Norwegian",
    "Polish",
    "Portuguese",
    "Romanian",
    "Russian",
    "Scottish",
    "Serbian",
    "Slovak",
    "Spanish",
    "Turkish",
    "Ukrainian",
    "Swedish"
];
export default langues;
