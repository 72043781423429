import React from "react";
import { Button } from "@empreinte/components.button";
import { useTranslation } from "react-i18next";

const HeaderDemoTables = ({ title, url }) => {
  const { t } = useTranslation();
  const handleClick = (path) => {
    window.parent.postMessage({ event: "changeRoute", data: path },"*")
  };
  return (
    <div className="headerDemo">
      <span>{t(`Dashboard.${title}recentes`)}</span>
        <Button
        onClick={()=>handleClick(url)}
          title={t("Dashboard.showMore")}
          color="primary"
          variant="contained"
          component="label"
        ></Button>
    </div>
  );
};

export default HeaderDemoTables;
