import { useState } from "react";
import {
  Row,
  Col,
  Button,
  Divider,
  Card,
  Tabs,
  Progress,
  Modal,
  Spin,
  message,
} from "antd";
import French from "@uppy/locales/lib/fr_FR";
import English from "@uppy/locales/lib/en_US";
import Files from "../addMedia/utils/Files";
import {
  CloseCircleFilled,
  FolderAddFilled,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import { AddHooks } from "../addMedia/utils/hooks";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  setAddMediaActions,
  setIdVideoAdded,
} from "../addMedia/store/addMediaActions";
export const AddAudioDescription = () => {
  const { videoId, mediaId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { StatusBar, DragDrop } = require("@uppy/react");
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [modalVisible, setModalVisible] = useState(false);
  const [Currentprogress] = useState(0);
  const [Currentprogressone] = useState(0);
  const [fileCountUpload, SetfileCountUpload] = useState(0);
  const [defaultKey, setdefaultKey] = useState("1");
  const [uploadToken] = useState(axios.CancelToken.source());
  const [currentFile, setCurrentFilesupload] = useState([]);
  const [currentuploading, setcurrenUploading] = useState(0);
  const [listtoupload, setlisttoupload] = useState([]);
  const [, setisFinishedUpload] = useState(false);

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const error = () => {
    Modal.error({
      title: "Réssayer ultérieurement",
      content: "Une erreur s'est produite de notre côté ",
    });
  };
  const removeSpecialCharFromName = (name) => {
    return name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(" ", "_")
      .replace(/[&/\\#,+()$~%.'":*?<>{}^]/g, "");
  };
  const uppy = new Uppy({
    debug: true,
    locale: i18next.language === "en" ? English : French,
    id: "uppy",
    restrictions: {
      maxNumberOfFiles:1,
      allowedFileTypes: [
        "video/*",
        ".flv",
        ".avi",
        ".wmv",
        ".mpeg",
        ".mpg",
        ".mpe",
        ".dat",
        ".vob",
        ".asf",
        ".asx",
        ".mov",
        ".3gp",
        ".3g2",
        ".3gpp",
        ".dv",
        ".qt",
        ".rm",
      ],
    },
    onBeforeUpload: (files) => {
      let headers;
      const updatedFiles = Object.assign({}, files);
      Object.keys(updatedFiles).forEach((fileId) => {
        let lastDotPosition = updatedFiles[fileId].name.lastIndexOf(".");
        let name = updatedFiles[fileId].name.slice(0, lastDotPosition);
        name = removeSpecialCharFromName(name);
        let ext = updatedFiles[fileId].name.slice(
          lastDotPosition,
          updatedFiles[fileId].name.length
        );
        let filename = name + makeid(5) + ext;
        headers = {
          "automatic-add": false,
          Authorization: "Bearer " + localStorage.getItem("authToken"),
          autoUpload: false,
          filename: filename,
          "client-name": window.process.env.CLIENT_NAME,
          "media-id": mediaId,
          "video-id": videoId,
          "audio-description": true,
        };
        updatedFiles[fileId].tus = {
          headers: headers,
        };
      });
      return updatedFiles;
    },
    autoProceed: true,
  });
  uppy.on("file-added", async (file) => {
    await axios.get(
      `${window.process.env.API_HOST_GLOBAL}/video/add-audio-description/${videoId}/${file.name}`,
      {
        headers: {
          Authorization: `Bearer ${window.process.env.API_TOKEN}`,
        },
      }
    );
  });
  uppy.use(Tus, {
    chunkSize: 1024 * 1024 * 50,
    endpoint: window.process.env.API_HOST_UPLOAD + "videos/upload-video",
    removeFingerprintOnSuccess: true,
    async onAfterResponse(req, res) {
      res?.getBody() && Files.setToken(res?.getBody());
      res?.getBody() && Files.setVideoId(res?.getBody());
    },
  });

  uppy.on("complete", async (result) => {
    if (result?.successful?.length) {
      let videoToken = Files.getToken();
    let tokensData = [{ token: videoToken }];
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "tokens",
        addMediaConstraintsDataValueChange: tokensData,
      })
    );
    let videoId = Files.getVideoId();
    setModalVisible(false);
    dispatch(setIdVideoAdded(videoId));
      history.push("/ws-media")
    setisFinishedUpload(true);
    setcurrenUploading(currentuploading + 1);
    SetfileCountUpload(fileCountUpload + 1);
    setlisttoupload([
      ...listtoupload,
      {
        fileName: result?.successful[0]?.name,
        size: result?.successful[0]?.size,
        id: fileCountUpload,
      },
    ]);
    setisFinishedUpload(true);
    }
  });
  uppy.on("upload-error", async () => {
    Object.keys(localStorage).forEach(ls=>{
      if(ls.includes("tus")){
        localStorage.removeItem(ls)
      }
    })
  });

  uppy.on("restriction-failed", (file, error) => {
    message.error(`${error}`);
  });
  const { formatBytes } = AddHooks();
  const history = useHistory();

  const deleteRowFiles = (row) => {
    setCurrentFilesupload(currentFile.filter((data) => data.id !== row.id));
    setlisttoupload(listtoupload.filter((data) => data.id !== row.id));
    SetfileCountUpload((count) => count - 1);
  };
  const handleCancelrequest = () => {
    uploadToken.cancel("sourceComments - Request cancel by user");
  };

  const onKeyChange = (key) => setdefaultKey(key);
  const { TabPane } = Tabs;
  const exportType = [
    {
      id: 1,
      title: `${t("video.Importer")}`,
      icon: <FolderAddFilled />,
      value: (
        <>
          <h2> {t("video.importer_fichiers")} </h2>
          <DragDrop
            id="dragdrop"
            uppy={uppy}
            locale={{
              strings: {
                dropHereOr: t("video.Select_files"),
              },
            }}
            inline={true}
            metaFields={[]}
            replaceTargetContent={true}
            note={t("video.noteupload")}
            target={document.body}
            proudlyDisplayPoweredByUppy={false}
            showProgressDetails={true}
            showSelectedFiles={true}
          />
          <div className="myclass" data-content="My Content"></div>
          <StatusBar
            uppy={uppy}
            hideUploadButton
            hideAfterFinish={false}
            showProgressDetails
          />
          <Modal
            centered
            width={100}
            open={modalVisible}
            closable={false}
            footer={[]}
          >
            <Spin
              style={{ textAlign: "center" }}
              indicator={antIcon}
              spinning={modalVisible}
            />
          </Modal>
        </>
      ),
    },
  ];
  return (
    <div className="upload-page">
      <ArrowLeftOutlined
        style={{
          cursor: "medium",
          padding: "10px",
          margin: 0,
          position: "absolute",
          top: "5px",
          left: "5px",
        }}
        onClick={() => history.push("/ws-media")}
      />
      <Tabs
        defaultActiveKey={defaultKey}
        activeKey={defaultKey}
        onChange={onKeyChange}
      >
        {exportType.map((element) => {
          return (
            <TabPane
              disabled={element.disabled}
              tab={
                <div>
                  <span>
                    {element.icon}
                    {element.title}
                  </span>{" "}
                  <Divider type="vertical" />
                </div>
              }
              key={element.id}
            >
              {element.value}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};