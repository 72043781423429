export const TYPES = {
	GET_PODCASTS_DATA: "GET_PODCASTS_DATA",
	POST_ACCESS_STAT: "POST_ACCESS_STAT",
	UPDATE_PODCASTS_DATA: "UPDATE_PODCASTS_DATA",
	GET_PODCAST_RECORD: "GET_PODCAST_RECORD",
	GET_RRS_LINK: "GET_RRS_LINK",
	UPLOAD_FILE_PROGRESS: "UPLOAD_FILE_PROGRESS",
	SET_LOADING: "SET_LOADING",
	SET_ID: "SET_ID",
	DELETE_PODCAST: "DELETE_PODCAST",
	ADD_PODCAST_ITEM: "ADD_PODCAST_ITEM",
	PODCAST_INFO: "PODCAST_INFO",
	GET_PODCAST_VIDEO: "GET_PODCAST_VIDEO",
	GET_PODCAST_BY_ID: "GET_PODCAST_BY_ID",
	GET_EMP_PARAMETERS_FORM: "GET_EMP_PARAMETERS_FORM",
	ON_MULTI_DELETE: "ON_MULTI_DELETE",
	MULTI_DELETE_ID: "MULTI_DELETE_ID",
	ON_MULTI_DELETE_BOOLEAN: "ON_MULTI_DELETE_BOOLEAN",
	GET_BY_SEARCHKEYWORD: "GET_BY_SEARCHKEYWORD",
	SET_SEARCH_RESULT: "SET_SEARCH_RESULT",
	GET_VIDEO_DOWNLOAD_LINK: "GET_VIDEO_DOWNLOAD_LINK",
	GET_VIDEO_SRC: "GET_VIDEO_SRC",
	GET_SELECT_CHOICES: "GET_SELECT_CHOICES",
	GET_LANGUAGE_SELECT_VALUE: "GET_LANGUAGE_SELECT_VALUE",
	GET_CATEGORY_SELECT_VALUE: "GET_CATEGORY_SELECT_VALUE",
	ARE_THEY_DONE_TRANSCODING: "ARE_THEY_DONE_TRANSCODING",
	UPDATE_PODCAST_DATA: "UPDATE_PODCAST_DATA",
	ON_MULTI_DELETE_DATA: "ON_MULTI_DELETE_DATA",
	GET_STATS_TOKEN: "GET_STATS_TOKEN",
	ON_UPLOAD_THUMB: "ON_UPLOAD_THUMB",
	MAIN_PAGE_SECOND_THUMB: "MAIN_PAGE_SECOND_THUMB",
	UPLOAD_MAIN_PAGE_SECOND_THUMB: "UPLOAD_MAIN_PAGE_SECOND_THUMB",
	GET_THUMB_ON_ADDPODCAST: "GET_THUMB_ON_ADDPODCAST",
	ON_ADD_PODCASTITEM: "ON_ADD_PODCASTITEM",
	GET_PODCAST_AUDIO: "GET_PODCAST_AUDIO",
	GET_PODCAST_VIDEO_ON_UPLOAD: "GET_PODCAST_VIDEO_ON_UPLOAD",
	ON_SEND_TUS_ID: "ON_SEND_TUS_ID",
	ON_GET_SECOND_THUMB_DATA_BY_ID: "ON_GET_SECOND_THUMB_DATA_BY_ID",
	ON_GET_PODCAST_ITEMS_BYID: "ON_GET_PODCAST_ITEMS_BYID",
	ON_SEND_PODCAST_LIST_ID: "ON_SEND_PODCAST_LIST_ID",
	TOGGLE_CONSTANT: "TOGGLE_CONSTANT",
	is_Deleted: "is_Deleted",
	done_Delete: "done_Delete",
	single_podcast_delete: "single_podcast_delete",
	done_DeleteSinglePod: "done_DeleteSinglePod",
	ON_SUCCES_RSS: "ON_SUCCES_RSS",
	ON_LOAD_DETAILS: "ON_LOAD_DETAILS",
	ON_LOAD_EDIT: "ON_LOAD_EDIT",
	ON_LOAD_PREVIEW: "ON_LOAD_PREVIEW",
	ON_LOAD_DOWNLOAD: "ON_LOAD_DOWNLOAD",
	ON_LOADING_SCREEN: "ON_LOADING_SCREEN",
	GET_PODCAST_DATA_FAILED: "GET_PODCAST_DATA_FAILED",
	ON_REDIRECT: "ON_REDIRECT",
	ON_AUTOMATIC_ADD: "ON_AUTOMATIC_ADD",
	redirectMainPage: "redirectMainPage",
	RESTORE_DELETE_PODCAST: "RESTORE_DELETE_PODCAST",
	SET_PODCAST_CONDITION: "SET_PODCAST_CONDITION",
	SET_PODCAST_CONDITION_ADD: "SET_PODCAST_CONDITION_ADD",
	Filter: "Filter",
	SET_PODCAST_EXPORT_LINK: "SET_PODCAST_EXPORT_LINK",
	SET_PODCAST_EXPORT_PODCAST: "SET_PODCAST_EXPORT_PODCAST",
	SET_PAGINATION_PROPS: "SET_PAGINATION_PROPS",
	SET_PODCAST_THEMES: "SET_PODCAST_THEMES",
	SET_PODCAST_THUMBNAIL: "SET_PODCAST_THUMBNAIL",
	CLEAR_PODCAST_THEMES: "CLEAR_PODCAST_THEMES",
	SET_EDIT_PODCAST: "SET_EDIT_PODCAST",
	SET_ADDED_PODCAST: "SET_ADDED_PODCAST",
	CLEAR_PODCAST_CONDITION: "CLEAR_PODCAST_CONDITION",
	GET_EXPORT_LINKS: "GET_EXPORT_LINKS",
	GET_VIDEO_TOKEN: "GET_VIDEO_TOKEN",
	SET_PODCAST_ITEMS: "SET_PODCAST_ITEMS",
	GET_PLAYER_BY_ID: "GET_PLAYER_BY_ID",
	GET_PODCAST_BY_ID: "GET_PODCAST_BY_ID",
	GET_LIST_VIDEO: "GET_LIST_VIDEO",
	GET_TOTAL_COUNT: "GET_TOTAL_COUNT",
	SET_SEARCH_WORD:"SET_SEARCH_WORD",
};
  