import { TYPES } from "./types";
import axios from "axios";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
};


const url = "https:" + window.process.env.API_HOST_GLOBAL;
const urlVideo = window.process.env.VIDEO_API;
const newUrl = urlVideo ? urlVideo : url;

export const getUserData = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_USER_DATA_LOADING });
  await axios
    .get(`${newUrl}/core/rights/get-user-rights`, { headers })
    .then((res) => {
      dispatch({ type: TYPES.GET_USER_DATA, payload: res.data });
      if (res?.data?.userData?.roles?.includes("ROLE_SUPER_ADMIN") || res?.data?.userData?.roles?.includes("ROLE_ADMIN"))  
      return dispatch({ type: TYPES.USER_SUPER_ADMIN });
    })
    .catch(() => dispatch({ type: TYPES.GET_USER_DATA_FAILED }));
};
