export const AddMedia = () => {
    const AddMediaConstraintsData = () => {
        return {
            fileTitle: "",
            title: "",
            medias: [
                {}
            ],
            insertDate: "",
            startDate: "",
            endDate: "",
            updated_at: "",
            duration: "",
            themes: [],
            secure: false,
            hashSource: "",
            allow_share: false,
            downloadable: false,
            externalLinkVideo: "",
            offline: false,
            subscriptionGroups: [],
            description: "",
            transcription: "",
            logoIncrusted: false,
            contributor: "",
            author: "",
            copyright: window.process.env.copyright_default_value ?? "",
            keyWords: "",
            isSecuredOnInternet: false,
            isVisibleOnInternet: true,
            passwordInternet: "",
            isPrivate: false,
            logo: "",
            mainPage: false,
            metaData: "",
            filesVideo: [],
        };
    };

    return { AddMediaConstraintsData };
};
