import React from "react";
import HeaderDemoTables from "./HeaderDemoTables";
import BodyDemoTables from "./BodyDemoTables";
import BodyDemoTablesContainCheckbox from "./BodyDemoTablesContainCheckbox";
const GridSection = ({ title, data, name, s, check, url }) => {
  return !data.length ? (
    <></>
  ) : (
    <div className={`oneGrid ${s}`}>
      <HeaderDemoTables url={url} title={title} />
      {name !== "Playlist" ? (
        <BodyDemoTables url={url} title={title} data={data} check={check} s={s} />
      ) : (
        <BodyDemoTablesContainCheckbox url={url} data={data} check={check} s={s} />
      )}
    </div>
  );
};

export default GridSection;
