import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "@empreinte/components.header";
import AddIcon from "@mui/icons-material/Add";
import { Button, message, Space } from "antd";
import { Helpers } from "../../utils/helpers";
import {
  OnCancelAddEdit,
  PlayListAddEdit,
} from "../Redux/features/AddEditSlice";
import { getPlayListToAdd, addPlayList, editPlaylist } from "../Redux/actions";
const PlayListHeader = () => {
  const {acces_send} =Helpers()
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    AddingPlayList,
    EditingPlayList,
    PlayListData,
    action,
    RmboxSelected,
  } = useSelector((state) => ({
    ...state.addEdit,
  }));
  const { modules } = useSelector((state) => ({
    ...state.Pagination,
  }));
  const transform = (arr) => {
    let returneData = [];
    arr.map((e) => returneData.push({ rmbox: e }));
    return returneData;
  };
  return (
    <div>
      {contextHolder}
      {modules?.playlist_post_add_action?.authorized &&
      !AddingPlayList &&
      !EditingPlayList ? (
        <Header
          hasPermission="playlist_post_add"
          title={t("PlayList.playlist")}
          firstBtnTitle={t("Profile.add")}
          mainPageBtnAction={() =>
           { acces_send("playlist_post_add_action");
            dispatch(
              PlayListAddEdit({
                Data: {
                  playlist: {
                    id: null,
                    title: null,
                    loopp: 0,
                    maxDuration: 0,
                    autoSort: 0,
                    created_at: null,
                    updated_at: null,
                    playlist_media: [],
                    onAir: null,
                    startDate: "2023-04-04T12:09:05+0200",
                    isLive: false,
                    rmboxes: [],
                  },
                  url_playlist: "",
                  default_theme_playlist: null,
                },
                addedit: "AddingPlayList",
              })
            )}
          }
          onMainPage={true}
          firstBtnIcon={<AddIcon />}
        />
      ) : AddingPlayList && !EditingPlayList ? (
        <Header
          title={t("PlayList.PlaylistInfos")}
          onMainPage={false}
          arrowBackAction={() => dispatch(OnCancelAddEdit())}
          CancelBtnAction={() => dispatch(OnCancelAddEdit())}
          secondBtnTitle={t("Profile.cancel")}
          thirdBtnTitle={t("Profile.register")}
          addBtnAction={() => {
            RmboxSelected.length >= 1
              ? dispatch(
                  addPlayList({
                    empPlaylistForm: {
                      autoSort: PlayListData.playlist.autoSort,
                      isLive: false,
                      loopp: PlayListData.playlist.loopp,
                      maxDuration: PlayListData.playlist.maxDuration,
                      title: PlayListData.playlist.title,
                      rmboxes: transform(RmboxSelected),
                      playlist_media: PlayListData.playlist.playlist_media,
                    },
                  })
                )
              : dispatch(
                  addPlayList({
                    empPlaylistForm: {
                      autoSort: PlayListData.playlist.autoSort,
                      isLive: false,
                      loopp: PlayListData.playlist.loopp,
                      maxDuration: PlayListData.playlist.maxDuration,
                      title: PlayListData.playlist.title,
                      playlist_media: PlayListData.playlist.playlist_media,
                    },
                  })
                );
          }}
          hasPermission="false"
          isDisabled={!action}
        />
      ) : !AddingPlayList && EditingPlayList ? (
        <Header
          title={t("PlayList.PlaylistModif")}
          onMainPage={false}
          arrowBackAction={() => dispatch(OnCancelAddEdit())}
          CancelBtnAction={() => dispatch(OnCancelAddEdit())}
          secondBtnTitle={t("Profile.cancel")}
          thirdBtnTitle={t("Profile.register")}
          addBtnAction={() => {
            PlayListData.playlist.title.length && PlayListData.playlist.playlist_media.length
							? RmboxSelected.length >= 1
								? dispatch(
										editPlaylist({
											empPlaylistForm: {
												autoSort: PlayListData.playlist.autoSort,
												isLive: false,
												loopp: PlayListData.playlist.loopp,
												maxDuration: PlayListData.playlist.maxDuration,
												title: PlayListData.playlist.title,
												rmboxes: transform(RmboxSelected),
												playlist_media: PlayListData.playlist.playlist_media,
											},
											id: PlayListData.playlist.id,
										})
									)
								: dispatch(
										editPlaylist({
											empPlaylistForm: {
												autoSort: PlayListData.playlist.autoSort,
												isLive: false,
												loopp: PlayListData.playlist.loopp,
												maxDuration: PlayListData.playlist.maxDuration,
												title: PlayListData.playlist.title,
												playlist_media: PlayListData.playlist.playlist_media,
											},
											id: PlayListData.playlist.id,
										})
									)
							: messageApi.open({
									type: "error",
									content: !PlayListData.playlist.title
										? t("PlayList.testTitle")
										: t("PlayList.testPLaylist"),
								});
          }}
          hasPermission="false"
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default PlayListHeader;
