import React, { useEffect, useRef, useLayoutEffect, useState } from "react";
import "../PlayList.scss";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import { TextField,Tooltip } from "@mui/material";
import { SelectList } from "@empreinte/components.select-list";
import { getThemes } from "../Redux/actions";
import { getrmbox } from "../Redux/actions";
import { InputText } from "@empreinte/components.input-text";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { SelectMultiple } from "@empreinte/components.select-multiple";
import {
  SelectRmbox,
  liveChangeForAddEdit,
  mediaListMovement,
  ableToMakeAction,
} from "../Redux/features/AddEditSlice";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useWindowSize } from "../Utils/resizeEvent";
import { SearchBar } from "@empreinte/components.search-bar";
import { Loader } from "@empreinte/components.loader";
import { Hooks } from "../Utils/Hooks";
import {GraphQLFetchData} from "../Redux/api"
import uploadImage from "../../assets/svgicons/upload.svg"
import { makeStyles } from '@mui/styles';
import { Pagination } from "@empreinte/components.pagination";
import { List } from "antd/lib/form/Form";
import Skeleton from '../Components/Skeleton';

const GroupHeader = (props)=>{
  return (
    <div style={{
      fontWeight: "bold",
      marginLeft: "4px"
    }}>
{props.children}
    </div>
  )
}

const AddEdit = () => {
  const useStyles = makeStyles({
    autocompleteLabel: {
      transform: 'translate(14px, -6px) scale(0.75)', // Adjust the positioning as needed
    },
  });
  const classes = useStyles();
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };
  const {descriptionminimize} = Hooks()
  const {GetListVideos,GetListVideosFiltered} = GraphQLFetchData()
  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const remainingSeconds = seconds - hours * 3600 - minutes * 60;

    const paddedHours = hours.toString().padStart(2, "0");
    const paddedMinutes = minutes.toString().padStart(2, "0");
    const paddedSeconds = remainingSeconds.toString().padStart(2, "0");

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  }
  function formatTimeReverse(seconds) {
    const date = new Date(Date.UTC(1970, 0, 1, 0, 0, seconds));
    const isoString = date.toISOString();
    const timezoneOffset = date.getTimezoneOffset();
    const timezoneHours = Math.floor(Math.abs(timezoneOffset) / 60)
      .toString()
      .padStart(2, "0");
    const timezoneMinutes = (Math.abs(timezoneOffset) % 60)
      .toString()
      .padStart(2, "0");
    const timezoneSign = timezoneOffset > 0 ? "-" : "+";
    const timezoneString = `${timezoneSign}${timezoneHours}${timezoneMinutes}`;

    return isoString.replace("Z", timezoneString);
  }
  // function timeInSeconds(dateTimeString) {
  //   const date = new Date(dateTimeString);
  //   const seconds = date.getSeconds();
  //   const minutes = date.getMinutes();
  //   const hours = date.getHours();
  //   return hours * 3600 + minutes * 60 + seconds;
  // }
 const timeInSeconds = (timeString) => {
		if (timeString === "") return 0;

		const [hours, minutes, seconds] = timeString.split(":").map(Number);
		return hours * 3600 + minutes * 60 + seconds;
 };
  const DefaultPage = 10;
	const currentPage = 1;
	const [offset, setOffset] = useState(0);

	const [page, setPage] = useState(DefaultPage);
	const [current, setCurrent] = useState(currentPage);
  const list = useSelector((state) => state.ReducerGetPodcastData.listVideo);
  const total = useSelector((state) => state.ReducerGetPodcastData.totalCount);
	const handlePageChange = (page) => {
		setCurrent(page);
		setPagination(true)
	};

	const handlePageSizeChange = (size) => {
		setPage(size);
	};
  const ReadablableTime = (timestamp) => {
    const date = new Date(timestamp);
    const seconds = date.getSeconds();
    const minutes = date.getMinutes();
    const hours = date.getHours();
    const paddedHours = hours.toString().padStart(2, "0");
    const paddedMinutes = minutes.toString().padStart(2, "0");
    const paddedSeconds = seconds.toString().padStart(2, "0");
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  };
  const scrollableRef = useRef(null);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const checker = (e) => {
    if (
      JSON.stringify(e.media.video.duration).includes("-") ||
      JSON.stringify(e.media.video.duration).includes("+") ||
      JSON.stringify(e.media.video.duration).includes("T")
    ) {
      return e.media.video.duration;
    } else {
      return formatTimeReverse(e.media.video.duration);
    }
  };
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  useLayoutEffect(() => {
    dispatch(getThemes());
    dispatch(getrmbox());
  }, []);
  const {
    Themes,
    Rmbox,
    ListVideos,
    totalListNumber,
    PlayListData,
    isLoading,
  } = useSelector((state) => ({
    ...state.addEdit,
  }));
  const [listPageNumber, setListPageNumber] = useState(1);
  const [OptionsTheme, setOptionThemes] = useState([]);
  const [OptionsThemeAdded, setOptionsThemesAdded] = useState([]);
  const [rmboxx, setrmboxx] = useState();
  const [listVideos, setListVideos] = useState([]);
  const [idf, setIdf] = useState();
  const [flexInputs, setFlexInputs] = useState(100);
  const [search, setSearch] = useState("");
  const [newVid, setNewVid] = useState();
  const begin = 1850;
  const moveUpDown = (currentIndex, newIndex) => {
    const newObjects = [...OptionsThemeAdded];
    const objectToMove = newObjects.splice(currentIndex, 1)[0];
    newObjects.splice(newIndex, 0, objectToMove);
    setOptionsThemesAdded(newObjects);
  };
  useLayoutEffect(() => {
    PlayListData?.playlist?.title?.length &&
    PlayListData?.playlist?.playlist_media?.length
      ? dispatch(ableToMakeAction(true))
      : dispatch(ableToMakeAction(false));
  }, [
    PlayListData?.playlist?.title,
    PlayListData?.playlist?.playlist_media.length,
  ]);
  useLayoutEffect(() => {
    setrmboxx(PlayListData?.playlist?.rmboxes.map((obj) => obj.rmbox.id));
  }, [PlayListData?.playlist?.rmboxes]);
  useLayoutEffect(() => {
    setOptionsThemesAdded(PlayListData?.playlist?.playlist_media);
  }, []);
  const moveOff = (index) => {
    const newArray = [...OptionsThemeAdded];
    const done = newArray
      .slice(0, index)
      .concat(newArray.slice(index + 1, newArray.length));
    dispatch(
      liveChangeForAddEdit({
        name: "playlist_media",
        value: PlayListData?.playlist?.playlist_media
          .slice(0, index)
          .concat(
            PlayListData?.playlist?.playlist_media.slice(
              index + 1,
              PlayListData?.playlist?.playlist_media.length
            )
          ),
      })
    );
    setOptionsThemesAdded(done);
  };
useEffect(() => {
	let newData = [];
	var i = 0;
	OptionsThemeAdded.map((e) => {
		if (e?.media?.mediaInfo) {
			e?.media?.mediaInfo?.map((media) => {
				newData.push({
					media: media?.Id,
					sort: i,
				});
				i++;
			});
		} else {
			newData.push({
				media: e?.media?.id,
				sort: i,
			});
			i++;
		}
	});
	dispatch(
		liveChangeForAddEdit({
			name: "playlist_media",
			value: newData,
		})
	);
}, [OptionsThemeAdded]);

  useEffect(() => {
		let duration = 0;
		OptionsThemeAdded.map((e) => {
			if (
				JSON.stringify(e.media.video.duration).includes("-") ||
				JSON.stringify(e.media.video.duration).includes("+") ||
				JSON.stringify(e.media.video.duration).includes("T")
			) {
				const durationStr = e.media.video.duration.split(/[T+]/)[1];
				duration += timeInSeconds(durationStr);
			} else {
				duration += timeInSeconds(e.media.video.duration);
			}
		});

		dispatch(liveChangeForAddEdit({ name: "maxDuration", value: duration }));
	}, [OptionsThemeAdded]);

  useEffect(() => {
    setFlexInputs(100 - (begin - windowSize) / 10);
  }, [windowSize]);
  useEffect(() => {
    setListPageNumber((prev) => prev - (prev - 1));
  }, [idf]);
 const [isLoadingg, setisLoadingg] = useState(true);

useEffect(() => {
	if (idf || current) {
		const offset = (current - 1) * page;
		if (idf) {
			setisLoadingg(true);
			GetListVideosFiltered({
				variables: {
					pagination: { limit: page, offset: offset },
					where: {
						titleContains: searchWorld,
						hasWebtvThemesWith: { id: idf },
						isVod: true,
						isTranscoded: true,
						deletedAt: null,
					},
					orderBy: { direction: "DESC", field: "ID" },
				},
			}).finally((res) => {
				setisLoadingg(false);
			});;
		} else if (pagination === true || searchWorld === "") {
			setisLoadingg(true);
			GetListVideosFiltered({
				variables: {
					pagination: { limit: page, offset: offset },
					where: {
						titleContains: searchWorld,
						isVod: true,
						isTranscoded: true,
						deletedAt: null,
					},
					orderBy: { direction: "DESC", field: "ID" },
				},
			}).finally((res) => {
				setisLoadingg(false);
			});;
		}
	}
}, [idf, current, page]);


  const handleSearch = (e) => {
    setSearch(e.target.value);
    setListPageNumber(1); 
  };
  useEffect(() => {
    if (listPageNumber === 1) {
      setListVideos(ListVideos);
    } else {
      setListVideos((prev) => [...prev, ...ListVideos]);
    }
  }, [ListVideos]);
  useEffect(() => {
    dispatch(SelectRmbox(rmboxx));
  }, [rmboxx]);
  useEffect(() => {
    const themesBychannels = Themes?.channels ? Themes?.channels : {};
    const formattedOptionsThemes = [];
    let index = 0;
    Object.values(themesBychannels)?.forEach((data) => {
      let form = {};
      let childChannel = {};
      if (data.themes?.length) {
        form = Object.assign({
          options: data?.themes?.map((theme) => {
            if (theme.themes?.length) {
              childChannel = Object.assign({
                options: theme?.themes?.map((subtheme) => {
                  return {
                    title: data.title,
                    label: subtheme.title,
                    value: subtheme.id,
                    key: index++,
                  };
                }),
              });
              formattedOptionsThemes.push(childChannel);
            }
            return {
              title: data.title,
              label: theme.title,
              value: theme.id,
              key: index++,
            };
          }),
        });

        if (form.options.length) {
          formattedOptionsThemes.push(form);
        }
      }
    });
    let tab = [];
    tab = formattedOptionsThemes.map((row) =>
      row.options.map((option) => option)
    );
    setOptionThemes(tab.flat());
  }, [Themes]);

  useEffect(() => {
    if (isAtEnd) {
      setListPageNumber((prev) => prev + 1);
    }
  }, [isAtEnd]);
  useEffect(() => {
    const videoElements = document.querySelectorAll(".video");
    const lastVideoElement = videoElements[videoElements?.length - 1];
    if (lastVideoElement) {
      lastVideoElement.click();
    }
  });

  const handleMouseDown = (event) => {
    event.preventDefault();
  };
      const modules = useSelector((state) => state.ReducerUserData.modules);
      const hasPermission = modules.configuration_rmbox_list_dtable;
	  const [pagination, setPagination] = useState(false);
	  const [searchWorld, setSearchWorld] = useState("");
const handleKeyDown = (e) => {
	if (e.key === "Enter") {
		setisLoadingg(true);
		setSearchWorld(e.target.value);
		setPagination(false);
		if (idf) {
			setCurrent(1);
			GetListVideosFiltered({
				variables: {
					pagination: { limit: page, offset: offset },
					where: {
						titleContains: e.target.value,
						isVod: true,
						isTranscoded: true,
						deletedAt: null,
						hasWebtvThemesWith: { id: idf },
					},
					orderBy: { direction: "DESC", field: "ID" },
				},
			}).finally((res) => {
				setisLoadingg(false);
			});;
		} else {
			setCurrent(1);
			GetListVideosFiltered({
				variables: {
					pagination: { limit: page, offset: offset },
					where: {
						titleContains: e.target.value,
						isVod: true,
						isTranscoded: true,
						deletedAt: null,
					},
					orderBy: { direction: "DESC", field: "ID" },
				},
			}).finally((res) => {
				setisLoadingg(false);
			});;
		}
	}
};

  return (
		<div className={`addEditGlobal ${windowSize < 910 ? "globalcol" : ""}`}>
			<Loader isOpen={isLoading && !search} />
			<div className={`part1 ${windowSize < 910 ? "part1Expand" : ""}`}>
				<div className="Header">{t("PlayList.ListOfVideos")}</div>
				<div className="headsearch">
					<SearchBar
						className="search-bar-Ip-main"
						name={"search"}
						placeholder={t("Podcast.Search...")}
						setSearchText={(e) => setSearch(e.target.value)}
						value={search}
						onKeyDown={handleKeyDown}
					></SearchBar>

					<Box
						sx={{
							width: "100%",
							maxWidth: "100%",
							paddingBottom: "20px",
							paddingTop: "3px",
						}}
					>
						<FormControl fullWidth>
							<Autocomplete
								className="autocomplete-form-input"
								style={{ padding: 0 }}
								id="grouped-demo"
								key={(option) => option.key}
								options={OptionsTheme}
								onChange={(event, newValue, reason) => {
									if (reason === "clear") {
										event.preventDefault();
										setCurrent(1);
										setIdf("");
										setPagination(true);
									} else {
										event.preventDefault();
										setCurrent(1);
										setIdf(newValue.value);
									}
								}}
								value={OptionsTheme.value}
								groupBy={(option) => option.title}
								renderGroup={(params) => {
									return (
										<li key={params.key}>
											<GroupHeader>{params.group}</GroupHeader>
											<div>{params.children}</div>
										</li>
									);
								}}
								getOptionLabel={(option) => option?.label}
								sx={{
									width: "100%",
									maxWidth: "100%",
									color: "red",
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										className="textThemeAddEdit"
										label={idf ? "" : t("PlayList.Theme")}
										onFocus={handleInputFocus}
										onBlur={handleInputBlur}
										InputLabelProps={{
											classes: {
												root: isInputFocused ? classes.autocompleteLabel : "",
												shrink: isInputFocused ? classes.autocompleteLabel : "",
											},
										}}
									/>
								)}
							/>
						</FormControl>
					</Box>
					{/* </> */}
				</div>
				{isLoadingg ? (
					<>
						<div className={`grid ${windowSize < 500 ? "colsVids" : ""}`}>
							{[...Array(6)].map((_, index) => (
								<div className="video" key={index}>
									<Skeleton />
								</div>
							))}
						</div>
					</>
				) : (
					<>
						<div
							ref={scrollableRef}
							onMouseDown={handleMouseDown}
							className={`grid ${windowSize < 500 ? "colsVids" : ""}`}
							style={{ height: "calc(100vh - 290px)" }}
						>
							{list === null ? (
								<span className="notFoundtVideos">
									<DoNotDisturbIcon sx={{ fontSize: 70 }} />
									<span>{t("PlayList.notFound")}</span>
								</span>
							) : (
								list &&
								list?.length > 0 &&
								list?.map((e) => (
									<Card className="video" sx={{ width: "100%", height: "max-content" }}>
										<CardActionArea>
											<CardMedia
												component="img"
												height="200"
												image={e?.video?.Logo}
												alt="green iguana"
											/>
											<CardContent>
												<Tooltip title={e?.video?.Title}>
													<Typography
														gutterBottom
														variant="h6"
														component="div"
														sx={{
															overflow: "hidden",
															whiteSpace: "nowrap",
															textOverflow: "ellipsis",
															maxWidth: "100%",
														}}
													>
														{e?.video?.Title}
													</Typography>
												</Tooltip>
												<Typography className="align" variant="body2" color="text.secondary">
													<AccessTimeIcon fontSize="small" />{" "}
													<span className="span-playlist">
														{e?.video?.Duration ? e?.video?.Duration : "00:00:00"}{" "}
													</span>
												</Typography>
											</CardContent>
										</CardActionArea>
										<CardActions>
											<Button className="alignsAddToPlaylist" size="small" color="primary">
												<AddCircleOutlineIcon fontSize="small" />{" "}
												<span
													onClick={(event) => {
														event.preventDefault();
														setOptionsThemesAdded((prev) => [
															...prev,
															{
																id: e.video.Id,
																sort: prev?.length,
																media: {
																	mediaInfo: e?.video?.Media,
																	video: {
																		duration: e?.video?.Duration,
																		fileTilte: e?.video?.FileTitle,
																		id: e?.video?.Id,
																		logo: e?.video?.Logo,
																		title: e?.video?.Title,
																	},
																},
															},
														]);
													}}
												>
													{t("PlayList.AddToPlayList")}
												</span>
											</Button>
										</CardActions>
									</Card>
								))
							)}
						</div>
						<div>
							<Pagination
								ShowName="page"
								EntriesName={t("PlayList.page")}
								OfPageName="of"
								pageSize={page}
								currentPage={current}
								totalEntities={total}
								setCurrentPage={handlePageChange}
								setPageSize={handlePageSizeChange}
							></Pagination>
						</div>
					</>
				)}
			</div>

			<Divider orientation="vertical" flexItem />
			<div className={`part2 ${windowSize < 910 ? "part2Expand" : ""}`}>
				<div className={`grid2 ${windowSize < 1520 ? "InputCols" : ""}`}>
					<InputText
						value={PlayListData.playlist.title}
						fullWidth
						handleChange={(e) => {
							dispatch(liveChangeForAddEdit({ name: "title", value: e.target.value }));
						}}
						labelInput={t("PlayList.title")}
						required
					></InputText>
					<SelectList
						className="custom-select"
						labelText={t("PlayList.Readinloop")}
						value={PlayListData.playlist.loopp}
						data={[
							{ value: 1, label: `${t("PlayList.yes")}` },
							{ value: 0, label: `${t("PlayList.no")}` },
						]}
						handleChange={(e) => {
							dispatch(
								liveChangeForAddEdit({
									name: "loopp",
									value: JSON.parse(e.target.value) ? 1 : 0,
								})
							);
						}}
					></SelectList>
					<SelectList
						className="custom-select"
						labelText={t("PlayList.RespectedLordreParameterized")}
						value={PlayListData.playlist.autoSort}
						data={[
							{ value: 1, label: `${t("PlayList.yes")}` },
							{ value: 0, label: `${t("PlayList.no")}` },
						]}
						handleChange={(e) => {
							dispatch(
								liveChangeForAddEdit({
									name: "autoSort",
									value: JSON.parse(e.target.value) ? 1 : 0,
								})
							);
						}}
					></SelectList>

					<InputText
						fullWidth
						value={PlayListData.playlist.maxDuration}
						labelInput={t("PlayList.DurationofbroadcastADDEDIT")}
						disabled={true}
						placeholder={t("PlayList.DurationofbroadcastADDEDIT")}
					></InputText>
				</div>
				<span className="name">{t("PlayList.rmbox")}</span>
				<SelectMultiple
					labelInput="RmBox"
					listIds={rmboxx}
					dataList={Rmbox?.data?.rmboxs.map((e) => {
						return { label: e.name, Id: e.id };
					})}
					handleChange={(e) => setrmboxx(e.target.value)}
				></SelectMultiple>
				<div className="name" id="list_video">
					{t("PlayList.Listofselectedvideos")}
				</div>
				<div className="container">
					{OptionsThemeAdded.length <= 0 && (
						<span className="vidContainer">
							<img src={"/ws-media/" + uploadImage} alt="" />
							<span>{t("PlayList.Addvideos")}</span>
						</span>
					)}
					{OptionsThemeAdded.map((e, i) => (
						<>
							<div className="display">
								<div className="movement">
									<ExpandLessIcon
										onClick={() => {
											i !== 0 && moveUpDown(i, i - 1);
										}}
										className={`${i === 0 && "disable"} up`}
									/>

									{i + 1}
									<ExpandMoreIcon
										onClick={() => {
											i !== OptionsThemeAdded.length - 1 && moveUpDown(i, i + 1);
										}}
										className={`${i === OptionsThemeAdded.length - 1 && "disable"} down`}
									/>
								</div>
								<img
									className="image"
									src={e.media.video.logo}
									alt={`${e.media.video.title} ` + " Image"}
								/>
								<div className="selectinnedVideoData">
									<span>{descriptionminimize(e.media.video.title)}</span>
									<span className="timeDisplay">
										<AccessTimeIcon fontSize="small" />{" "}
										{e.media.video.duration
											? e.media.video.duration.match(/^\d{2}:\d{2}:\d{2}$/)
												? e.media.video.duration
												: e.media.video.duration.split(/[T+]/)[1]
											: "00:00:00"}
									</span>
								</div>
								<span className="rightt">
									<CancelIcon onClick={() => moveOff(i)} fontSize="small" className="rouge" />
								</span>
							</div>
						</>
					))}
				</div>
			</div>
		</div>
	);
};

export default AddEdit;
