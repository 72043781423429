import React, { useEffect, useState } from "react";
import { Tooltip, Divider, Button, Modal, Row, Col } from "antd";
import Hooks from "../utils/hooks";
import { DownloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { GetData } from "../../APIcalls";
import { getUrlVideos } from "../store/mediasActions";
import { ClipLoader } from 'react-spinners';

export default function DownloadModalFiles({ video: data, idVideo }) {
  const [id, setId ] = useState();
  const [loading, setLoading] = useState(false);
  const idRow = useAppSelector((state) => state.MediaReducer.idRow);
  const modalToOpen = useAppSelector((state) => state.MediaReducer.modalToOpen);
  const urlVideos = useAppSelector((state) => state.MediaReducer.urlVideo);
  const dispatch = useAppDispatch();
  const getVideosForDownload = async (id) => {
    setLoading(true);
    await GetData("/video-api/video/download/" + id).then((res) => {
      dispatch(getUrlVideos(res?.data?.data))
      setLoading(false);
    }
    ).catch(err => {
      setLoading(false);
      console.err(err.message);
    });
  };
  const { t } = useTranslation();
  const { handleCancel } = Hooks();
  let bitrates = [
    { name: "250", file: urlVideos.file_250 },
    { name: "450", file: urlVideos.file_450 },
    { name: "850", file: urlVideos.file_850 },
    { name: "1500", file: urlVideos.file_1500 },
    { name: "4000", file: urlVideos.file_4000 },
    { name: "8000", file: urlVideos.file_8000 },
  ];

  useEffect(() => {
    if((!idVideo && idRow) || (idRow && (idVideo != idRow))) {
      setId(idRow)
    } else if (idVideo) {
      setId(idRow)
    }
  }, [idVideo, idRow]);

  useEffect(()=> {
    id && getVideosForDownload(id);
  }, [id])

  useEffect(()=> {
    if(idRow && !idVideo) {
      idVideo = idRow
    }
  }, [idRow, idVideo])

  return (
    <>
      {modalToOpen && (
        <Modal
          footer={[
            <Button key="back" onClick={handleCancel}>
              {t("media.close")}
            </Button>,
          ]}
          onCancel={handleCancel}
          title={t("media.download-files")}
          open={modalToOpen === "showModalForDownloadVideos"}
        >
          {!loading ?  
          <Row
            style={{
              display: "flex",
              flexDirection: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <>
              <Col style={{ flex: "1" }}>
                <b>{t("media.file-source")}</b>
              </Col>
              <br />

              <Tooltip
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <a 
                  href={urlVideos.file} 
                  download 
                  title="Fichier Source"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    size="middle"
                    style={{ float: "right", marginLeft: "-2px" }}
                  >
                    {t("media.download")}
                  </Button>
                </a>
              </Tooltip>
              <Divider />
            </>

            {bitrates?.map((item, index) => {
              if (item.file) {
                return (
                  <React.Fragment key={item.index}>
                    <Col
                      style={{
                        flex: "1",
                        marginTop: "8px",
                        marginBottom: "13px",
                      }}
                    >
                      <b style={{ float: "left" }}>{index === 0 && "MP4"} </b>
                    </Col>

                    <Tooltip>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <h4>
                          {t("media.bitrate")}: {item.name}
                        </h4>
                        <a
                          href={item.file}
                          download
                          title={`Bitrate:${item.name}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            size="middle"
                            key={item.name}
                          >
                            {t("media.download")}
                          </Button>
                        </a>
                      </div>
                    </Tooltip>
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })}
          </Row> : <Row type="flex" align='middle' justify='center'><Col><ClipLoader/></Col></Row>
          }
        </Modal>
      )}
    </>
  );
}
