import { createSlice } from "@reduxjs/toolkit";
import { getDirectsPlannedNumber } from "../actions";

export const RecentLivesSlice = createSlice({
  name: "RecentLives",
  initialState: {
    name: "Direct",
    RecentLivesData: {},
    totalRecentLivesNumber: 0,
    action: false,
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getDirectsPlannedNumber.pending]: (state) => {
      state.action = true;
    },
    [getDirectsPlannedNumber.fulfilled]: (state, { payload }) => {
      state.action = false;
      state.RecentLivesData = payload.data;
      state.totalRecentLivesNumber = payload.recordsFiltered;
    },
    [getDirectsPlannedNumber.rejected]: (state, { payload }) => {
      state.action = false;
    },
  },
});
export const {} = RecentLivesSlice.actions;
export default RecentLivesSlice.reducer;
