import { GetData } from "../../APIcalls";
import React, { useEffect, useState } from "react";
import { List, Modal, Row, Col, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { InfoCircleOutlined} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { setSubscriptionList } from "../../Medias/store/mediasActions";
import Hooks from "../utils/hooks";
import { useAppSelector } from "../../redux/hooks";
import '../styles.css'
import { setCheckedValue } from "../../InfosMedias/utils/actions";
export function AbonnementModal({video, idVideo}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const subscriptionList = useAppSelector((state) => state.MediaReducer.subscriptionList);
  const modalToOpen = useAppSelector((state) => state.MediaReducer.modalToOpen);
  const eventModalOpen = useAppSelector((state) => state.MediaReducer.eventModalOpen);
  const [subscribersList, setSubscribersList] = useState([])
  const [checkedValuesSubs, setcheckedValuesSubs] = useState([])
  const [checkedValuesSubsToremove, setcheckedValuesSubsToremove] = useState([])
  const [addSbscriberEnabled, setaddSbscriberEnabled] = useState(false)
  const [ids_subscribersVideoList, setids_SubscribersVideoList] = useState([])
  const { handleCancel, nameVideo, addSubscriptionUsers } = Hooks();
  const getSubscribersList = async (Id) => {
    await GetData(`/video/get-subscriptionUsers/${Id}`).then((res) => {
      dispatch(setSubscriptionList(res.data?.data))
      setSubscribersList(res.data?.data?.users)
      setids_SubscribersVideoList(new Set(res.data?.data?.subscriptionUsers))
   
    });
  }
  const onAddSubscriberVideo = (checkedValues) => {
    setcheckedValuesSubs(checkedValues)
  };

  const onClickAddSubscriberVideo = () => {
    setaddSbscriberEnabled(true)
    setids_SubscribersVideoList(new Set([...ids_subscribersVideoList, ...checkedValuesSubs]))
  };

  const isUserChecked = (item) => {
    return  Array.from(ids_subscribersVideoList).some(value => { return value.id == item.id })

  }

  const isUserToremoveChecked = (item) => {
    return ! Array.from(ids_subscribersVideoList).some(value => { return value == item })

  }

  const onClickRemoveSubscriberVideo = (checkedValues) => {
    setids_SubscribersVideoList(new Set(checkedValuesSubsToremove))

  };

  const onRemoveSubscriberVideo = (checkedValues) => {
    let myArray =  Array.from(ids_subscribersVideoList).filter(function (el) {
      return !checkedValues.includes(el);
    });
    setcheckedValuesSubsToremove(myArray)

  };


  useEffect(() => {
    idVideo && getSubscribersList(idVideo)
    if (typeof modalToOpen === typeof undefined) {
      setids_SubscribersVideoList([]);
      setSubscribersList([])
      setaddSbscriberEnabled([])
      setcheckedValuesSubs([])
      setCheckedValue([])
      setSubscriptionList([])
      setcheckedValuesSubsToremove([])
    }
  }, [modalToOpen]);

  return (
    <Modal
      onCancel={handleCancel}
      onOk={() => { addSubscriptionUsers(idVideo, ids_subscribersVideoList) }}
      icon={<InfoCircleOutlined />}
      type='error'
      title={`${t("video.Metadata_video")}: ${nameVideo}`}
      width={'80%'}
      open={modalToOpen === "addSubscribers" || eventModalOpen === "addSubscribers"}
    >
      <Row gutter={[16, 24]}>
        <Col className="scroll-container"  xs={{ span: 20, offset: 0 }} md={{ span: 10, offset: 0 }} >
          <Checkbox.Group
            style={{
              width: '100%',
            }}
            onChange={onAddSubscriberVideo}
          >
            <List
              size="small"
              header={<div><b>{t("video.subscribe_list")}</b>{" "}</div>}
              bordered
              dataSource={subscriptionList?.users?.length && subscribersList}
              renderItem={(item, index) => <List.Item className={isUserChecked(item) ? 'checked-item' : ''}><Checkbox checked={true} onChange={(e) => { }} value={item}>{item.name + " " + item.lastName}</Checkbox> </List.Item>}
            />
          </Checkbox.Group>

        </Col>
        <Col style={{display:'flex',justifyContent:'center',alignItems:'center'}} md={{ span: 2, offset: 1 }}>
                  
        <PersonAddIcon onClick={() => onClickAddSubscriberVideo()}  className="add_user svg_icons"  />
    
 
        <PersonRemoveIcon onClick={() => onClickRemoveSubscriberVideo()}  className="remove_user_class svg_icons" />
  
        </Col>
        <Col className="scroll-container" xs={{ span: 20, offset: 0}} md={{ span: 10, offset: 1 }} >
          <Checkbox.Group
            style={{
              width: '100%',
            }}
            onChange={onRemoveSubscriberVideo}
          >
            <List
              size="small"
              header={<div><b>{t("video.List_of_sub_video")}</b>{" "}</div>}
              bordered
              dataSource={ [...ids_subscribersVideoList]}
              renderItem={item => <List.Item>  <Checkbox className={isUserToremoveChecked(item) ? 'checked-item' : ''} value={item}>{item.name + " " + item.lastName}</Checkbox></List.Item>}
            />
          </Checkbox.Group>

        </Col>


      </Row>
    </Modal>
  )
}