import { PostData, GetData, DeleteData } from "../../APIcalls";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { graphGetVideosList, graphGetVideosListFiltered } from "./features/AddEditSlice";
import { TYPES } from "../../Podcast/utils/types";
import { message } from "antd";
import { t } from "i18next";

const getVideosList = gql`
	mutation ($pagination: Pagination, $where: VideoWhereInput, $orderBy: VideoOrder) {
		Videos(pagination: $pagination, where: $where, orderBy: $orderBy) {
			totalCount
			edges {
				video {
					Id
					Title
					Duration
					Logo
					FileTitle
					Media {
						Id
					}
				}
			}
		}
	}
`;

export const getUserData = () => GetData(`/core/rights/get-rights-by-module`);

export const getChannelsByThemes = () => GetData(`/channel/channel-by-themes`);

export const getRmbox = () => GetData(`/rmbox`);

export const GraphQLFetchData = () => {
	const dispatch = useDispatch();
	const [GetListVideos] = useMutation(getVideosList, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: async (data) => {
			const response = data.Videos.edges;

			dispatch({ type: TYPES.GET_LIST_VIDEO, payload: response });
			dispatch({ type: TYPES.GET_TOTAL_COUNT, payload: data.Videos.totalCount });
		},
		onError: (error) => {
			message.error({
				content: t("serverError"),
			});
		},
	});
	const [GetListVideosFiltered] = useMutation(getVideosList, {
		fetchPolicy: "no-cache",
		variables: {},

		onCompleted: async (data) => {
			const response = data.Videos.edges;
           dispatch({ type: TYPES.GET_LIST_VIDEO, payload: response });
			dispatch({ type: TYPES.GET_TOTAL_COUNT, payload: data.Videos.totalCount });
		},
		onError: (error) => {
			message.error({
				content: t("serverError"),
			});
		},
	});	
	return {
		GetListVideos,
		GetListVideosFiltered,
	};
};

export const AddOnePlayList = (empPlaylistForm) => PostData(`/playlist/add`, empPlaylistForm);

export const editOnePlayList = (empPlaylistForm) =>
	PostData(`/playlist/edit/${empPlaylistForm.id}`, empPlaylistForm);

export const getOnePlayListToAdd = () => GetData(`/playlist/details/0/playlist`);

export const getOnePlayListToEdit = (id) => GetData(`/playlist/details/${id}/playlist`);

export const getOnePlayListToView = (id) => GetData(`/playlist/details/${id}/playlist`);

export const deleteOnePlayList = async (id) => DeleteData(`/playlist/delete/${id}`);

export const getVideoPlayer = async (id) => {
	return GetData(`/playlist/playlist-link/${id}`);
};

export const multyDelete = async (id) => {
	return DeleteData(`/multiDelete/playlists/${id}`);
};
