import "../Dashboard.scss"
import {  useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Header = () => {
    const { t } = useTranslation();
    const { UserData } = useSelector((state:any) => ({
        ...state.Dashboard,
      }));

    const {HeaderNumberDirects} = useSelector((state:any)=>({
        ...state.Header
      }))
    return <div className="liniaire">
            <img className="imageTitle" src={UserData.vignette ? UserData.vignette : "/user.svg"} alt={t("Dashboard.yourImage")} />
            <div className="titleHeader">
                <p className="biggerTitle" >{`${t("Dashboard.hello")}, ${UserData.username}`}</p>
            </div>
        </div>
}
export default Header;