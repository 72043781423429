import gql from "graphql-tag";

export const graphQL_schema = () => {
  
    const Get_All_Playlists = gql `
    mutation (
        $where: PlaylistWhereInput, $pagination: Pagination, $orderBy: PlaylistOrder
        ) {
        Playlists(where:$where, pagination: $pagination, orderBy: $orderBy) {
        totalCount
          edges
          {node{
            Id
            title
            loop   
            maxDuration
          }}
      }
      }
    `;
    return({Get_All_Playlists})
}
