export default class PodcastClass {
    static filesCount = 0;
    static id = null;
    static setId = (idparam) => {
        if (idparam.length !== 0) {

            this.id = idparam

        }
    }
    static getId = () => {
        return this.id
    }
}
