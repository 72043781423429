export const PodcastList = () => {
  const AddPodcastItems = () => {
    const ThumbNailURL = window.process.env.BUCKET_URL_THUMBNAIL?.replace(
      "moyen",
      "podcast"
    );
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let dDate = new Date(year + 10, month, day);
    return {
      Title: "",
      Description: "",
      Vignette: `${ThumbNailURL}default_podcast.jpg`,
      Lang: "Français",
      Explicite: false,
      IsDownloadable:false,
      IsReady: true,
      StartDate: new Date(),
      EndDate: dDate,
      CreatedBy: 1,
      IsAccessible: false,
      IsArchived: false,
      CreatedAt: new Date(),
      UpdatedAt: new Date(),
      Author: "",
      Editor: "",
      Encoded_By: "",
      Copyright: "",
      Keywords: "",
      Themes: [],
      PodcastItems: {}
    };
  };

  const paginationProps = () => {
    return {
      totalCounts: 0,
      order: "desc",
      pageSize: 10,
      columnKey: 0,
      current: 1,
      id: [],
      idLive: 0,
    };
  };

  const PodcastCondition = () => {
    return {
      elementsSelected: [],
      elementSelected: 0,
      addPodcast: false,
      editPodcast: false,
      disableSubmitForm: true,
    };
  };

  const PodcastData = () => {
    return [];
  };

  const PodcastDetails = () => {
    return [];
  };

  return {
    AddPodcastItems,
    PodcastDetails,
    PodcastData,
    PodcastCondition,
    paginationProps,
  };
};
