import React, {useEffect, useState,useRef} from "react";
export const Hooks = () =>{
   
      const usePrevious = (value:any) => {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
      }
      const descriptionminimize = (str:any, show:boolean) => {
        if (show) return str;
        if (str && str.length > 18) {
          str = str.substring(0, 18) + " ...";
        }
        return str;
      };
  
    return({
        descriptionminimize,
        usePrevious
    })


}