import { createSlice } from "@reduxjs/toolkit";
import { getPodcasts } from "../actions";
export const RecentPodcastSlice = createSlice({
  name: "RecentPodcast",
  initialState: {
    name: "Podcast",
    RecentPodcastData: {},
    totalRecentPodcastNumber: 0,
    action: false,
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getPodcasts.pending]: (state) => {
      state.action = true;
    },
    [getPodcasts.fulfilled]: (state, { payload }) => {
      state.action = false;
      state.RecentPodcastData = payload.data;
      state.totalRecentPodcastNumber = payload.recordsTotal;
    },
    [getPodcasts.rejected]: (state, { payload }) => {
      state.action = false;
    },
  },
});
export const {} = RecentPodcastSlice.actions;
export default RecentPodcastSlice.reducer;
