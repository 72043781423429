import React, { useState,useEffect } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import BarChartIcon from "@mui/icons-material/BarChart";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Box, Chip, Dialog, DialogContent, Modal, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ModalInputs } from "@empreinte/components.modal-inputs";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { ModalConfirm } from "@empreinte/components.modal-confirm";
import { ClipLoader } from "@empreinte/components.clip-loader";
import Hooks  from "../../Podcast/utils/hooks";
import {GetPlayerByID} from '../../Podcast/utils/action'
import { PodcastGraphQlFetchData } from "../../Podcast/graph-ql-podcast/GraphQlFetchData";




const PodcastsActions = ({ title ,url,data }) => {
  const { GETPODCASTLIST } = PodcastGraphQlFetchData();

    const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { ArchivePodcasts,GetPodcastItemInfo } = Hooks();



  const handleClick = (path) => {
    const parentUrl = window.parent.location.href;
    const newUrl = parentUrl.replace('/dashboard', `/${path}`);
    window.parent.location.href = newUrl;
  };

  const singlePodcastDelete = useSelector(
    (state) => state.ReducerGetPodcastData.singlePodcastDelete
  );
  const doneDeleteSinglePod = useSelector(
    (state) => state.ReducerGetPodcastData.doneDeleteSinglePod
  );
  const success_preview = useSelector(
    (state) => state.ReducerGetPodcastData.success_preview
  );
  const playerpod = useSelector(
    (state) => state.ReducerGetPodcastData.playerById
  );
  const FilterList = useSelector(
    (state) => state.ReducerGetPodcastData.FilterList
  );


  const [ViewDetailsModal, setViewDetailsModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalOpen(false);
  const handleDeleteModalOpen = () => setDeleteModalOpen(true);
  const [apercu_modal, setApercu_modal] = useState(false);
  const handleCloseApercuModal = () => setApercu_modal(false);
  const handleOpenApercuModal = () => setApercu_modal(true);
  const [open, setOpen] = useState(false);
  const [Filter, setFilterValue] = useState(FilterList);
  const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width:"50%",
  };



  const handleDetailsModalClosing = () => {
		setViewDetailsModal(false);
	};

	const handleClose = () => {
		setOpen(false);
	};
 
  const isPodcast = data?.node?.id? true :false
 

  return (
    <div className="action actionModification">
      {title === "Podcast" && (
        <Tooltip placement="top" title={t("Dashboard.watch")}>
          <PlayArrowIcon
            color="action"
            fontSize="small"
            className={"icon_action"}
            onClick={() =>{
              setApercu_modal(true)
              dispatch(GetPlayerByID(data?.node?.id))
            } }
          />
        </Tooltip>
      )}
      <Tooltip placement="top" title={t("Dashboard.edit")}>
        <ModeEditIcon
          color="action"
          fontSize="small"
          className={"icon_action"}
          onClick={() => {
            GetPodcastItemInfo(data?.node);
            history.push(`/edit/${data?.node.id}`)
          }
            }
        />
      </Tooltip>
      <Tooltip placement="top" title={t("Dashboard.details")}>
        <RemoveRedEyeIcon
          color="action"
          fontSize="small"
          className={"icon_action"}
          onClick={() => setViewDetailsModal(true)}
        />
      </Tooltip>

   {/* Details Modal */}
      <ModalInputs
        maxWidth="sm"
        className="modal-inputs-h2"
        open={ViewDetailsModal}
        dividers={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleDetailsModalClosing}
        onCancel={handleDetailsModalClosing}
        CancelBtnName={t("Profile.close")}
        title={`${t("Podcast.add-edit.Podcast detail")} : ${data?.node?.Title}`}
        children={
          <>
            <div className="Podcast-details-modal">
              <div className="Podcast-details-modal-container">
                <img
                  className="thumbnail-container"
                  src={data?.node?.Vignette}
                  alt=""
                />
              </div>
              <div className="Podcast-details-modal-container">
                <span className="Podcast-details-modal-title">
                  {t("Podcast.Identifier")}
                </span>
                <span className="Podcast-details-modal-value">
                  {data?.node?.id ? data?.node?.id : "--"}
                </span>
              </div>
              <div className="Podcast-details-modal-container">
                <span className="Podcast-details-modal-title">{t("Podcast.Title")}</span>
                <span className="Podcast-details-modal-value">
                  {data?.node?.Title ?data?.node?.Title: "--"}
                </span>
              </div>
              <div className="Podcast-details-modal-container">
                <span className="Podcast-details-modal-title">
                  {t("Profile.description")}
                </span>
                <span
                  className="Podcast-details-modal-value"
                  style={{
                    height: "auto",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  {data?.node?.Description ? data?.node?.Description : "--"}
                </span>
              </div>
              <div className="Podcast-details-modal-container">
                <span className="Podcast-details-modal-title">
                  {t("Podcast.add-edit.Category")}
                </span>
                <span
                  className="Podcast-details-modal-value"
                  style={{
                    height: "auto",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  {data?.node?.Themes ? (
                    data?.node?.Themes?.map((theme) => (
                      <Chip key={theme.ThemeId} label={theme.ThemeTitle} />
                    ))
                  ) : (
                    <Typography variant="body2">--</Typography>
                  )}
                </span>
              </div>
            </div>
          </>
        }
      />



      {/* <Tooltip placement="top" title={t("Dashboard.stats")}>
        <BarChartIcon
          color="action"
          fontSize="small"
          className={"icon_action"}
        />
      </Tooltip>
      <Tooltip placement="top" title={t("Dashboard.download")}>
        <DownloadOutlinedIcon
          color="action"
          fontSize="small"
          className={"icon_action"}
        />
      </Tooltip> */}
      <Tooltip placement="top" title={t("Dashboard.delete")}>
        <DeleteIcon color="action" fontSize="small" className={"icon_action"} onClick={() => handleDeleteModalOpen()}/>
      </Tooltip>

                 {/*  Delete Dialog */}
                 {doneDeleteSinglePod ? null : singlePodcastDelete ? (
        <Dialog
          open={deleteModalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ height: "50%" }}>
            <ClipLoader
              color="inherit"
              thickness={1.6}
              style={{ marginTop: "6px" }}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <ModalConfirm
          className="modal-delete-podcast"
          fullWidth={true}
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title={t("Podcast.delete-trash")}
          content={t("Podcast.moved-trash")}
          onCancel={handleDeleteModalClose}
          CancelBtnName={t("Podcast.add-edit.cancel")}
          ConfirmBtnName={t("Podcast.add-edit.Delete")}
          onConfirm={() => {
            ArchivePodcasts(data?.node?.id);
            handleDeleteModalClose();
            GETPODCASTLIST({
              variables: {
                where: { isArchived: Filter },
                pagination: { limit: 5, offset:0 },
                orderBy: { direction: "DESC" },
              },
            });

          }}
        />
      )}

          {/* Apercu Modal */}
          {success_preview ? (
        <Dialog
          open={apercu_modal}
          onClose={handleCloseApercuModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ height: "50%" }}>
            <ClipLoader
              color="inherit"
              thickness={1.6}
              style={{ marginTop: "6px" }}
            />
          </DialogContent>
        </Dialog>
      ) : (
		<Modal
		open={apercu_modal}
		onClose={handleCloseApercuModal}
		className="modal-inputs-styling"
		aria-labelledby="modal-modal-title"
		aria-describedby="modal-modal-description"
	  >
		<Box sx={style}>
		<Typography id="modal-modal-description" sx={{ mt: 0, mb: 0 }}>
			  <iframe
				title={playerpod?.podcast?.title}
				src={`https:${playerpod?.link}`}
			 className="iframe_container"
                height="240px"
				></iframe>
			</Typography>
		</Box>
	  </Modal>
      )}
    </div>
  );
};

export default PodcastsActions;
