import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
export const DataPendingVideosTable = () => {
    const [source, setSource] = useState([]);
    const { t } = useTranslation();
    const videos = useAppSelector((state) => state.addMediaReducer.pendingVideo);
    useEffect(() => {
        if (videos && videos?.length) {
            const list = videos?.map((row) => {
                let arrayOfnodes = [];
                arrayOfnodes = {
                    ...row,
                    contributor: row.contributor ? row?.contributor?.firstName + row?.contributor?.lastName : '-'
                };
                return arrayOfnodes;
            });
            setSource(list);
        }
        else setSource([])
    }, [videos]);

    const columns = [
        {
            headerName: "Id",
            field: "id",
            key: 0,
            width: 150,
            flex:1,
            sortable: true
        }, {
            headerName: t("media_center.table.name"),
            field: "title",
            key: 1,
            width: 300,
            flex:1,
            sortable: true
        }, {
            headerName: t("video.startdate"),
            field: "startDate",
            width: 450,
            key: 2,
            flex:1,
            sortable: true
        }, {
            headerName: t("media_center.table.contributor"),
            field: "contributor",
            key: 3,
            width: 150,
            flex:1,
            sorter: (a, b) => a.contributor.localeCompare(b.contributor),
            sortable: true
        },
    ];

    const data = source;
    return { columns, data };
};
