import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {useSelector,useDispatch} from "react-redux";
import {getExtraPodcastParameters} from "../../utils/action"
import "../../../index.css"
import PodcastClass from "./const";
const Toggle_add_Podcast = () => {
    const audio = useSelector((state)=>state.ReducerGetPodcastData.podcastAudio)
    const thumb = useSelector((state)=>state.ReducerGetPodcastData.secondThumbData);
   // const id = useSelector((state)=>state.ReducerGetPodcastData.tus_id);
    const video = useSelector((state)=>state.ReducerGetPodcastData.podcastVideo);
   // const podItems = useSelector((state)=>state.ReducerGetPodcastData.podcastItems_byId);
   //  let titleItems = podItems?.map(podcast=>podcast.title);
   //  let descrItems = podItems?.map(podcast=>podcast.description);
     const videoForPodcastItem = useSelector(state=>state.ReducerGetPodcastData.podcastItems.video);
    // const audioForPodcastItem = useSelector(state=>state.ReducerGetPodcastData.podcastVideo);
    const dispatch = useDispatch();
    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [vignette,setVignette]=useState("");
    const [podcastItemid,setpodcastItemid] = useState("");
   

    useEffect(()=>{
        let pIid = JSON.parse(PodcastClass.getId())?.podcastItemId
        setpodcastItemid(pIid);
    },[])
    useEffect(()=>{
        setVignette(thumb);
        let titleForPodcastItem = "";
        if(title.length <= 0 ) {
            titleForPodcastItem = videoForPodcastItem;
        }
        else if(title.length > 0 ) {
            titleForPodcastItem = title;
        }
        dispatch(getExtraPodcastParameters(titleForPodcastItem,description,vignette,audio,video,podcastItemid))
    },[title, description, vignette, thumb, videoForPodcastItem, audio])

    const {t} = useTranslation();
    const [toggle,setToggle] = useState(false);
    const setToggle_btn = () => setToggle(!toggle);
    // return <div className="tg_cntr" >
    //   <div className="tglHeader"  onClick={setToggle_btn}>
    //     <div className="toggle_btn_container" >
    //         <p className="ptitle" >{t("Podcast.bread-crumb.Podcast"  )} 1 : {audioForPodcastItem} </p>
    //         { toggle ? <RemoveIcon/> :   <AddIcon/>}
    //     </div>
    //   </div>
    //   {
    //     toggle ? 
    //     <div style={{ marginBottom:'20px',border:'1px solid transparent',borderRadius:'4px',boxShadow:'0 1px 1px rgb(0 0 0 / 5%)'}}
    //     >
    //         <div className="select_upload" >
    //      <img className="toggle_image" src={ vignette ? vignette : "/default.png" } alt="" /> 
       
    //     <SecondThumbUpload content={t("Podcast.add-edit.CHOOSE A THUMB")} />
    //     </div>
    //     <div className="upload_file_inputs_add" >
    //         <Box
    //             sx={{
    //                 width: 900,
    //                 maxWidth: "100%",
    //                 paddingBottom: "20px",
    //                 marginTop:"15px"
    //             }}
    //         >
    //             <TextField name={"title"} value={title}
    //             defaultValue={titleItems}
    //             InputLabelProps={{
    //                 style: {
    //                     fontSize: "medium",
    //                     color:"black"
    //                 }
    //             }}
    //             onChange={(e)=>setTitle(e.target.value)}
    //             fullWidth label={t("Podcast.Title")} id="fullWidth" />
    //         </Box>
    //         <Box
    //             sx={{
    //                 width: 900,
    //                 maxWidth: "100%",
    //                 paddingBottom: "30px",
    //             }}
    //         >
    //             <TextField multiline={true}
    //                InputLabelProps={{
    //                 style: {
    //                     fontSize: "medium",
    //                     color:"black"
    //                 }
    //             }}
    //             defaultValue={descrItems}
    //             name={"description"}
    //             value={description}
    //             onChange={(e)=>setDescription(e.target.value)}
    //   rows={6}  fullWidth label={t('Podcast.Description')} id="fullWidth" />
    //         </Box>
    //         </div>
    //     </div>
    //     : null
    //   }

    // </div>
}

export default Toggle_add_Podcast;