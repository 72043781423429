import { useEffect, useRef, useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "../../../index.css";
import { useDispatch } from "react-redux";
import { UploadThumb } from "../../utils/action";
import eventHub from "../../../Event";
import { useHistory } from "react-router-dom";
import { UploadImage } from "@empreinte/components.upload-image";
import FilterOutlinedIcon from "@mui/icons-material/FilterOutlined";

const FileUpload = ({ content, fileURL }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    eventHub.on("progress", async (data) => {
      setProgress(data);
      if (progress === "100") {
        history.push("/ws-media/upload");
      }
    });
  }, [progress]);

  const handleClick = (file) => {
    inputRef.current.click();
  };

  const handleFileChange = (files) => {
    let formData = new FormData();
    formData.append("empApiFileForm[thumb]", files[0]);
    formData.append("flowChunkNumber", 1);
    formData.append("flowChunkSize", files[0].size);
    formData.append("flowCurrentChunkSize", files[0].size);
    formData.append("flowTotalSize", 3569216);
    formData.append("flowIdentifier", files[0].size - files[0].name);
    formData.append("flowFilename", files[0].name);
    formData.append("flowRelativePath", files[0].name);
    formData.append("flowTotalChunks", 1);

    dispatch(UploadThumb(formData));
    // clear the input field to avoid thumb url bugs
    if (files && files.target) {
      files.target.value = null;
    }
  };

  return (
    <UploadImage
      className="upload-podcast-thumb"
      title={content}
      component="span"
      startIcon={<FileDownloadOutlinedIcon className="addIc" />}
      variant="contained"
      handleChange={(e) => handleFileChange(e.target.files)}
      fileURL={fileURL}
      icon={
        <FilterOutlinedIcon
          fontSize="large"
          sx={{ width: "200px", height: "113px" }}
        />
      }
    />
  );
};

export default FileUpload;
