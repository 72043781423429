import { useSelector } from "react-redux"
import "../PlayList.scss"
import {useWindowSize} from './resizeEvent.js'

export const DataPLaylist = () =>{
    const { AllPlayListsData} = useSelector((state:any) => ({ ...state.playlist }))
    const columns :any= [
    ];
    const data = {
        totalElements: AllPlayListsData?.totalCount,
        content:
        (typeof AllPlayListsData === 'undefined') ?  [] :  AllPlayListsData?.edges
      };
    
    return({
        columns,
        data
    })

}