import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Tag,
  Card,
  Form,
  DatePicker,
  Select,
  Upload,
  message,
  Checkbox,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "../addMedia.scss";
import { AddHooks } from "../utils/hooks";
import { useAppSelector } from "../../redux/hooks";
const { Dragger } = Upload;

export const FormMedia = () => {
  const { handleChangeInputs, updateFilesVideos, handleChangeDate } =
    AddHooks();
  const { Option } = Select;
  const [FileUpload, setFileUpload] = useState(false);
  const [optionThemes, setOptionThemes] = useState([]);
  const externalData = useAppSelector(
    (state) => state.addMediaReducer.externalData
  );
  const contributoList = useAppSelector(
    (state) => state.addMediaReducer.contributoList
  );
  const themesList = useAppSelector((state) => state.addMediaReducer.themes);
  useEffect(() => {
    const themesBychannels = themesList?.channels ? themesList?.channels : {};

    const formattedOptionsThemes = [];
    Object.values(themesBychannels)?.forEach((data) => {
      const form = Object.assign({
        label: data?.title,
        options: data?.themes?.map((theme) => {
          return { label: theme.title, value: theme.id, id: theme.id };
        }),
      });

      formattedOptionsThemes.push(form);
    });

    setOptionThemes(formattedOptionsThemes);
  }, [themesList]);

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const props = {
    name: "empApiFileForm[thumb]",
    multiple: true,
    action:
      window.process.env.API_HOST_GLOBAL + "/video/video/upload/attached-file",

    data: (file) => {
      return {
        flowChunkNumber: 1,
        flowChunkSize: 1048576,
        flowCurrentChunkSize: file.size,
        flowTotalSize: file.size,
        flowIdentifier: file.uid,
        flowFilename: file.name,
        flowRelativePath: file.name,
        flowTotalChunks: 1,
      };
    },
    beforeUpload: (file) => {
    },
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
       
      }

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        const Newfile = {
          title: info.file.response.location.path,
          fileName: info.file.name,
        };
        updateFilesVideos(Newfile,"add");
        
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      
    },
  };
  return (
   
    <>
      <Row gutter={[0, 22]}>
        <Col span={24}>
          <Row justify={"space-between"}>
            <Col span={10}>
              <Form.Item name="logo">
                <img
                  alt={externalData?.data?.video?.thumbnails}
                  name="logo"
                  style={{ width: "100%" }}
                  src={externalData?.data?.video?.thumbnails}
                ></img>
              </Form.Item>
            </Col>
            <Col span={13}>
              <Row gutter={[0, 15]}>
                <Col span={24}>
                  <Row gutter={[0, 8]}>
                    <Col span={24}>
                      <span style={{ fontFamily: "system-ui" }}>Titre</span>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="title"
                        initialValue={"externalData?.data?.video?.title"}
                        rules={[
                          {
                            required: true,
                            message: "Please input your title!",
                          },
                        ]}
                      >
                        {externalData?.data?.video?.title && (
                          <Input
                            onChange={handleChangeInputs}
                            name={"title"}
                            id={"title"}
                            defaultValue={externalData?.data?.video?.title}
                            value={externalData?.data?.video?.title}
                            aria-placeholder="saisir un titre"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 8]}>
                    <Col style={{ fontFamily: "system-ui" }} span={24}>
                      <span style={{ fontFamily: "system-ui" }}>
                        Description
                      </span>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please input the description!",
                          },
                        ]}
                      >
                        {externalData?.data?.video?.description && (
                          <Input
                            onChange={handleChangeInputs}
                            name={"description"}
                            id={"description"}
                            defaultValue={
                              externalData?.data?.video?.description
                            }
                            value={externalData?.data?.video?.description}
                            aria-placeholder="saisir un titre"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Row gutter={[20, 0]} justify="space-between">
                <Col span={12}>
                  <span style={{ fontFamily: "system-ui" }}>
                    Début de disponiblité
                  </span>
                </Col>
                <Col span={12}>
                  <span style={{ fontFamily: "system-ui" }}>
                    Fin de disponiblité
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[20, 0]} justify="space-between">
                <Col span={12}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please input Start Date!" },
                    ]}
                    name="startDate"
                  >
                    <DatePicker
                      onChange={(e) => {
                        handleChangeDate(e, "startDate");
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please input End Date!" },
                    ]}
                    name="endDate"
                  >
                    <DatePicker
                      onChange={(e) => {
                        handleChangeDate(e, "endDate");
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Row gutter={[20, 0]} justify="space-between">
                <Col span={12}>
                  <span style={{ fontFamily: "system-ui" }}>Contributeur</span>
                </Col>

                <Col span={12}>
                  <span style={{ fontFamily: "system-ui" }}>Thèmes</span>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[20, 0]} justify="space-between">
                <Col span={12}>
                  <Form.Item name="contributor">
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Choisir un Contributeur"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {contributoList?.contributors?.map((row) => (
                       row?.name?.trim() && <Option key={row.id} value={row.id}>
                       {row?.name}
                     </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="themes">
                    <Select
                      showSearch
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Choisir un thème"
                      optionFilterProp="children"
                      options={optionThemes}
                    >
                     
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <span style={{ fontFamily: "system-ui" }}>Mots clès</span>
            </Col>
            <Col span={24}>
              <Card size="small">
                {externalData?.data?.video?.tags?.map((tag) => (
                  <Tag closable onClose={preventDefault}>
                    {tag}
                  </Tag>
                ))}
              </Card>
            
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Row gutter={[20, 0]} justify="space-between">
                <Col span={12}>
                  <span style={{ fontFamily: "system-ui" }}>transcription</span>
                </Col>
                <Col span={12}>
                  <span style={{ fontFamily: "system-ui" }}>Licence</span>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[20, 0]} justify="space-between">
                <Col span={12}>
                  <Form.Item name="transcription">
                    <Input
                      onChange={handleChangeInputs}
                      name="transcription"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="copyright">
                    <Input
                      onChange={handleChangeInputs}
                      name="copyright"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Checkbox
                onChange={(e) => {
                 
                  setFileUpload(e.target.checked);
                }}
              >
                Joindre des fichiers
              </Checkbox>
            </Col>
          </Row>
        </Col>
      </Row>
      {FileUpload && (
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
      )}
    </>
  );
};
