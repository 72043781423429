import { useTranslation } from "react-i18next";
import ToolbarGrid from "../Components/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  AreTheyDoneTranscoding,
  cleanMultiDeleteIds,
  triggerMultiDeleteModal,
} from "../../Podcast/utils/action";
import { useEffect, useState } from "react";
import { setSearchKeyWord, setFilter, setOnDeleteOff } from "../utils/action";
import "../../index.css";
import "./podcast.scss";
import { Dialog, DialogContent } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "../../userData/userDataActions";
import { ClipLoader } from "@empreinte/components.clip-loader";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../utils/LoadingScreen";
import i18next from "i18next";
import { useHistory } from "react-router-dom";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import MediaNotfound from "../../MediaNotfound";
import { SearchBar } from "@empreinte/components.search-bar";
import { Header } from "@empreinte/components.header";
import { ModalConfirm } from "@empreinte/components.modal-confirm";
import { SelectList } from "@empreinte/components.select-list";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Hooks from "../utils/hooks";
import { PodcastGraphQlFetchData } from "../graph-ql-podcast/GraphQlFetchData";
import { TYPES } from "../utils/types";
import AddIcon from "@mui/icons-material/Add";

const Podcast = () => {
  const { GETPODCASTLIST } = PodcastGraphQlFetchData();
  const { DeleteMultiplePodcasts, ArchivePodcasts } = Hooks();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const lang = queryParam.get("lang");
  const onDeleteGroup = useSelector(
    (state) => state.ReducerGetPodcastData.onDelete
  );
  const MultiDeleteId = useSelector(
    (state) => state.ReducerGetPodcastData.MultiDeleteId
  );
  const deletePodcastData = useSelector(
    (state) => state.ReducerGetPodcastData.deletePodcastData
  );
  const singlePodcastDelete = useSelector(
    (state) => state.ReducerGetPodcastData.singlePodcastDelete
  );
  const editedPodcast = useSelector(
    (state) => state.ReducerGetPodcastData.isPodcastEdited
  );
  const addedPodcast = useSelector(
    (state) => state.ReducerGetPodcastData.isPodcastAdded
  );
  const isDeleted = useSelector(
    (state) => state.ReducerGetPodcastData.isDeleted
  );
  const doneDelete = useSelector(
    (state) => state.ReducerGetPodcastData.doneDelete
  );
  const modules = useSelector((state) => state.ReducerUserData.modules);
  const isSuperAdmin = useSelector(
    (state) => state.ReducerUserData.isSuperAdmin
  );
  const downloadmodal = useSelector(
    (state) => state.ReducerGetPodcastData.success_download
  );
  const loadingscreen = useSelector(
    (state) => state.ReducerGetPodcastData.on_loading_screen
  );

  const loading = useSelector((state) => state.ReducerUserData.loading);

  const unautorizedPodcast = useSelector(
    (state) => state.ReducerGetPodcastData.unauthorizedPodcast
  );

  const unauthorizedUser = useSelector(
    (state) => state.ReducerUserData.unauthorizedUser
  );
  const FilterList = useSelector(
    (state) => state.ReducerGetPodcastData.FilterList
  );
  const [SearchWord, setSearchWord] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [Filter, setFilterValue] = useState(FilterList);
  const [offset, setOffset] = useState(0)
  const [searchWorld, setSearchWorld] = useState("");
const [pagination, setPagination] = useState(false);
  const isArchivedPage = queryParam.get("archived");
  useEffect(() => {
		if (isArchivedPage == "true") {
			setFilterValue(convertBoolean("true"));
			dispatch(setFilter(convertBoolean("true")));
		}
	}, [isArchivedPage]);


const handlePageChange = (page) => {
	setCurrentPage(page);
	setPagination(true);
};
const handlePageSizeChange = (size) => {
	setPageSize(size);
};
  useEffect(() => {
    if (isDeleted) {
      dispatch({ type: TYPES.is_Deleted, payload: false });
    }else if(doneDelete) {
      dispatch({ type: TYPES.done_Delete, payload: false });
    }
  }, [isDeleted, doneDelete, dispatch]);

 useEffect(() => {
		if (
			pagination === true ||
			searchWorld === "" ||
			(Filter === true && searchWorld === "") ||
			editedPodcast ||
			addedPodcast ||
			onDeleteGroup ||
			deletePodcastData ||
			singlePodcastDelete
		) {
			const offset = (currentPage - 1) * pageSize;
			setOffset(offset);
			GETPODCASTLIST({
				variables: {
					where: {
						isArchived: isArchivedPage == "true" ? true : Filter,
						...(searchWorld && { titleContains: searchWorld }),
					},
					pagination: { limit: pageSize, offset },
					orderBy: { direction: "DESC" },
				},
			});
		}
 }, [
		pageSize,
		currentPage,
		Filter,
		editedPodcast,
		addedPodcast,
		onDeleteGroup,
		deletePodcastData,
		singlePodcastDelete,
 ]);
  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language === "null") {
      i18next.changeLanguage("fr");
      localStorage.setItem("lang", "fr");
    }
    window.addEventListener("message", function (event) {
      if (event.data?.info === "updateLang") {
        i18next.changeLanguage(event.data.lang);
        localStorage.setItem("lang", event.data.lang);
      }
      if (event.data?.info === "redirectHomepage") {
        history.push("/ws-media/podcasts");
      }
    });
  }, []);

  let convertBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value === "true") return true;
      if (value === "false") return false;
    }
    return value;
  };

  const handleChange = (event) => {
    setFilterValue(convertBoolean(event.target.value));
    dispatch(setFilter(convertBoolean(event.target.value)));
    setCurrentPage(1)
  };

  const handleMultiDelete = () => {
    if (Filter) {
      DeleteMultiplePodcasts(MultiDeleteId);
      setCurrentPage(1)
      GETPODCASTLIST({
                variables: {
                  where: { isArchived: Filter },
                  pagination: { limit: 10, offset:0 },
                  orderBy: { direction: "DESC" },
                },
              });
    } else {
      ArchivePodcasts(MultiDeleteId);
      setCurrentPage(1)
      GETPODCASTLIST({
                variables: {
                  where: { isArchived: Filter },
                  pagination: { limit: 10, offset:0 },
                  orderBy: { direction: "DESC" },
                },
              });
    }
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSearch = (e) => {
    setSearchWord(e.target.value);
    setOffset(0)
    setCurrentPage(1)
  };

  const handleKeyDown = (e) => {
	if (e.key === "Enter") {
     setPagination(false);
		setSearchWorld(e.target.value);
    setOffset(0);
		setCurrentPage(1);
    if(pagination === false) {
 GETPODCASTLIST({
		variables: {
			where: {
				isArchived: isArchivedPage == "true" ? true : Filter,
				...(e.target.value && { titleContains: e.target.value }),
			},
			pagination: { limit: pageSize, offset },
			orderBy: { direction: "DESC" },
		},
 });
    } }}

  if (loadingscreen || loading) {
    return <LoadingScreen />;
  } else if (unautorizedPodcast || unauthorizedUser) {
    return <MediaNotfound />;
  }

  return (
		<div className="containerPodcast">
			<Header
				className="header_title"
				firstBtnTitle={t("Podcast.Add")}
				title="Podcast"
				onMainPage={true}
				hasPermission={"podcast_post_add"}
				mainPageBtnAction={() => history.push("/podcast/upload")}
				firstBtnIcon={<AddIcon />}
			/>
			<div className="sous-header">
				<div className={onDeleteGroup ? "onDelete" : null}>
					{onDeleteGroup && MultiDeleteId.length > 0 ? (
						<div className="messageDelete">
							<DeleteIcon
								onClick={() => {
									handleClickOpen();
									dispatch(triggerMultiDeleteModal());
								}}
								sx={{ marginLeft: "0.8%", cursor: "pointer", color: "black" }}
							/>
							<div className="suppTextPodcats">
								{MultiDeleteId.length} {t("élément(s) sélectionné(s)")}
							</div>
						</div>
					) : null}

					{/* MultiDelete Modal */}
					{doneDelete ? null : (
						<>
							{" "}
							{isDeleted ? (
								<Dialog
									open={open}
									onClose={handleClose}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
								>
									<DialogContent sx={{ height: "50%" }}>
										<ClipLoader color="inherit" thickness={1.6} style={{ marginTop: "6px" }} />
									</DialogContent>
								</Dialog>
							) : (
								<>
									<ModalConfirm
										open={open}
										fullWidth={true}
										className="confirm-modal-delete"
										onClose={handleClose}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
										title={t("Podcast.Are you sure you want to delete the selected podcasts ?")}
										content={t("Podcast.add-edit.Selected items will be deleted")}
										CancelBtnName={t("Podcast.add-edit.cancel")}
										onCancel={() => {
											handleClose();
										}}
										ConfirmBtnName={t("Podcast.add-edit.Confirm")}
										onConfirm={handleMultiDelete}
									/>
								</>
							)}
						</>
					)}
					{downloadmodal ? (
						<Dialog
							open={true}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogContent sx={{ height: "50%" }}>
								<ClipLoader color="inherit" thickness={1.6} style={{ marginTop: "6px" }} />
							</DialogContent>
						</Dialog>
					) : null}

					{/*  */}
					<div className={onDeleteGroup ? "searchBarOnDelete" : "searchBar"}>
						<div className="shadowShouldBeADDED">
							<Box sx={{ minWidth: 250 }}>
								<FormControl fullWidth>
									<SelectList
										className="home-select-podcast"
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={Filter}
										handleChange={handleChange}
										data={[
											{ value: "false", label: t("Podcast.all") },
											{ value: "true", label: t("Podcast.trash") },
										]}
									/>
								</FormControl>
							</Box>
						</div>
						<div className="shadowShouldBeADDED widthFullForPodcastSearch">
							<SearchBar
								onCancelSearch={() => dispatch(setSearchKeyWord(""))}
								name="SearchWord"
								value={SearchWord}
								setSearchText={(e) => setSearchWord(e.target.value)}
								className="searchComponent"
								placeholder={t("Podcast.Search...")}
								onKeyDown={handleKeyDown}
							/>
						</div>
					</div>
				</div>
				{(modules?.podcast_list_dtable || isSuperAdmin) && (
					<div className="data-table" has-permission="podcast_list_dtable">
						<ToolbarGrid
							setCurrentPage={handlePageChange}
							currentPage={currentPage}
							setPageSize={handlePageSizeChange}
							pageSize={pageSize}
						/>
					</div>
				)}{" "}
			</div>
		</div>
	);
};

export default Podcast;
