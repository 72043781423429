import {useEffect, useState} from "react";
import Hooks from "./utils/hooks";
import { useAppSelector } from "../redux/hooks";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import { ExportVideoModal } from "./ExportVideoModal";
import { useDispatch } from "react-redux";
import MediaNotfound from "../MediaNotfound";
import { AbonnementModal } from "./modals/abonnementModal";
import { MetaDataVideoModal } from "./modals/metadataVideoModal";
import { DetailsModal } from "./modals/detailModal";
import { EventHandler } from "../Medias/utils/event";
import { setVideoFile, setthumbsData } from "../addMedia/store/addMediaActions";
import DownloadModalFiles from "./modals/downloadModalFiles";
import { AddHooks } from "../addMedia/utils/hooks";
import VideoDetails from "./modals/VideoDetails";
import DeleteAudioDescription from "../AddAudioDescription/DeleteAudioDescription";
import "../App.css";
import { ShowCadenasModal } from "./modals/ShowCadenasModal";

function Media() {
  const { getMediaList, idVideo, visible,nameVideo } = Hooks();
  const dispatch = useDispatch();
  const { EventListener } = EventHandler();
  const listMediaurl = useAppSelector((state) => state.MediaReducer.listVideos);
  const modalToOpen = useAppSelector((state) => state.MediaReducer.modalToOpen);
  const video = useAppSelector((state) => state.MediaReducer.urlVideo);

  const unautorizedMedia = useAppSelector(
    (state) => state.MediaReducer.unautorizedMedia
  );
  const mediaLoading = useAppSelector(
    (state) => state.MediaReducer.mediaLoading
  );
  const iframeKey = useAppSelector(state=>state.MediaReducer.iframeKey)
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const [lang, setLang] = useState(
    queryParam.get("lang") || 
      (localStorage.getItem("i18nextLng") !== "null" &&
        localStorage.getItem("i18nextLng")) ||
      "fr"
  );
  const color = queryParam.get("color");
  const [key, setKey] = useState(false);

  useEffect(() => {
    dispatch(setthumbsData({}))
    dispatch(setVideoFile(""))
    getMediaList();
    EventListener();
    window.parent.postMessage({info:"diffusion",value:"ongoing"}, "*")
    window.addEventListener("message", function (event) {
        if (event.data ?. info === "updateLang") {
            setLang(event.data.lang)
            i18next.changeLanguage(event.data.lang)
            localStorage.setItem("lang", event.data.lang)
        } else if (event.data && event.data.info && event.data.info === "videoDeletedSuccessfully") {
            setKey(iframeKey + 1);
        }
    });
}, []);

useEffect(() => {
    if(iframeKey) {
        setKey(iframeKey);
    }
}, [iframeKey]);
  return (
    <div>
      {!mediaLoading && unautorizedMedia ? (
        <MediaNotfound />
      ) : (
        <div>
            <iframe
              title={listMediaurl}
              className="iframe-video"
              id="iframe_list"
              allowFullScreen=""
              frameBorder="0"
              key={key}
              src={listMediaurl &&
                `https://${listMediaurl}${
                  lang && color
                    ? "&lang=" + lang + "&color=" + color
                    : lang && !color
                    ? "&lang=" + lang
                    : !lang && color
                    ? "&color=" + color
                    : ""
                }`
              }
              ></iframe>
              {modalToOpen && modalToOpen === "showModalExport" && <ExportVideoModal video={video} idVideo={idVideo}/>}            
              {modalToOpen && modalToOpen === "showVideoDetail" && <MetaDataVideoModal video={video} idVideo={idVideo} nameVideo={nameVideo}/>}         
              {modalToOpen && modalToOpen === "addSubscribers" && <AbonnementModal video={video} idVideo={idVideo}/>}   
              {modalToOpen && modalToOpen === "showModalForDownloadVideos" && <DownloadModalFiles video={video} idVideo={idVideo} />}
              {modalToOpen && modalToOpen.type && modalToOpen.type === "showListAction" && <DetailsModal />}
              {modalToOpen == "showModalForDetailVideo" && (<VideoDetails idVideo={idVideo} />)}
              {modalToOpen == "showCadenasModal" && (<ShowCadenasModal video={video} idVideo={idVideo} />)}
              {modalToOpen == "deleteDescription" && (<DeleteAudioDescription  video={video} idVideo={idVideo}/>)}
          </div>
          )}
    </div>
  );
}

export default Media;
