import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useState} from "react";

const EmbedCodeTemplate = ({link}) => {
    const template = `<iframe
style="width:100%;height:100%"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
src="${link}"></iframe>`
    const [copyIframe, setCopyIframe] = useState(false)
    const copyLink = (value) => {
        navigator.clipboard.writeText(value);
        setCopyIframe(true)
        setTimeout(() => {
            setCopyIframe(false)
        }, 1000)

    };
    return (
        <>
            <textArea className="textAreaTemplate">
                {template}</textArea>
            {
            copyIframe ? <CheckCircleOutlineIcon sx={
                {color: "green"}
            }/> : <ContentCopyIcon onClick={
                () => copyLink(template)
            }/>
        } </>
    )
}

export default EmbedCodeTemplate;
