import {
    Breadcrumb,
    Button,
    PageHeader,
    Row,
    Col,
    Collapse
} from "antd";
import {
    DeleteOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    DownloadOutlined,
    ArrowRightOutlined
} from "@ant-design/icons";
// redux
import {useTranslation} from "react-i18next";
const {Panel} = Collapse;

function InfosMedia() {
    const {t} = useTranslation();
    return (
        <div className="infosMedias">
            <Breadcrumb>
                <Breadcrumb.Item href="">
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                    {
                    t("media_center.bread_crumb.medias")
                } </Breadcrumb.Item>
                <Breadcrumb.Item> {
                    t("media_center.bread_crumb.all")
                }</Breadcrumb.Item>
                <Breadcrumb.Item>
                    Info</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-page-header-ghost-wrapper ">
                <PageHeader onBack={
                        () => window.history.back()
                    }
                    title="Infos :"
                    subTitle="imagename.png"
                    className="media_page_header"
                    extra={
                        [
                            <Button icon={<DownloadOutlined/>}
                                className="page_header_button"
                                key="1">
                                {
                                t("media_center.table_actions.download")
                            } </Button>,
                            <Button icon={<ArrowRightOutlined/>}
                                className="page_header_button"
                                key="2">
                                {
                                t("media_center.media_info.page_header.move")
                            } </Button>,
                            <Button icon={<DeleteOutlined/>}
                                className="page_header_button"
                                key="3">
                                {
                                t("media_center.table_actions.delete")
                            } </Button>,
                        ]
                }></PageHeader>
            </div>

            <Row gutter={16}
                className="media_data">
                <Col xs={24}
                    sm={24}
                    md={17}
                    lg={17}
                    xl={17}
                    xxl={17}>
                    <img className="media_info_image" src="https://cdn.pixabay.com/photo/2022/02/12/13/29/desert-7008952_960_720.jpg" alt=""/>
                </Col>
                <Col xs={24}
                    sm={24}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}>
                    <div className="media_infos">
                        <div className="info_section">
                            <span className="info_section_title">
                                indentification
                            </span>
                            <div className="separator"></div>
                            <div className="info_section_table">
                                <div className="info_row">
                                    <Row>
                                        <Col span={12}>
                                            <span className="info_title">ID :</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_value">125</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_title">Media :</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_value">Photo</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_title">Titre :</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_value">imagename.png</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_title">Date de production :</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_value">29/04/2020</span>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div className="info_section">
                            <span className="info_section_title">
                                {" "}
                                Caractéristiques techniques{" "} </span>
                            <div className="separator"></div>
                            <div className="info_section_table">
                                <div className="info_row">
                                    <Row>
                                        <Col span={12}>
                                            <span className="info_title">Format :</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_value">Exif</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_title">Taille :</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_value">3.04 Mo</span>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div className="info_section">
                            <span className="info_section_title">
                                Indexation
                            </span>
                            <div className="separator"></div>
                            <div className="info_section_table">
                                <div className="info_row">
                                    <Row>
                                        <Col span={12}>
                                            <span className="info_title">Thème :</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_value">
                                                Photographie et vidéaste
                                            </span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_title">Mot clés :</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_value">3.04 Mo</span>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div className="info_section">
                            <span className="info_section_title">
                                Droits
                            </span>
                            <div className="separator"></div>
                            <div className="info_section_table">
                                <div className="info_row">
                                    <Row>
                                        <Col span={12}>
                                            <span className="info_title">Licence :</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_value">Creative Common</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_title">Mot clés :</span>
                                        </Col>
                                        <Col span={12}>
                                            <span className="info_value">3.04 Mo</span>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <Collapse defaultActiveKey={
                                ["1"]
                            }
                            className="exif_collapse"
                            expandIconPosition="right">
                            <Panel header="This is panel header 1" key="1"
                                extra={<InfoCircleOutlined/>}>
                                <div className="texte_Exif">
                                    <p>
                                        <span>Image Width -</span>
                                        <span>{"urlIframe.iframe.exif.width"}</span>
                                    </p>
                                    <p>
                                        <span>Image Height -</span>
                                        <span>{"urlIframe.iframe.exif.height"}</span>
                                    </p>
                                    <p>
                                        <span>Compression -</span>
                                        <span>{"urlIframe.iframe.exif.compression"}</span>
                                    </p>
                                    <p>
                                        <span>Photometric Interpretation -</span>
                                        <span> {"urlIframe.iframe.exif.PhotometricInterpretation"} </span>
                                    </p>
                                    <p>
                                        <span>Make -</span>
                                        <span>{"urlIframe.iframe.exif.make"}</span>
                                    </p>
                                    <p>
                                        <span>Orientation -</span>
                                        <span>{"urlIframe.iframe.exif.orientation"}</span>
                                    </p>
                                    <p>
                                        <span>X-Resolution -</span>
                                        <span>{"urlIframe.iframe.exif.xResolution"}</span>
                                    </p>
                                    <p>
                                        <span>Y-Resolution -</span>
                                        <span>{"urlIframe.iframe.exif.yResolution"}</span>
                                    </p>
                                    <p>
                                        <span>Planar Configuration -</span>
                                        <span>{"urlIframe.iframe.exif.planConfiguration"}</span>
                                    </p>
                                    <p>
                                        <span>Software -</span>
                                        <span>{"urlIframe.iframe.exif.software"}</span>
                                    </p>
                                    <p>
                                        <span>Date and Time (Modified) -</span>
                                        <span>{"urlIframe.iframe.exif.datetimeUp"}</span>
                                    </p>
                                    <p>
                                        <span>YCbCr Positioning -</span>
                                        <span>{"urlIframe.iframe.exif.YCbCrPositioning"}</span>
                                    </p>
                                    <p>
                                        <span>ISO Speed -</span>
                                        <span>{"urlIframe.iframe.exif.ISOSpeed"}</span>
                                    </p>
                                    <p>
                                        <span>Exif Version -</span>
                                        <span>{"urlIframe.iframe.exif.exifVersion"}</span>
                                    </p>
                                    <p>
                                        <span>Date and Time (Original) -</span>
                                        <span>{"urlIframe.iframe.exif.datetimeOr"}</span>
                                    </p>
                                    <p>
                                        <span>Components Configuration -</span>
                                        <span> {"urlIframe.iframe.exif.ComponentsConfiguration"} </span>
                                    </p>
                                    <p>
                                        <span>User Comment -</span>
                                        <span>{"urlIframe.iframe.exif.UserComment"}</span>
                                    </p>
                                    <p>
                                        <span>Flashpix Version -</span>
                                        <span>{"urlIframe.iframe.exif.FlashpixVersion"}</span>
                                    </p>
                                    <p>
                                        <span>Color Space -</span>
                                        <span>{"urlIframe.iframe.exif.ColorSpace"}</span>
                                    </p>
                                    <p>
                                        <span>GPS Version ID -</span>
                                        <span>{"urlIframe.iframe.exif.GPSVersionID"}</span>
                                    </p>
                                    <p>
                                        <span>GPS Latitude -</span>
                                        <span>{"urlIframe.iframe.exif.GPSLatitude"}</span>
                                    </p>
                                    <p>
                                        <span>Keywords -</span>
                                        <span>{"urlIframe.iframe.exif.Keywords"}</span>
                                    </p>
                                    <p>
                                        <span>Application Record Version -</span>
                                        <span> {"urlIframe.iframe.exif.ApplicationRecordVersion"} </span>
                                    </p>
                                    <p>
                                        <span>IPTCDigest -</span>
                                        <span>{"urlIframe.iframe.exif.IPTCDigest"}</span>
                                    </p>
                                    <p>
                                        <span>XMPToolkit -</span>
                                        <span>{"urlIframe.iframe.exif.XMPToolkit"}</span>
                                    </p>
                                    <p>
                                        <span>Subject -</span>
                                        <span>{"urlIframe.iframe.exif.Subject"}</span>
                                    </p>
                                    <p>
                                        <span>Creator Tool -</span>
                                        <span>{"urlIframe.iframe.exif.CreatorTool"}</span>
                                    </p>
                                    <p>
                                        <span>version -</span>
                                        <span>{"urlIframe.iframe.exif.version"}</span>
                                    </p>
                                    <p>
                                        <span>Document ID -</span>
                                        <span>{"urlIframe.iframe.exif.DocumentID"}</span>
                                    </p>
                                    <p>
                                        <span>code -</span>
                                        <span>{"urlIframe.iframe.exif.code"}</span>
                                    </p>
                                    <p>
                                        <span>Instance ID -</span>
                                        <span>{"urlIframe.iframe.exif.InstanceID"}</span>
                                    </p>
                                    <p>
                                        <span>Camera ID -</span>
                                        <span>{"urlIframe.iframe.exif.CameraID"}</span>
                                    </p>
                                    <p>
                                        <span>Camera Type -</span>
                                        <span>{"urlIframe.iframe.exif.CameraType"}</span>
                                    </p>
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default InfosMedia;
