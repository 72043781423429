import { useState, useEffect, useRef } from "react";

import {
  Row,
  Col,
  Input,
  Tooltip,
  Tag,
  Upload,
  Form,
  Select,
  Checkbox,
} from "antd";
import "../addMedia.scss";
import { AddHooks } from "../utils/hooks";
import "./formeditvideo.css"
import {
  CloudUploadOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux"
import { useAppSelector } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
const { Dragger } = Upload;
// antd init : 
const { TextArea } = Input;
export const AdditionalInfo = (props) => {
  const { t } = useTranslation();

  const { inputValue, setInputValue, values, setFileUpload, FileUpload, tags, setTags, Option } = props;
  const {
    handleChangeInputs,
    updatecontributor,
    updateFilesVideos,
    updateKeywords,
    deleteAttachedFile
  } = AddHooks();


  const contributoList = useAppSelector(
    (state) => state.addMediaReducer.contributoList
  );
  const externalData = useAppSelector(
    (state) => state.addMediaReducer.externalData
  );

  const modules = useSelector(state => state.ReducerUserData.modules)
  const isSuperAdmin = useSelector(
    (state) => state.ReducerUserData.isSuperAdmin
  );
  const [editInputValue, setEditInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [inputVisible, setInputVisible] = useState(false);


  const editInputRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    return () => updatecontributor("")
  }, [])
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    if (editInputValue && tags?.indexOf(editInputValue) === -1) {
      const newTags = [...tags];
      newTags[editInputIndex] = editInputValue;
      setTags(newTags);
    }
    setEditInputIndex(-1);
    setInputValue("");
  };
  const handleClose = (removedTag) => {
    const newTags = tags?.filter((tag) => tag !== removedTag);
    setTags(newTags);
    updateKeywords(newTags);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags?.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue.trim()]);
      updateKeywords([...tags, inputValue.trim()]);
    }
    setInputVisible(false);
    setInputValue("");
  };


  let filesListVideo = null;
  const handleFilesFormat = () => {
    if (values && values.filesVideo) {
      filesListVideo = values.filesVideo.map(file => {
        return { uid: file.id, url: file.title, name: file.fileName, status: "done" };
      });
    }
    return filesListVideo
  }
  const propsFile = {
    name: 'empApiFileForm[thumb]',
    multiple: false,
    listType: "picture",
    action: window.process.env.API_HOST_GLOBAL + "/video/video/upload/attached-file",
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem("authToken"),
    },
    defaultFileList: handleFilesFormat(),
    onRemove: (file) => {
      const { status } = file;
      let filesDelete = []
      if (status === 'done') {
        if (values?.filesVideo && file) {
          filesDelete = values.filesVideo.find(obj => obj.fileName === file.name);
        }
        const filteredFileList = [...values.filesVideo].filter(fl => fl.fileName !== file.name)
        updateFilesVideos(filteredFileList, "remove")
        filesDelete?.id && deleteAttachedFile(file?.name, filesDelete.id)
      }
    },

    data: file => {
      return {
        'flowChunkNumber': 1,
        'flowChunkSize': 1048576,
        'flowCurrentChunkSize': file.size,
        'flowTotalSize': file.size,
        'flowIdentifier': file.size + file.name,
        'flowFilename': file.name,
        'flowRelativePath': file.name,
        'flowTotalChunks': 1
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        const Newfile = [...values.filesVideo, { title: info.file.response.location.path, fileName: info.file.name }]
        updateFilesVideos(Newfile, "edit")
      }
    },
  };
  return (
    <Form className='additional-info'>
      <Row>
        <Col span={24}>
          <span style={{ fontFamily: "system-ui" }}>Description</span>
          <Form.Item
            initialValue={values?.description}
            key={values?.description}
            name="description"
          >
            <TextArea
              rows={4}
              onBlur={handleChangeInputs}
              name={"description"}
              id={"description"}
              defaultValue={values?.description}
              value={externalData?.data?.video?.description}
              style={{ resize: 'none' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        {(modules.media_video_form_transcription || isSuperAdmin) && <Col span={24}>
          <span has-permission="media_video_form_transcription" style={{ fontFamily: "system-ui" }}>Transcription</span>
          <Form.Item initialValue={values.transcription} name="transcription">
            <TextArea
              rows={4}
              onBlur={handleChangeInputs}
              key={values?.transcription}
              placeholder={values.transcription}
              defaultValue={values.transcription}
              value={values.transcription}
              name="transcription"
              style={{ resize: 'none' }}
            />
          </Form.Item>
        </Col>}
      </Row>
      <Row gutter={[20, 0]} justify="space-between">
        {(modules.media_video_contributor_form_no_api || isSuperAdmin) && <Col xs={24} sm={24} md={12}>
          <span has-permission="media_video_contributor_form_no_api" style={{ fontFamily: "system-ui" }}>
            {t("video.contributeur")}
          </span>
          {contributoList?.contributors?.length !== 0 &&
            values?.contributor?.length !== 0 && (
              <Form.Item
                name="contributors"
                initialValue={JSON.stringify(
                  contributoList?.contributors?.find(
                    (value) => value.id === values.contributor
                  )?.name
                )}
              >

                <Select
                  defaultValue={
                    values?.contributor?.length !== 0
                      ? JSON.stringify(
                        contributoList?.contributors?.find(
                          (value) => value.id === values.contributor
                        )?.name
                      )
                      : ""
                  }
                  showSearch
                  onChange={(e) => updatecontributor(e)}
                  style={{ width: "100%" }}
                  placeholder={
                    contributoList?.contributors?.find(
                      (value) => value.id === values.contributor
                    )?.name
                  }
                  optionFilterProp="children"
                >
                  {contributoList?.contributors?.map((row) => (
                    row?.name?.trim() && <Option key={row.id} value={row.id}>
                      {row.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          {values?.contributor?.length === 0 && (
            <Form.Item name="contributors">
              <Select
                showSearch
                onChange={(e) => updatecontributor(e)}
                style={{ width: "100%" }}
                optionFilterProp="children"
              >
                {contributoList?.contributors?.map((row) => (
                 row?.name?.trim() && <Option key={row.id} value={row.id}>
                 {row?.name}
               </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>}
        <Col xs={24} sm={24} md={12}>
          <span style={{ fontFamily: "system-ui" }}>
            {t("video.author")}
          </span>
          <Form.Item name="author" initialValue={values?.author}>
            <Input
              onBlur={handleChangeInputs}
              key={values?.author} name={"author"}
              id={"author"} defaultValue={values?.author}
              value={values?.author} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[20, 0]} justify="space-between">
        <Col xs={24} sm={24} md={12}>
          <span style={{ fontFamily: "system-ui" }}>
            Copyright
          </span>
          <Form.Item name="copyright">
            <Input
              onBlur={handleChangeInputs}
              key={values?.copyright}
              defaultValue={values.copyright}
              value={values.copyright}
              placeholder={values.copyright}
              name="copyright"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <span style={{ fontFamily: 'system-ui' }}>{t("video.keywords")}</span>


          <div className='keywords'>
            <div className="tags-container">
              {tags?.map((tag, index) => {
                if (editInputIndex === index) {
                  return (
                    <Input
                      ref={editInputRef}
                      key={tag}
                      size="small"
                      className="tag-input"
                      value={editInputValue}
                      onChange={handleEditInputChange}
                      onBlur={handleEditInputConfirm}
                      onPressEnter={handleEditInputConfirm}
                    />
                  );
                }
                const isLongTag = tag?.length > 20;
                const tagElem = (
                  <div key={tag} className="tag-container" >
                    <Tag
                      className="edit-tag"
                      key={tag}
                      closable={true}
                      onClose={() => handleClose(tag)}
                    >
                      <span
                        onDoubleClick={(e) => {
                          if (index !== 0) {
                            setEditInputIndex(index);
                            setEditInputValue(tag);
                            e.preventDefault();
                          }
                        }}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </span>
                    </Tag>
                  </div>
                );
                return isLongTag ? (
                  <Tooltip title={tag} key={tag}>
                    {tagElem}
                  </Tooltip>
                ) : (
                  tagElem
                );
              })}
            </div>
            <Input
              id="video-input"
              ref={inputRef}
              type="text"
              size="small"
              className="tag-input"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
              placeholder={t("video.add_tag")}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row gutter={[0, 8]}>
            {(modules.media_video_join_files_form_no_api || isSuperAdmin) && <Checkbox checked={FileUpload} onChange={(e) => { setFileUpload(e.target.checked) }}><b has-permission="media_video_join_files_form_no_api" >{t("video.join_files")}</b></Checkbox>}
          </Row>
        </Col>
        <Col span={24} >
          <div>
            {FileUpload &&
              <Dragger {...propsFile}>
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ color: "gray" }} />
                </p>
                <b>Glisser et déposer des fichiers ici</b>
              </Dragger>
            }
          </div>
        </Col>
      </Row>
    </Form>

  )

}
