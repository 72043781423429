import { useDispatch, useSelector } from "react-redux";

const Player = ({ Videos, setOffVideo }) => {
  const dispatch = useDispatch();

  return (
    <div className="all">
      <div class="video-container">
        <iframe
          ng-if="vm.link"
          ng-src={`https:${Videos?.embed}`}
          webkitallowfullscreen=""
          mozallowfullscreen=""
          allowFullScreen=""
          className="ng-scope"
          src={`https:${Videos?.link}`}
          width="800"
          height="400"
          frameBorder="0"
        ></iframe>
        {JSON.stringify(Videos).length > 2 && (
          <button
            onClick={() => {
              dispatch(setOffVideo());
            }}
            type="button"
            aria-label="Close"
            className="close-btn"
          >
            ×
          </button>
        )}
      </div>
    </div>
  );
};
export default Player;
