import { useMutation } from "@apollo/client";
import { graphQL_schema } from "./graphQL";
import { useDispatch } from "react-redux";
import { getPlayList } from "../Redux/actions";

export const GraphQLFetchDataPlaylist = () => {
	 const dispatch = useDispatch();
	const [GetPlaylists] = useMutation(graphQL_schema().Get_All_Playlists, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data) => {
            if(data)
           { dispatch(getPlayList({ data }))
}
        },
	});


	return {
		GetPlaylists
	};
};
