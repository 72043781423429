import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserData,
  getDirectsPlanned,
  getVideosNumber,
  getAllPlayLists,
  getAllPodcasts,
  getAllVideos,
} from "./api";

export const getModules = createAsyncThunk(
  "Dashboard/getModules",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getUserData();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getDirectsPlannedNumber = createAsyncThunk(
  "Dashboard/getDirectsPlannedNumber",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getDirectsPlanned(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getVideoNumberForStat = createAsyncThunk(
  "Dashboard/getVideoNumberForStat",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getVideosNumber();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getPlayLists = createAsyncThunk(
  "Dashboard/getPlayLists",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getAllPlayLists(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getPodcasts = createAsyncThunk(
  "Dashboard/getPodcasts",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getAllPodcasts(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getVideos = createAsyncThunk(
  "Dashboard/getVideos",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getAllVideos();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
//getAllVideos
//getAllPodcasts
//getAllPlayLists
//getVideosNumber
//getDirectsPlanned
