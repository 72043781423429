import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../redux/hooks";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Hooks from "../utils/hooks";
import { useTranslation } from "react-i18next";
import { Button } from "@empreinte/components.button";
import {Label} from "@empreinte/components.label";
import { InputText } from "@empreinte/components.input-text";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
import { setAddMediaActions, setmetadataVideo } from "../../addMedia/store/addMediaActions";
import { AddHooks } from "../../addMedia/utils/hooks";
import { ModalInputs } from "@empreinte/components.modal-inputs";
export function ShowCadenasModal({idVideo,video, onCancel }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const{getVideoDataById,editupload}= AddHooks();
  const modalToOpen = useAppSelector((state) => state.MediaReducer.modalToOpen);
  const videoState = useAppSelector((state) => state.addMediaReducer.AddMediaConstraintsData);
  const cancelToken = axios .CancelToken.source();
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [password, setPassword] = useState('');
  const handlePasswordChange = (event) => {
    const mypassword = event.target.value;
    setPassword(mypassword);
    handleMatchPaswword();
  };
  const handleConfirmPasswordChange = (event) => {
    const confirmPassword = event.target.value;
    setConfirmPassword(confirmPassword);
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "passwordInternet",
        addMediaConstraintsDataValueChange:  confirmPassword,

      })
    );
    dispatch(
      setAddMediaActions({
        addMediaConstraintsDataNameChange: "secure",
        addMediaConstraintsDataValueChange:  true,

      })
    );

    dispatch(
			setAddMediaActions({
				addMediaConstraintsDataNameChange: "isSecuredOnInternet",
				addMediaConstraintsDataValueChange: !videoState?.isSecuredOnInternet,
			})
		);
    handleMatchPaswword();
  }
const handleConfirmBtn = async() =>{ 
  await editupload(idVideo);
  handleCancel();

    }
const handleMatchPaswword = () =>{

  setPasswordsMatch(password!== "" && (password === confirmPassword)  );
 
    };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);

  };
  useEffect(() => {
      getVideoDataById(idVideo,video.fileTitle,"edit",cancelToken);
  }, [idVideo,video]);

  useEffect(() => {
    handleMatchPaswword();
  }, [modalToOpen,password,confirmPassword]);

  const { handleCancel } = Hooks();

  return (
    <>
      
        <ModalInputs
               open={modalToOpen === "showCadenasModal"}
                title={t("video.CasenasModalTitle")}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							onClose={handleCancel}
              className="modal-inputs-h2"
              		>
                <div style={{ margin: '1px', flexDirection: 'column' }}>
      
       <Label  className="passwordLabel" 
     >{t("video.labelPassword")}</Label>
      <InputText 
   value={password}
   type={showPassword ? 'text' : 'password'}
fullWidth
   variant="outlined"
   handleChange={handlePasswordChange}
   inputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={togglePasswordVisibility}
           edge="end"
            aria-label="Toggle password visibility"
          >
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </InputAdornment>
      ),
    }}

></InputText>

<div style={{ marginBottom: '20px' }}></div>
<Label  className="passwordLabel" 
     >{t("video.labelConfirmPassword")}</Label>
<InputText

  value={confirmPassword}
  type={showPassword ? 'text' : 'password'}
  fullWidth  variant="outlined"
  handleChange={handleConfirmPasswordChange}
  inputProps={{
     endAdornment: (
       <InputAdornment position="end">
         <IconButton
           onClick={togglePasswordVisibility}
          edge="end"
           aria-label="Toggle password visibility"
         >
           {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
         </IconButton>
       </InputAdornment>
     ),
   }}
></InputText>

   
    <Button
    className="confirmBtn"
      title={t("video.btn_confirm")}
      variant="contained"
      component="label"
      disabled={!passwordsMatch}
      onClick=  {
        handleConfirmBtn
      
      }
      >
  </Button>
    </div>
   
   
  </ModalInputs>

        
    </>
  );
}