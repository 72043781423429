import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllPlayLists,
  getChannelsByThemes,
  getRmbox,
  getListVideo,
  GetFilteredListVideo,
  AddOnePlayList,
  getOnePlayListToEdit,
  deleteOnePlayList,
  getVideoPlayer,
  multyDelete,
  getOnePlayListToAdd,
  editOnePlayList,
  getUserData,
  getOnePlayListToView,
  GetListVideos
} from "./api";
import { message } from "antd";
import { t } from "i18next";

export const getModules = createAsyncThunk(
  "playlist/getModules",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getUserData();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPlayList = createAsyncThunk(
  "playlist/getPlayList",
  async ({ data }, { rejectWithValue }) => {
    try {
     
      return data?.Playlists;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getThemes = createAsyncThunk(
  "addEdit/getThemes",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getChannelsByThemes();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getrmbox = createAsyncThunk(
  "addEdit/getrmbox",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getRmbox();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const addPlayList = createAsyncThunk(
  "addEdit/addPlayList",
  async (empPlaylistForm, { rejectWithValue }) => {
    try {
      const { data } = await AddOnePlayList(empPlaylistForm);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editPlaylist = createAsyncThunk(
  "addEdit/editPlaylist",
  async (empPlaylistForm, { rejectWithValue }) => {
    try {
      const { data } = await editOnePlayList(empPlaylistForm);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPlayListToAdd = createAsyncThunk(
  "addEdit/getPlayListToAdd",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getOnePlayListToAdd();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getPlayListToEdit = createAsyncThunk(
  "addEdit/getPlayListToEdit",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await getOnePlayListToEdit(id);
      return data;
    } catch (error) {
       message.error({
					content: t("edit"),
					duration: 2,
				});
      return rejectWithValue(error.message);
    }
  }
);

export const getPlayListToView = createAsyncThunk(
  "addView/getPlayListToView",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await getOnePlayListToView(id);
      return data;
    } catch (error) {
       message.error({
					content: t("detail"),
					duration: 2,
				});
      return rejectWithValue(error.message);
    }
  }
);

export const deletePlayList = createAsyncThunk(
  "playlist/deletePlayList",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await deleteOnePlayList(id);
      message.success({
				content: t("oneDelete"),
			});
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const playerVideoById = createAsyncThunk(
  "Player/playerVideoById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await getVideoPlayer(id);
      return data;
    } catch (error) {
        message.error({
					content: t("testPlayer"),
				});
      return rejectWithValue(error.message);
    }
  }
);

export const DeleteMultiple = createAsyncThunk(
  "playlist/DeleteMultiple",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await multyDelete(id);
       message.success({
					content: t("multiDelete"),
				});

      return data;
    } catch (error) {
      message.error({
				content: t("serverError"),
			});
      return rejectWithValue(error.message);

    }
  }
);
