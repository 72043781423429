import { useState, useEffect } from "react";
import { Row, Input, Button, message, Form, Col } from "antd";
import { AddHooks } from "../utils/hooks";
import Hooks from "../../Medias/utils/hooks";
import { LinkOutlined,} from "@ant-design/icons";
import { useTranslation } from "react-i18next";


export const AddUrl=()=> {
  const { t } = useTranslation();
    const [Link, setLink] = useState("");
    const [isEmptyData, setIsEmptyData] = useState(false); 
    const [form] = Form.useForm();
    useEffect(() => {
      if (isEmptyData) {
        message.error({
          content: (
            <div style={{ backgroundColor: "white", padding: "10px" }}>
              <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>
              {t("urlupload.error_message")}              </p>
              <p>{t("urlupload.message_url")}</p>
            </div>
          ),
          duration: 3, 
          onClose: () => {
            setIsEmptyData(false); 
          },
        });
      }
    }, [isEmptyData]);
    
    const handleLinkChange = (e) => {
        setLink(e.target.value);
        form.setFieldsValue({ link: e.target.value });
      };
    
      const onFinish = async () => {
        const data = await extractDataVideo(Link);
    
        if (!data) {
          setIsEmptyData(true); 
        } else {
          setIsEmptyData(false); 
        }

        handleSecondAddMedia();
        getContributors();
        getThemesByChannel();
      };
      const {
        extractDataVideo,
        getContributors,
        getThemesByChannel,
        getMetaData,
        getThumbs,
      } = AddHooks();
      const { handleSecondAddMedia } = Hooks();
      return (
        <Row
          justify="center"
          align="middle"
          className="row_url_div"
        >
          <Col xs={24} sm={18} md={16} lg={14} xl={12}>
            <div style={{ textAlign: "center" }}>
              <h4 style={{
                color: "#4D292B",
                fontFamily: "",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                marginBottom: "20px",
              }}>{t("urlupload.add_url")}</h4>
    
              <Form form={form} onFinish={onFinish}>
              <Form.Item
              
  name="link"
  label=""
  rules={[
    {
      required: true,
      message: `${t("urlupload.required_url")}`,
    },
    {
      pattern: /^(http:\/\/|https:\/\/)/i,
      message: `${t("urlupload.message_url")}`,
    },
  ]}
>
  <div> 
    <Input
    className="input-url"
      value={Link}
      onChange={handleLinkChange}
      prefix={<LinkOutlined className={"icon_import"} />}
      placeholder={t("urlupload.input_url")}
    />
  </div>
</Form.Item>

                <Button
                className="button-url"
                  htmlType="submit"
                  type="primary"
                >
           {t("urlupload.btn_import")}        
        </Button>
              </Form>
            </div>
          </Col>
        </Row>
      );
    };
  
      
      
      


