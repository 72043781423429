import React, {useEffect, useState,useRef} from "react";
export const Hooks = () =>{
   
      const descriptionminimize = (str:any, show:boolean) => {
        if (show) return str;
        if (str && str.length > 10) {
          str = str.substring(0, 10) + " ...";
        }
        return str;
      };
    return({
        descriptionminimize
    })


}