import gql from "graphql-tag";

export const graphQL_shema = () => {
  
    const Get_All_Medias = gql `
    mutation {
        Medias {
        totalCount
          edges
          {node{
            Id
            Type
            Lang
            Dir
            SmilFile
            BdFile
            OgvFile
            WebMFile
            Link
            Mp4File
            IsTmpMp4
            SeenNbr
            Debit
            Width
            Height
            StartHour
            StartFrame
            StartMinute
            StreamingSource
            WebtvPlaylist{Id title }                                      
          }}
      }
      }
    `;

    const Get_All_Video = gql `
  mutation {
    Videos {
    totalCount
      edges
      {node{
        Id
        TemplateID
        FileTitle
        Title
        GenerateMultipleFormats
        InsertDate
       StartDate
        EndDate
        DispoFo Duration
        Secure
        Downloadable
        Offline
        Description
        Transcription
        Author
        Copyright
        
        KeyWords
        Logo
        MainPage
        IsTranscoded
        PushVideo
        CreatedAt
        UpdatedAt
        AudioDescription
        AudioDescriptionFile
        IsArchived
        VideoContributor{Id Vignette Username}
        WebtvThemes{Id UrlName Title}      
      }}
  }
  }`


    const createGroup = gql `
      mutation($input: CreateSubscriptionGroupInput!) {
        createSubscriptionGroup(input: $input) {
          Name
          Id
          Parent {
            Id
            Parent {
              Id
            }
          }
        }
      }`


    const deleteGroup = gql `
    mutation($id:Int!){
      DeleteSubscriptionGroup(id:$id)
    
    }
    
    `

    return({Get_All_Medias, Get_All_Video, createGroup, deleteGroup})

}
