import { createSlice } from "@reduxjs/toolkit";
import { playerVideoById } from "../actions";
export const PlayerVideo = createSlice({
  name: "Player",
  initialState: {
    Videos: {},
    LoadingToWatch: false,
    watching: false,
  },
  reducers: {
    setOffVideo: (state, action) => {
      state.watching = false;
    },
  },
  extraReducers: {
    [playerVideoById.pending]: (state) => {
      state.LoadingToWatch = false;
    },
    [playerVideoById.fulfilled]: (state, { payload }) => {
      state.Videos = payload;
      state.watching = true;
      state.LoadingToWatch = false;
    },
    [playerVideoById.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
  },
});
export const { setOffVideo } = PlayerVideo.actions;
export default PlayerVideo.reducer;
