import React, { useEffect, useState } from "react";
import {
  Tooltip,
  Divider,
  Button,
  Modal,
  Checkbox,
  Input,
  Row,
  Col,
  Select,
  InputNumber,
  message
} from "antd";
import { Option } from "antd/lib/mentions";
import axios from "axios";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Hooks from "./utils/hooks";
import { useAppSelector } from "../redux/hooks";
import { GetData } from "../APIcalls";
import { getUrlVideos ,getEntitiy} from "./store/mediasActions";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../userData/userDataActions";
import ytImg from "./images/youtube.png"
import twImg from "./images/twitter.png"
import fbImg from "./images/fb.png"
import LinkedInImg from "./images/in.png"
import { useMediaQuery } from "@mui/material";
import {ReactComponent as Arrow}  from "../assets/svgicons/arrow.svg"
import { useTranslation } from "react-i18next";

export function ExportVideoModal({ idVideo }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  useEffect(()=>{
    dispatch(getUserData());
  },[])
  const modules = useSelector(state => state.ReducerUserData.modules);
  const isSuperAdmin = useSelector(
    (state) => state.ReducerUserData.isSuperAdmin
  );
  const modalToOpen = useAppSelector((state) => state.MediaReducer.modalToOpen);
  const video = useAppSelector((state) => state.MediaReducer.urlVideo);
  const entities = useAppSelector((state)=>state.MediaReducer.entities)
  const [isReady,setIsReady] = useState(false)
  const tokenSkin = useAppSelector((state)=>state.MediaReducer.tokenList);

  const getUrlVideo = async (id) => {
    modalToOpen == "showModalExport" &&  await GetData("/video/video/url-export/" + id).then((res) => {
      if(res?.status === 200){
      dispatch(getUrlVideos(res?.data?.video));
      setIsReady(true)
     }else{
      message.error(`${t("video.errorUploadingUrl")}`);
      // setIsReady(false)
     }
    }).catch((err)=>{
      message.error(`${t("video.errorUploadingUrl")}`);
    })
  };
  const getEntityKeys = async()=>{
       await GetData("/param/by-key/entity").then((res) => {dispatch(getEntitiy(res?.data))
  }).catch((err) => console.log("error",err));
  }
  const { TextArea } = Input;
  const { handleCancel, visible, getExportYoutubeLink, defaultChecked,getSkinToken } = Hooks();
  const marginId = {
    position: "relative",
    marginBottom: "5px",
    marginTop: "20px"
  };
  const margin = {
    position: "relative",
    marginBottom: "5px"
  };
  const spanstyle = {
    position: "relative",
    marginLeft: "25px"
  };
  let filteredChecks = [
    {
      label: "autoplay",
      value: "a=1",
      permission: modules.media_video_options_embed_no_api,
      permissionLabel: "media_video_options_embed_no_api",
    },
    {
      label: "Send mail",
      value: "mail=1",
      permission: modules.media_video_send_mail_no_api,
      permissionLabel: "media_video_send_mail_no_api"

    },
    {
      label: "Facebook",
      value: "fb=1",
      permission: modules.media_video_social_network_embed_no_api,
      permissionLabel: "media_video_social_network_embed_no_api",

    },
    {
      label: "Twitter",
      value: "tw=1",
      permission: modules.media_video_social_network_embed_no_api,
      permissionLabel: "media_video_social_network_embed_no_api",



    }, {
      label: "Linkedin",
      value: "lk=1",
      permission: modules.media_video_social_network_embed_no_api,
      permissionLabel: "media_video_social_network_embed_no_api"

    },
  ]
  const embedLinks = [
    {
      label: "Simple",
      value: video?.link_embed_simple,
      permission: modules.media_video_embed_no_api_simple,
      htmllabel: "simple",
    }, {
      label: t("video.skin_interactivity_with_timeLine"),
      value: video?.link_embed_interactivity_with_timeline,
      permission: modules.media_video_embed_no_api_interactivity_with_timeline,
      htmllabel: "interactivity_with_timeLine",
    },{
      label:t("video.skin_interactivity_without_timeLine"),
      value:video?.link_embed_interactivity_without_timeline,
      permission:modules.media_video_embed_no_api_interactivity_without_timeline,
      htmllabel:"interactivity_without_timeLine",
    },{
      label:t("video.Navigation"),
      value:video?.link_embed_navigation,
      permission: modules.media_video_embed_no_api_navigation,
      htmllabel: "navigation",
    }
  ];
  const [urlPermalien, setPermalien] = useState();
  const [additionalParams, setadditionalParams] = useState("");
  const [isCDNchecked, setCDNchecked] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const sizeTablet = useMediaQuery('(max-width:950px)');
  const sizeMobile = useMediaQuery('(max-width:650px');
  const breakPoint = useMediaQuery('(max-width:750px)');
  const breakPoint2 = useMediaQuery('(max-width:575px');
  const [isHeight,setHeight] = useState('');
  const [isWidth,setWidth] = useState('')
  const size = sizeMobile ? 100 : (sizeTablet ? 120 : 150);
  const [isLoading, setIsLoading] = useState(false);
  const [selectToken,setSelectToken] = useState('')
  const [skinChecked,setSkinChecked] = useState('')
  const CheckboxGroup = Checkbox.Group;
  const copy = (value) => {
    navigator.clipboard.writeText(value);
  };
  useEffect(() => {
    getUrlVideo(idVideo)
    getEntityKeys()
    getSkinToken();
  }, [idVideo])

  const checkClientName = () => {
    if (window?.process?.env?.CLIENT_NAME) {
        const modifiedClientName = window.process.env.CLIENT_NAME.replace(/_/g, '-');

        return modifiedClientName.includes("groupe-vyv");
    }
    return false;
};

  const [entitySelected,setEntitySelected] = useState('')
  const onChangeEntity = (value) => {
    setEntitySelected(value)
  };
let entitiesArray = entities?.split(',').map((item, index) => {
  let obj = {};
  obj[index] = item;
  return obj;
});
  useEffect(() => {
    setPermalien(video?.link);
  }, [video]);
  function onChange(checkedValues) {
    setCDNchecked(false)
    if (checkedValues.length !== 0)
      setadditionalParams("?" + checkedValues[0] + checkedValues.slice(1).map(i => '&' + i).join(''));

    if (checkedValues.length === 0) {
      setadditionalParams("")
      setPermalien(video?.link);
    }
  }
  const handleCheckboxChange = (event) => {
    const value = event.target.value;

    if (event.target.checked) {
      setSelectedValues([...selectedValues, value]);
    } else {
      setSelectedValues(selectedValues.filter((v) => v !== value));
    }
  };
  const buildQueryString = () => {
    if (selectedValues.length === 0) {
      return "";
    }
    let queryString = "?";
    queryString += selectedValues[0];

    for (let i = 1; i < selectedValues.length; i++) {
      queryString += "&" + selectedValues[i];
    }

    return queryString;
  };

  const filteredChecksMapped = filteredChecks.map(
    (checkbox) =>
      (checkbox.permission || isSuperAdmin) && (

        <label has-permission={checkbox.permissionLabel}>
          <Checkbox
            value={checkbox.value}
            onChange={handleCheckboxChange}
            checked={selectedValues.includes(checkbox.value)}
          />
          <span style={{ marginLeft: "5px", marginRight: "5px" }} >{checkbox.label}</span>
        </label>

      )
  );
  function onChangecdn(event) {
    setCDNchecked(event.target.checked)
  }
  function onChangeSkin(event) {
    setSkinChecked(event.target.checked)
  }

  function stripSkinFromUrl(url) {
    const skinIndex = url.indexOf('&skin');
    if (skinIndex !== -1) {
      return url.substring(0, skinIndex);
    }
    return url;
  }

  const handleUrlPerma = (isSkin,link) => {
    const filteredURL = (skinChecked && selectToken) ? stripSkinFromUrl(link) :  link
    const url = new URL(link);
    const searchParams = url.searchParams;
    // Ensure entity parameter is set if checkClientName() and entitySelected are true
    if(isSkin){
      if (checkClientName() && entitySelected) {
        searchParams.set("entity", entitySelected);
      } else {
        searchParams.delete("entity");
      }
    
      // Set or delete skin parameter based on conditions
      if (skinChecked && selectToken) {
        searchParams.set("skin", selectToken);
      } else {
        searchParams.delete("skin");
      }
    
    }else{
      if (checkClientName() && entitySelected) {
        searchParams.set("entity", entitySelected);
      } else {
        searchParams.delete("entity");
      }
    }
    
    const newUrl = searchParams.size > 0 && skinChecked 
    ? `${url.origin}${url.pathname}?${searchParams.toString()}` 
    : (entitySelected 
        ? `${link}?entity=${entitySelected}` 
        : link);

    console.log("newURL",newUrl)
    return newUrl;
  };
  

  return (isReady ? (<Modal
    onOk={handleCancel}
    centered
    className="modal-edit-video"
    heigh={300}
    title={t("video.export_video")}
    open={modalToOpen == "showModalExport"}
    onCancel={handleCancel}
  >
    {filteredChecksMapped}
   
    {(modules.media_video_skin_player || isSuperAdmin) && <span has-permission="media_video_skin_player" > <Checkbox onChange={onChangeSkin}>Skin</Checkbox>
    {skinChecked &&
    <Select
    defaultValue={tokenSkin[0]}
    style={{
      width: 120,
    }}
    onChange={(e)=>{
      setSelectToken(e)
    }}
    options={tokenSkin}
  />
  
  }
    </span>}
  
    {(modules.media_video_social_network_embed_no_api || isSuperAdmin) && <span has-permission="media_video_social_network_embed_no_api" > <Checkbox onChange={onChangecdn}>Cdn Web</Checkbox></span>}
    <Row>
      <Col xs={24} sm={21}>
        <div style={margin}></div>
        <div>
        <Col style={marginId}>
            {checkClientName() && (
            <Select style={{ width: "100%" }} onChange={onChangeEntity}
            placeholder={t('video.Entity')}
            >
                {entitiesArray && entitiesArray.map((entity, index) => (
                    <Option key={index} value={Object.values(entity)[0]}>
                        {Object.values(entity)[0]}
                    </Option>
                ))}
            </Select>
              )}
            </Col>
        </div>
        <div style={marginId}>
          {(modules.media_video_uid_embed_no_api || isSuperAdmin) && <Row has-permission="media_video_uid_embed_no_api" gutter={[0, 3]}>
            <Col xs={24} sm={6}> <b>UID</b> </Col>
            <Col xs={20} sm={16}>   <span style={spanstyle}> {video.id}</span> </Col>
            <Tooltip title="Copier UID">
              <CopyToClipboard
                text={video?.id}
              >
                {
                  <Button
                    style={{ marginLeft: "10px" }}
                    icon={<CopyOutlined />}
                  />
                }
              </CopyToClipboard>
            </Tooltip>
          </Row>}
        </div>

        <div style={marginId}>
          {(modules.media_video_mp4_embed_no_api || isSuperAdmin) && <Row has-permission="media_video_mp4_embed_no_api" >
            <Divider />

            {video?.videos_mp4?.map((link, index) => <React.Fragment key={index} >
              <Col xs={24} sm={6}>
                <b>{index == 0 && 'MP4'} </b>
              </Col>
              <Col xs={20} sm={16}>
                <span style={spanstyle}> {link.src}</span>
              </Col>
              <Tooltip>
                <CopyToClipboard
                  text={link?.src}
                >
                  {
                    <Button
                      style={{ marginLeft: "10px" }}
                      icon={<CopyOutlined />}
                    />
                  }
                </CopyToClipboard>
              </Tooltip>
              <Divider /></React.Fragment>)}
          </Row>}
        </div>

        <div style={margin}>
          {(modules.media_video_progressif_download_embed_no_api || isSuperAdmin) && <Row has-permission="media_video_progressif_download_embed_no_api" gutter={[0, 30]}>
            <Col xs={24} sm={6}>
              <b>{t("video.download_progressiv_vid")}</b></Col>
            <Col xs={20} sm={16}>  <span style={spanstyle}>
              {checkClientName() && entitySelected 
              ? `${video?.link_progressive_download}?entity=${entitySelected}`
              : video?.link_progressive_download}
            </span> </Col>
            <Tooltip title={t("video.cp_download")}>
              <CopyToClipboard
                text={checkClientName()&& entitySelected 
                  ? `${video?.link_progressive_download}?entity=${entitySelected}`
                  : video?.link_progressive_download}
              >
                {
                  <Button
                    style={{ marginLeft: "10px" }}
                    icon={<CopyOutlined />}
                    onClick={()=>{
                      let downLoad = checkClientName() && entitySelected 
                      ? `${video?.link_progressive_download}?entity=${entitySelected}`
                      : video?.link_progressive_download;
                      }
                    }
                  />
                }
              </CopyToClipboard>
            </Tooltip>
          </Row>}
        </div>
        {!isCDNchecked && <><div style={margin}>
          {(modules.media_video_permalien_embed_no_api || isSuperAdmin) && <Row has-permission="media_video_permalien_embed_no_api" gutter={[0, 30]}>
            <Divider />

            <Col xs={24} sm={6}>
              <b>{t("media_center.export_media.permalink")}</b> </Col>
            <Col xs={20} sm={16}> <span style={spanstyle}>
            {urlPermalien && handleUrlPerma(true,urlPermalien + buildQueryString())}</span> </Col>
            <Tooltip title="Copier Permalien">
              <CopyToClipboard
                text={urlPermalien && handleUrlPerma(true,urlPermalien + buildQueryString())}
              >
                {
                  <Button
                    style={{ marginLeft: "10px" }}
                    icon={<CopyOutlined />}
                    onClick={()=>{
                      let permalienURL =handleUrlPerma(true,urlPermalien + buildQueryString());

                    }}
                  />
                }
              </CopyToClipboard>
            </Tooltip>
          </Row>}
        </div>
          {(modules.media_video_embed_no_api || isSuperAdmin) && <Row has-permission={"media_video_embed_no_api"} >
            <Divider />

            {embedLinks?.map((embedlink, index) => {
              return (embedlink.permission || isSuperAdmin) === true ? <React.Fragment key={index}><Col xs={24} sm={2}> <b>{index == 0 && 'Embed'} </b> </Col><Col xs={24} sm={4}> <b>{embedlink.label} </b> </Col><Col has-permission={index > 0 ? embedlink.htmllabel : null} xs={20} sm={16}>   <span style={spanstyle}>
                {embedlink.value && handleUrlPerma(true,embedlink.value)}</span>
              </Col>
                <Col style={{ marginBottom: '15px' }} span={1}>
                  <Tooltip >
                    <CopyToClipboard
                  
                      text={embedlink.value &&  handleUrlPerma(true,embedlink.value)}
                    >
                      {
                        <Button
                          style={{ marginLeft: "10px" }}
                          icon={<CopyOutlined />}
                          onClick={()=>{
                          
                            handleUrlPerma(true,embedlink.value)
                          }}
                        />
                      }
                    </CopyToClipboard>
                  </Tooltip>
                </Col>
              </React.Fragment> : null
            })}
          </Row>}
          <div>

          </div>

          <div>
            {(modules.media_video_embed_code_no_api || isSuperAdmin) && <Row has-permission="media_video_embed_code_no_api" gutter={[0, 30]}>
              <Col xs={24} sm={4}>
                <b>{t("Podcast.Code_Embed")}</b></Col>
              <Col xs={20} sm={16}>  <TextArea
                style={{ width:breakPoint ? "100%" : "calc(100% - 42px)" }}
                rows={4}
                value={`<iframe style="width:${isWidth ? isWidth : "100%"};height:${isHeight ? isHeight : "100%"}"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            src="${checkClientName() && entitySelected ? `${urlPermalien && handleUrlPerma(false,urlPermalien + buildQueryString())}` : urlPermalien + buildQueryString()}" ` +
            `allowfullscreen frameborder="0"></iframe>
            `}
          />
          <p>{t("video.video_size")}</p>
          </Col>
          <Col align="middle" xs={4} sm={4}>
          <Tooltip title="Copier Code Embed">
          <CopyToClipboard
                  text={`<iframe style="width:${isWidth ? isWidth : "100%"};height:${isHeight ? isHeight : "100%"}"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        src="${checkClientName() && entitySelected ? `${urlPermalien && handleUrlPerma(false,urlPermalien + buildQueryString())}` : urlPermalien + buildQueryString()}" ` +
                        `allowfullscreen frameborder="0"></iframe>
                  `}
                >
                  {
                    <Button
                    style={{  marginLeft: sizeMobile === "10px"
                    ? "10px"
                    : breakPoint2
                      ? "0px"
                      : breakPoint
                        ? "3rem"
                        : "3.5rem" }}
                      icon={<CopyOutlined />}
                      onClick={()=>{
                        let embed = `<iframe style="width:${isWidth ? isWidth : "100%"};height:${isHeight ? isHeight : "100%"}"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        src="${checkClientName() && entitySelected ? `${urlPermalien && handleUrlPerma(false,urlPermalien + buildQueryString())}` : urlPermalien + buildQueryString()}" ` +
                        `allowfullscreen frameborder="0"></iframe>
                        `
                      }}
                    />
                  }
             
                </CopyToClipboard>
               
              </Tooltip>
              <Row
                style={marginId}
                align="end"
                justify="start"
          
              >
                <Row xs={24} sm={19}  align="middle"> <Arrow style={{transform:"rotate(90deg)"}}/><InputNumber  min={1} max={1000000}   style={{ width: breakPoint ? "65px":'90px' }} onChange={(e)=>{setWidth(e)}} 
                placeholder="100"
                type="number"
                   prefix={breakPoint ? null : <strong>W</strong>}
                  />
                  </Row>
                  <Row xs={24} sm={19}  align="middle"> <Arrow/><InputNumber  min={1} max={1000000}  style={{ width: breakPoint ? "65px":'90px' }} onChange={(e)=>{setHeight(e)}} type="number"
                 placeholder="100"
                 prefix={breakPoint ? null : <strong>H</strong>}
                  /></Row>
                  </Row>
                  </Col>
            </Row>}
            <Divider />
          </div> </>}
      </Col>
      {(modules.media_video_social_network_embed_no_api || isSuperAdmin) && <Col has-permission="media_video_social_network_embed_no_api" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '15px 0' }} className="modal-edit-icons" span={2} offset={1}>
        <Tooltip placement="top" title={"Export Youtube"}>
          <img width={40} alt="youtube" style={{ cursor: 'pointer' }} src={ytImg} onClick={() => { getExportYoutubeLink(idVideo) }}></img>
        </Tooltip>
        <Tooltip placement="top" title={"Export Twitter"}>
          <img width={40} alt="twitter" style={{ cursor: 'pointer' }} src={twImg}
            onClick={() => { window.open("https://twitter.com/intent/tweet?text=" + video.title + "&url=" + video.link_embed) }}></img>
        </Tooltip>
        <Tooltip placement="top" title={"Export Facebook"}>
          <img width={40} alt="fb" style={{ cursor: 'pointer' }} src={fbImg}
            onClick={() => { window.open('https://www.facebook.com/sharer/sharer.php?u=' + video.link_to_player) }}></img>
        </Tooltip>
        <Tooltip placement="top" title={"Export LinkedIn"}>
          <img width={40} alt="lk" style={{ cursor: 'pointer' }} src={LinkedInImg}
            onClick={() => { window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + video.link_to_player) }}></img>
        </Tooltip>
      </Col>}
    </Row>
  </Modal>
  ): null

  )
}
