import "../../index.css";
import { Col, Button, Spin } from "antd";
import {
	ArrowLeftOutlined,
	CheckCircleOutlined,
	CloseOutlined,
	EditOutlined,
} from "@ant-design/icons";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import "../addMedia.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import MovieIcon from "@mui/icons-material/Movie";
import { useSelector } from "react-redux";

const CommonHeader = ({ id, filename, hasIcons, cancelBtnLocation, SubmitAction }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const loadingBtn = useAppSelector((state) => state.addMediaReducer.loadingBtn);

	const modules = useAppSelector((state) => state.ReducerUserData.modules);
	const isSuperAdmin = useSelector((state) => state.ReducerUserData.isSuperAdmin);
	const emitEventToParent = (event) => {
		window.parent.postMessage({ info: "edit_video", value: event }, "*");
	};
	const icons = [
		{
			icon: <EditOutlined />,
			path: `/ws-media/video/${filename}/${id}/edit`,
			title: t("video_edit.Edit"),
			permission: modules.media_video_post_edit,
			permissionName: "media_video_post_edit",
			event: "edit",
		},
		{
			icon: <ClosedCaptionIcon />,
			path: `/ws-media/video/${filename}/${id}/edit/sous-titrages`,
			title: t("video_edit.Sub_Titles"),
			permission: modules.media_video_caption_post_generate_caption,
			permissionName: "media_video_caption_post_generate_caption",
			event: "caption",
		},
		{
			icon: <MovieIcon />,
			path: `/ws-media/video/${filename}/${id}/edit/rich-media`,
			title: t("video_edit.Rich_Media"),
			permission: modules.rich_media_video_preview_link_by_title,
			permissionName: "rich_media_video_preview_link_by_title",
			event: "rich_media",
		},
		{
			icon: <ContentCutIcon />,
			path: `/ws-media/video/${filename}/${id}/edit/montage`,
			title: "Montage",
			permission: modules.media_video_get_date_last_montage,
			permissionName: "media_video_get_date_last_montage",
			event: "montage",
		},
	];
	return (
		<div className={"add_div add_div_edit"}>
			<Col
				span={18}
				xs={12}
				style={{
					display: "flex",
					justifyContent: "flex-start",
				}}
			>
				<ArrowLeftOutlined
					style={{
						display: "flex",
						alignItems: "center",
						fontSize: "medium",
						cursor: "medium",
					}}
					onClick={() => {
						history.push("/ws-media");
						window.parent.postMessage({ info: "diffusion", value: "ongoing" }, "*");
					}}
				/>
				{hasIcons ? (
					icons.map((i) => {
						return (
							(i.permission || isSuperAdmin) && (
								<NavLink
									key={i.title}
									onClick={() => emitEventToParent(i.event)}
									exact
									className="edit-buttons"
									to={i.path}
								>
									{i.icon}
									<p has-permission={i.permissionName}>{i.title}</p>
								</NavLink>
							)
						);
					})
				) : (
					<span
						style={{
							fontSize: "16px",
							fontFamily: "Arial, Helvetica, sans-serif",
						}}
					>
						<span>{t("video.add")}</span>
					</span>
				)}
			</Col>
			{!location.pathname.match(/(sous-titrages|rich-media|montage)$/gi) && (
				<div className={"div_btn_add_cancel"}>
					<Button
						onClick={() => history.push(cancelBtnLocation)}
						className={"btnCancel"}
						icon={<CloseOutlined />}
						htmlType="button"
						name={"cancel"}
					>
						<span className={"spn_cancel"}>{t("Profile.cancel")}</span>
					</Button>
					<Spin spinning={loadingBtn}>
						<Button
							onClick={SubmitAction}
							className={"btnSubmit"}
							icon={<CheckCircleOutlined />}
							type="primary"
							htmlType="submit"
							name={"submit"}
						>
							<span className={"spn_add"}>{t("Profile.register")}</span>
						</Button>
					</Spin>
				</div>
			)}
		</div>
	);
};

export default CommonHeader;
