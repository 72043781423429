import { createSlice } from "@reduxjs/toolkit";
import { getUserList } from "../actions";
import { getrmbox } from "../actions";
import {
  getList,
  getListFiltered,
  addPlayList,
  editPlaylist,
  getPlayListToEdit,
  getPlayListToView,
  getPlayListToAdd,getThemes
} from "../actions";
export const AddEditSlice = createSlice({
  name: "AddEdit",
  initialState: {
    PlayListData: {},
    DetailedPlayList: {},
    visible: false,
    AddingPlayList: false,
    EditingPlayList: false,
    action: false,
    Themes: {},
    Rmbox: {},
    RmboxSelected: [],
    ListVideos: [],
    totalListNumber: 0,
    isLoading: false,
  },
  reducers: {
    PlayListAddEdit: (state, action) => {
      state.PlayListData = action.payload.Data;
      state[action.payload.addedit] = true;
    },
    OnCancelAddEdit: (state, action) => {
      state.PlayListData = {};
      state.AddingPlayList = false;
      state.EditingPlayList = false;
    },
    showModal: (state, action) => {
      state.DetailedPlayList = action.payload;
      state.visible = true;
    },
    CancelView: (state, action) => {
      state.DetailedPlayList = action.payload;
      state.visible = false;
    },
    SelectRmbox: (state, action) => {
      state.RmboxSelected = action.payload;
    },
    ableToMakeAction: (state, action) => {
      state.action = action.payload;
    },
    liveChangeForAddEdit: (state, action) => {
      state.PlayListData.playlist[action.payload.name] = action.payload.value;
    },
    mediaListMovement: (state, action) => {
      state.PlayListData.playlist.playlist_media.push(action.payload);
    },
    graphGetVideosList : (state, action) =>{
      state.ListVideos = action?.payload?.MediaList;
    },
    graphGetVideosListFiltered : (state, action) =>{
      state.ListVideos = action?.payload?.MediaList;
    }
  },
  extraReducers: {
    [getThemes.fulfilled]: (state, { payload }) => {
      state.Themes = payload;
    },
    [getThemes.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
    [getrmbox.fulfilled]: (state, { payload }) => {
      state.Rmbox = payload;
    },
    [getrmbox.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
 
    [addPlayList.pending]: (state) => {},
    [addPlayList.fulfilled]: (state, { payload }) => {
      state.PlayListData = {};
      state.AddingPlayList = false;
    },
    [addPlayList.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
    [editPlaylist.pending]: (state) => {},
    [editPlaylist.fulfilled]: (state, { payload }) => {
      state.PlayListData = {};
      state.EditingPlayList = false;
    },
    [editPlaylist.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
    [getPlayListToAdd.pending]: (state) => {},
    [getPlayListToAdd.fulfilled]: (state, { payload }) => {
      state.PlayListData = {
        playlist: {
          id: payload.playlist.id,
          title: payload.playlist.title,
          loopp: 0,
          maxDuration: 0,
          autoSort: 0,
          created_at: payload.playlist.created_at,
          updated_at: null,
          playlist_media: payload.playlist.playlist_media,
          onAir: null,
          startDate: payload.playlist.startDate,
          isLive: false,
          rmboxes: payload.playlist.rmboxes,
        },
        default_theme_playlist: null,
        code: payload.code,
      };
      state.AddingPlayList = true;
    },
    [getPlayListToAdd.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
    [getPlayListToView.pending]: (state) => {},
    [getPlayListToView.fulfilled]: (state, { payload }) => {
      state.DetailedPlayList = {
        playlist: {
          id: payload.playlist.id,
          title: payload.playlist.title,
          maxDuration: payload.playlist.maxDuration,
          loopp: payload.playlist.loopp ? 1 : 0,
          autoSort: payload.playlist.autoSort ? 1 : 0,
          created_at: payload.playlist.created_at,
          updated_at: null,
          playlist_media: payload.playlist.playlist_media,
          startDate: payload.playlist.startDate,
          isLive: false,
          rmboxes: payload.playlist.rmboxes,
        },
        url_playlist_permalien: payload.url_playlist_permalien,
        url_playlist_embed: payload.url_playlist_embed,
        default_theme_playlist: null,
        code: payload.code,
      };
      state.visible = true;
    },
    [getPlayListToView.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
    [getPlayListToEdit.pending]: (state) => {},
    [getPlayListToEdit.fulfilled]: (state, { payload }) => {
      state.PlayListData = {
        playlist: {
          id: payload.playlist.id,
          title: payload.playlist.title,
          maxDuration: payload.playlist.maxDuration,
          loopp: payload.playlist.loopp ? 1 : 0,
          autoSort: payload.playlist.autoSort ? 1 : 0,
          created_at: payload.playlist.created_at,
          updated_at: null,
          playlist_media: payload.playlist.playlist_media,
          startDate: payload.playlist.startDate,
          isLive: false,
          rmboxes: payload.playlist.rmboxes,
        },
        url_playlist_permalien: payload.playlist.url_playlist_permalien,
        url_playlist_embed: payload.playlist.url_playlist_embed,
        default_theme_playlist: null,
        code: payload.code,
      };
      state.EditingPlayList = true;
    },
    [getPlayListToEdit.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
  },
});
export const {
  PlayListAddEdit,
  OnCancelAddEdit,
  showModal,
  CancelView,
  SelectRmbox,
  liveChangeForAddEdit,
  mediaListMovement,
  ableToMakeAction,
  graphGetVideosList,
  graphGetVideosListFiltered
} = AddEditSlice.actions;
export default AddEditSlice.reducer;
