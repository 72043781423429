import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux"
import "../../../index.css"
import {getExtraPodcastParameters} from "../../utils/action"
import {useDispatch} from "react-redux"
const EditToggle = () => {
    const dispatch = useDispatch();
    const thumb = useSelector((state) => state.ReducerGetPodcastData.secondThumbData);
    const podItems = useSelector((state) => state.ReducerGetPodcastData.podcastItems_byId);
    let titleItems = podItems ?. map(podcast => podcast.title).join("");
    let descrItems = podItems ?. map(podcast => podcast.description).join("");
    let thumbnail = podItems ?. map(podcast => podcast.vignette).join("");
  //  const audioForPodcastItem = useSelector(state => state.ReducerGetPodcastData.podcastItems.audio);
  //  const videoForPodcastItem = useSelector(state => state.ReducerGetPodcastData.podcastItems.video)
    const audio = podItems ?. map(podcast => podcast.audio).join("");
    const video = podItems ?. map(podcast => podcast.video).join("");
    const id = podItems ?. map(podcast => podcast.id).join("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [vignette, setVignette] = useState("");
    useEffect(() => {
        setTitle(titleItems);
        setDescription(descrItems);
        setVignette(thumbnail);
    }, [podItems]);

    useEffect(() => {
        if (thumb) {
            setVignette(thumb)
        }
    }, [thumb])
    useEffect(() => {

        dispatch(getExtraPodcastParameters(title, description, vignette, audio, audio, id))
    }, [title, description, vignette])
    const {t} = useTranslation();
    const [toggle, setToggle] = useState(false);
    const setToggle_btn = () => setToggle(!toggle);
    // return <div className="tg_cntr" >
    //     <div onClick={setToggle_btn} className="tglHeader">
    //         <div className="toggle_btn_container" >
    //             <p>{t("Podcast.bread-crumb.Podcast")} 1 : {audioForPodcastItem} </p>
    //             {toggle ? <RemoveIcon /> : <AddIcon />}
    //         </div>
    //     </div>
    //     {
    //         toggle ?
    //             <div style={{ marginBottom:'20px',border:'1px solid transparent',borderRadius:'4px',boxShadow:'0 1px 1px rgb(0 0 0 / 5%)'}}
    //             >
    //                 <div className="select_upload" >
    //                     <img className="toggle_image" src={vignette ? vignette : "/default.png"} alt="" />
    //                     <SecondThumbUpload content={t("Podcast.add-edit.CHOOSE A THUMB")} />
    //                 </div>
    //                 <div className="upload_file_inputs_toggle" >
    //                     <Box
    //                         sx={{
    //                             width: 900,
    //                             maxWidth: "100%",
    //                             paddingBottom: "20px",
    //                             marginTop:"15px"
    //                         }}
    //                     >
    //                         <TextField name={"title"} value={title}
    //                             fullWidth
    //                             defaultValue={titleItems}
    //                             InputLabelProps={{
    //                                 style: {
    //                                     fontSize: "medium",
    //                                     color:"black"
    //                                 }
    //                             }}
    //                             onChange={(e) => setTitle(e.target.value)}
    //                              label={t("Podcast.Title")} id="fullWidth" />
    //                     </Box>
    //                     <Box
    //                         sx={{
    //                             width: 900,
    //                             maxWidth: "100%",
    //                             paddingBottom: "30px",
    //                         }}
    //                     >
    //                         <TextField multiline={true}
    //                             defaultValue={descrItems}
    //                             name={"description"}
    //                             InputLabelProps={{
    //                                 style: {
    //                                     fontSize: "medium",
    //                                     color:"black"
    //                                 }
    //                             }}
    //                             value={description}
    //                             fullWidth
    //                             onChange={(e) => setDescription(e.target.value)}
    //                             rows={6}  label={t("Podcast.Description")} id="fullWidth" />
    //                     </Box>
    //                 </div>
    //             </div>
    //             : null
    //     }

    // </div>
}

export default EditToggle;
