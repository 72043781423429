import { createSlice } from "@reduxjs/toolkit";
import { getModules } from "../actions";

export const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    UserData: {},
    modules: [],
    allModules:[],
    GlobalLoading: true,
    Authorized: false,
    isLoading: false,
  },
  extraReducers: {
    [getModules.pending]: (state) => {
      state.GlobalLoading = true;
    },
    [getModules.fulfilled]: (state, { payload }) => {
      state.Authorized = true;
      state.GlobalLoading = false;
      state.modules = payload.userData.modules.playlist_module;
      state.allModules = payload.userData.modules;
      state.UserData = payload.userData.user;
    },
    [getModules.rejected]: (state, { payload }) => {
      state.Authorized = false;
      state.GlobalLoading = false;
    },
  },
});
export default DashboardSlice.reducer;
