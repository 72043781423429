import {CaretRightOutlined, DownloadOutlined, DeleteOutlined, EllipsisOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {Dropdown, Menu} from "antd";
import {useDispatch} from "react-redux";
import {setUrlFrame} from "../../ExportMedias/utils/actions";
import {useTranslation} from "react-i18next";
import {AddHooks} from "../utils/hooks";
import {setVisibleDelete, setvideoname} from "../store/addMediaActions";
import {useSelector} from "react-redux"
function useActionMenu({record}) {
    const history = useHistory();
    const {previewVideo} = AddHooks();
    const dispatch = useDispatch();
    const modules = useSelector(state => state.ReducerUserData.modules);
    const isSuperAdmin = useSelector(
        (state) => state.ReducerUserData.isSuperAdmin
      );
    // handle Click Menu
    const handleClickDropdowMenu = () => {

        dispatch(setUrlFrame(record));
    };
    const {t} = useTranslation();
    const actionMenu = (
        <Menu className="menu">
            <Menu.Item onClick={
                () => handleInfos(record)
            }>
               { (modules?.media_video_preview_no_api || isSuperAdmin ) && <span has-permission="media_video_preview_no_api" >
                <CaretRightOutlined/> {
                t("video.visualiser")
            }</span>} </Menu.Item>
            <Menu.Item onClick={
                () => {
                    history.push("/ws-media/video/" + record.row?.fileTitle + "/" + record.id + "/add");
                }
            }>
               { (modules.media_video_post_add || isSuperAdmin ) && <span has-permission="media_video_post_add"  >
                <DownloadOutlined/> {
                t("media_center.table_actions.add")
            }</span>} </Menu.Item>

            <Menu.Item onClick={
                () => {
                    dispatch(setVisibleDelete(true));
                    dispatch(setvideoname(record));
                }
            }>
                { (modules.media_video_delete_video || isSuperAdmin ) && <span has-permission="media_video_delete_video" >
                <DeleteOutlined/> {
                t("media_center.table_actions.delete")
            }</span>} </Menu.Item>

        </Menu>
    );
    const handleInfos = (row) => {
        previewVideo(row.id);
    };
    const actionColumnView = (
        <div className="action">
            <span>
                <Dropdown overlay={actionMenu}
                    trigger={
                        ["click"]
                    }
                    onClick={
                        () => handleClickDropdowMenu()
                }>
                    <EllipsisOutlined style={
                        {fontSize: "22px"}
                    }/>
                </Dropdown>
            </span>
        </div>
    );
    return [actionColumnView];
}
export default useActionMenu;
