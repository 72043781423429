import {SET_URL_FRAME} from './types'

export function setUrlFrame(e){

    return{
        type: SET_URL_FRAME,
        payload:e,
    }

}
