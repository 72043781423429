import { PostData, GetData, DeleteData, UpdateData } from "../../APIcalls";

export const getUserData = () => GetData(`/core/rights/get-rights-by-module`);
export const getDirectsPlanned = (id) =>
  GetData(
    `/dataTable/lives/endless?draw=1&length=5&start=0&order%5B0%5D%5Bdir%5D=desc&order%5B0%5D%5Bcolumn%5D=0&columns%5B0%5D%5Borderable%5D=true&idUser=${id}`
  );

// for stat
export const getVideosNumber = () => GetData(`/video/videos/nbvideos`);
export const getAllPlayLists = (id) =>
  GetData(
    `/dataTable/playlists?columns[0][orderable]=true&draw=1&length=5&order[0][column]=0&order[0][dir]=desc&start=0&idUser=${id}`
  );
export const getAllPodcasts = (id) =>
  GetData(
    `/dataTable/podcast?columns%5B0%5D%5Borderable%5D=true&draw=1&length=5&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=desc&start=0`
  );
export const getAllVideos = (id) => GetData(`/video/videos/latest`);
