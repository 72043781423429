import {useEffect} from 'react';
import {
    Row,
    Col,
    Spin,
    Button,
    Form
} from 'antd'
import {useAppSelector} from '../../redux/hooks';
import {AddHooks} from '../utils/hooks';
import {useTranslation} from "react-i18next";
import {useParams, useHistory} from 'react-router-dom';
import {setAddMediaActions} from '../store/addMediaActions';
import {useDispatch} from 'react-redux';
import './changeVideoSource.css'
const ChangeVideoSource = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const {id, filename} = useParams()
    const link = useAppSelector((state) => state.addMediaReducer.linkVideo);
    const values = useAppSelector((state) => state.addMediaReducer.AddMediaConstraintsData)
    const thumbList = useAppSelector(state => state.addMediaReducer.thumbs.thumbnails)
    const {getVideoLink, getThumbs} = AddHooks()
    useEffect(() => {
        getVideoLink(id);
        getThumbs(filename, 1)
    }, [])
    // ------- functions --------
    return (
        <>
            <div className='change-video-source'>
                <div className='video-source'>
                    <Spin spinning={
                        link ? false : true
                    }>
                        <Form.Item name="logo">
                            <iframe title='iframevideo' id="myiFrame" className='iframevideo'
                                src={
                                    "https:" + link ? link : ""
                                }/>
                        </Form.Item>
                    </Spin>
                </div>
                <div className='single-vignette'>
                    <Spin spinning={
                        thumbList ?. length <= 0
                    }>
                        <img alt='' className='vignette'
                            src={
                                values ?. logo ? window.process.env.BUCKET_URL_THUMBNAIL + values.logo : thumbList && window.process.env.BUCKET_URL_THUMBNAIL + thumbList[0]
                        }></img>
                    </Spin>
                </div>
                <hr className='hr-source'/>
                <Row className='div-vignette'>
                    {
                    thumbList ?. length ? thumbList ?. map((image, index) => {
                        return (
                            <Col key={index}>
                                <div className="container"
                                    onClick={
                                        () => dispatch(setAddMediaActions({addMediaConstraintsDataNameChange: "logo", addMediaConstraintsDataValueChange: image}))
                                }>
                                    <img className="cardform" alt="card"
                                        src={
                                            window.process.env.BUCKET_URL_THUMBNAIL + image
                                    }></img>
                                    <div className="overlay">
                                        <div className="text">
                                            {
                                            t("video.Definir_comme_miniature")
                                        }</div>
                                    </div>
                                </div>
                            </Col>
                        );
                    }) : <Spin spinning={true}>
                        <Row> {
                            [
                                0,
                                1,
                                2,
                                3,
                                4
                            ] ?. map((node, index) => {
                                return (
                                    <Col key={index}>
                                        <div className="container">
                                            <img alt='' className="cardform"
                                                src={node}></img>
                                            <div className="overlay">
                                                <div className="text">
                                                    {
                                                    t("video.Definir_comme_miniature")
                                                } </div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                        } </Row>
                    </Spin>
                } </Row>
                <div className='buttons'>
                    <Button danger>
                        {
                        t("cancel")
                    }</Button>
                    <Button type="primary">
                        {
                        t("validate")
                    }</Button>
                </div>
            </div>
        </>
    )
}

export default ChangeVideoSource
