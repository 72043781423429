import { useMutation } from "@apollo/client";
import {
	GET_ALL_PODCAST,
	UPDATE_PODCAST,
	ADD_PODCAST,
	DELETE_PODCAST,
	GET_PODCAST_INFO,
	MULTI_DELETE,
	ARCHIVE_PODCASTS,
	UNARCHIVE_PODCASTS,
	URL_EXPORT_PODCAST,
} from "./GraphQl";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TYPES } from "../utils/types";
import {
	GetExportLinks,
	GetPodcastsData,
	PodcastCondition,
	PodcastConditionAdd,
	setPaginationProps,
} from "../utils/action";
import { message } from "antd";

export const PodcastGraphQlFetchData = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [GETPODCASTLIST] = useMutation(GET_ALL_PODCAST, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data: any) => {
			let idArr: any[] = [];
			data.podcasts?.edges?.map((podcast: any) => {
				if (podcast.node.IsReady === false) {
					idArr.push(podcast.node.id);
				}
			});
			dispatch(GetPodcastsData(data?.podcasts?.edges));
			dispatch(
				setPaginationProps({
					PaginationPropsNameChange: "totalCounts",
					PaginationPropsValueChange: data?.podcasts?.totalCount,
				})
			);
			dispatch({
				type: TYPES.GET_PODCAST_RECORD,
				payload: data?.podcasts?.edges?.node?.IsArchived,
			});
			dispatch({ type: TYPES.ON_SEND_PODCAST_LIST_ID, payload: idArr });
			dispatch({ type: TYPES.ON_LOADING_SCREEN, payload: false });
		},
		onError: (error: any) => {
			dispatch({ type: TYPES.ON_LOADING_SCREEN, payload: false });
			dispatch({ type: TYPES.GET_PODCAST_DATA_FAILED, payload: true });
			message.error({ content: t("Podcast.echec-operation"), duration: 2 });
		},
	});

	const [UPDATEPODCAST] = useMutation(UPDATE_PODCAST, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data: any) => {
			try {
				dispatch(
					PodcastCondition({
						PodcastConditionsName: "editPodcast",
						PodcastConditionsValue: false,
					})
				);
				dispatch({ type: TYPES.SET_EDIT_PODCAST, payload: true });
				message.success({
					content: t("Podcast.edit-operation"),
					duration: 2,
				});
			} catch (error) {
				console.error(error);
				message.error({ content: t("Podcast.echec-operation"), duration: 2 });
			}
		},
		onError: (error: any) => {
			dispatch({ type: TYPES.SET_EDIT_PODCAST, payload: false });
			message.error({ content: t("Podcast.echec-operation"), duration: 2 });
		},
	});

	const [ADDPODCAST] = useMutation(ADD_PODCAST, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data: any) => {
			try {
				dispatch(
					PodcastCondition({
						PodcastConditionsName: "addPodcast",
						PodcastConditionsValue: false,
					})
				);
				dispatch({ type: TYPES.SET_ADDED_PODCAST, payload: true });
				message.success({
					content: t("Podcast.add-operation"),
					duration: 2,
				});
			} catch (error) {
				console.error(error);
				message.error({ content: t("Podcast.echec-operation"), duration: 2 });
			}
		},
		onError: (error: any) => {
			dispatch({ type: TYPES.SET_ADDED_PODCAST, payload: false });
			message.error({ content: t("Podcast.echec-operation"), duration: 2 });
		},
	});

	const [DELETEPODCAST] = useMutation(DELETE_PODCAST, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data: any) => {
			dispatch({ type: TYPES.done_Delete, payload: true });
			message.success({ content: t("Podcast.delete-operation"), duration: 2 });
		},
		onError: (error: any) => {
			message.error({ content: t("Podcast.echec-operation"), duration: 2 });
		},
	});

	const [GETPODCASTINFO] = useMutation(GET_PODCAST_INFO, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data: any) => {
			dispatch(PodcastConditionAdd(data?.podcasts?.edges[0]?.node));
		},
		onError: (error: any) => {
			message.error({ content: t("Podcast.echec-operation"), duration: 2 });
		},
	});

	const [MULTIPODCAST] = useMutation(MULTI_DELETE, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data: any) => {
			dispatch({ type: TYPES.is_Deleted, payload: true });
			dispatch({ type: TYPES.done_Delete, payload: true });
			message.success({ content: t("Podcast.delete-operation"), duration: 2 });
		},
		onError: (error: any) => {
			message.error({ content: t("Podcast.echec-operation"), duration: 2 });
		},
	});

	const [ARCHIVEPODCAST] = useMutation(ARCHIVE_PODCASTS, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data: any) => {
			dispatch({ type: TYPES.single_podcast_delete, payload: true });
			message.success({ content: t("Podcast.archive-operation"), duration: 2 });
		},
		onError: (error: any) => {
			message.error({ content: t("Podcast.echec-operation"), duration: 2 });
		},
	});

	const [UNARCHIVEPODCASTS] = useMutation(UNARCHIVE_PODCASTS, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data: any) => {
			dispatch({ type: TYPES.single_podcast_delete, payload: true });
			message.success({ content: t("Podcast.restore-operation"), duration: 2 });
		},
		onError: (error: any) => {
			message.error({ content: t("Podcast.echec-operation"), duration: 2 });
		},
	});

	const [GETLINKSEXPORTS] = useMutation(URL_EXPORT_PODCAST, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data: any) => {
			dispatch(GetExportLinks(data?.podcasts?.edges[0]?.node));
			dispatch({ type: TYPES.ON_LOAD_PREVIEW, payload: false });
			dispatch({ type: TYPES.ON_SUCCES_RSS, payload: false });
		},
		onError: (error: any) => {
			message.error({ content: t("Podcast.echec-operation"), duration: 2 });
		},
	});

	return {
		GETPODCASTLIST,
		UPDATEPODCAST,
		ADDPODCAST,
		DELETEPODCAST,
		GETPODCASTINFO,
		ARCHIVEPODCAST,
		MULTIPODCAST,
		UNARCHIVEPODCASTS,
		GETLINKSEXPORTS,
	};
};
