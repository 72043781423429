export default class Files {
    static token = null 
    static videoId = null 
    static setToken = (idparam) => {
        this.token = JSON.parse(idparam).token
    }
    static setVideoId = (idparam) => {
        this.videoId = JSON.parse(idparam).videoId
    }
    static getToken = () => {
        return this.token
    }
    static getVideoId = () => {
        return this.videoId
    }
}
