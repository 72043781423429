import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { CopyToClipboard } from "react-copy-to-clipboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LinkIcon from "@mui/icons-material/Link";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import {
  Set_Id,
  GetDataById,
  cleanDataById,
  cleanExportLinks,
  loadPreview,
  setLoadRss,
  GetPlayerByID
} from "../utils/action";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useHistory } from "react-router-dom";
import "../../index.css";
import Tooltip from "@mui/material/Tooltip";
import EmbedCodeTemplate from "../utils/EmbedCodeTemplate";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { downloadPodcast } from "../utils/PodcastAPI_CALLS";
import { ModalInputs } from "@empreinte/components.modal-inputs";
import { ClipLoader } from "@empreinte/components.clip-loader";
import { ModalConfirm } from "@empreinte/components.modal-confirm";
import Chip from "@mui/material/Chip";
import Hooks from "../utils/hooks";
import DownloadIcon from '@mui/icons-material/Download';
import { TYPES } from "../utils/types";
const Icons = ({ record }) => {
  const history = useHistory();
  const {
    GetPodcastItemInfo,
    UnArchivePodcasts,
    DeleteMultiplePodcasts,
    ArchivePodcasts,
    GetExportsLinks,
  } = Hooks();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteFromTrashOpen, setDeleteFTrashModalOpen] = useState(false);
  const [restoreModalOpen, setRestoreModalOpen] = useState(false);
  const handleDeleteModalOpen = () => setDeleteModalOpen(true);
  const handleRestorePodcast = () => setRestoreModalOpen(true);
  const handleDeleteFromTrashPodcast = () => setDeleteFTrashModalOpen(true);
  const closeDeleteFromTrashPodcast = () => setDeleteFTrashModalOpen(false);
  const handleCloseRestorePodcast = () => setRestoreModalOpen(false);
  const handleDeleteModalClose = () => setDeleteModalOpen(false);
  const [ViewDetailsModal, setViewDetailsModal] = useState(false);
  const [apercu_modal, setApercu_modal] = useState(false);
  const handleCloseApercuModal = () => setApercu_modal(false);
  const handleOpenApercuModal = () => setApercu_modal(true);
  const handleOpen = () => setOpen(true);
  const [copiedExportChannel, setcopiedExportChannel] = useState(false);
  const [copiedExportPodcast, setcopiedExportPodcast] = useState(false);

  const podcast_data = useSelector(
    (state) => state.ReducerGetPodcastData.podcastData
  );
  const deletePodcastData = useSelector(
    (state) => state.ReducerGetPodcastData.deletePodcastData
  );
  const updatePodcastData = useSelector(
    (state) => state.ReducerGetPodcastData.updatePodcastData
  );
  const singlePodcastDelete = useSelector(
    (state) => state.ReducerGetPodcastData.singlePodcastDelete
  );
  const doneDeleteSinglePod = useSelector(
    (state) => state.ReducerGetPodcastData.doneDeleteSinglePod
  );
  const exportLinks = useSelector(
    (state) => state.ReducerGetPodcastData.exportLinks
  );
  const modules = useSelector((state) => state.ReducerUserData.modules);
  const isSuperAdmin = useSelector(
    (state) => state.ReducerUserData.isSuperAdmin
  );
  const FilterList = useSelector(
    (state) => state.ReducerGetPodcastData.FilterList
  );
  const success_preview = useSelector(
    (state) => state.ReducerGetPodcastData.success_preview
  );
  const success_rss = useSelector(
    (state) => state.ReducerGetPodcastData.success_rss
  );
  const playerpod = useSelector(
    (state) => state.ReducerGetPodcastData.playerById
  );
  useEffect(() => {}, [podcast_data, deletePodcastData, updatePodcastData]);
  useEffect(() => {
    if (singlePodcastDelete) {
      dispatch({ type: TYPES.single_podcast_delete, payload: false });
    }else if(doneDeleteSinglePod) {
      dispatch({ type: TYPES.done_DeleteSinglePod, payload: false });
    }
  }, [singlePodcastDelete, doneDeleteSinglePod, dispatch]);

  const handleClose = () => {
    setOpen(false);
    dispatch(cleanExportLinks());
    setcopiedExportPodcast(false);
    setcopiedExportChannel(false);
  };
  const handleDetailsModalClosing = () => {
    setViewDetailsModal(false);
    dispatch(cleanDataById());
  };

  const handleStatsBtn = () => {
    dispatch(Set_Id(record.id));
    window.parent.postMessage(
      {
        info: "showPodcastStat",
        value: record.id,
      },
      "*"
    );
  };
  async function getFileNameForDownload(id) {
    dispatch(GetDataById(id)).then(() => downloadPodcast(id));
  }
  return (
    <div className="icons_cont">
      {FilterList === false ? (
        <>
          {(modules?.podcast_preview_podcast || isSuperAdmin) && (
            <div
              has-permission="podcast_preview_podcast"
              onClick={() => {
                dispatch(loadPreview());
                dispatch(GetPlayerByID(record.id));
                handleOpenApercuModal();
              }}
            >
              <Tooltip title={t("Podcast.Preview")}>
                <PlayArrowIcon
                  className="icons_S"
                  sx={{ height: "18px", color: "#696969" }}
                />
              </Tooltip>
            </div>
          )}
          {(modules?.podcast_url_export || isSuperAdmin) && (
            <div
              has-permission="podcast_url_export"
              onClick={() => {
                dispatch(setLoadRss());
                GetExportsLinks(record.id);
                handleOpen();
              }}
            >
              <Tooltip title={t("Podcast.add-edit.RSS file link of")}>
                <LinkIcon className="icons_S" />
              </Tooltip>
            </div>
          )}
            {(modules?.podcast_post_edit || isSuperAdmin) && (
              <div
                has-permission="podcast_post_edit"
                onClick={() => {
                  GetPodcastItemInfo(record);
                  history.push(`/edit/${record.id}`);
                }}
              >
                <Tooltip title={t("Profile.edit")}>
                  <BorderColorIcon className="icons_S" />
                </Tooltip>
              </div>
            )}
          {(modules?.podcast_get_detail || isSuperAdmin) && (
            <div
              has-permission={"podcast_get_detail"}
              onClick={() => {
                setViewDetailsModal(true);
              }}
            >
              <Tooltip title={t("Profile.details")}>
                <VisibilityIcon className="icons_S" />
              </Tooltip>
            </div>
          )}
          <div onClick={handleStatsBtn}>
            <Tooltip title={t("statistique.statistique")}>
              <BarChartIcon className="icons_S" />
            </Tooltip>
          </div>
          <div>
            <div
              onClick={() => {     
                getFileNameForDownload(record.id);
              }}
            >
              <Tooltip title={t("media_center.table_actions.download")}>
                <DownloadIcon className="icons_S" />
              </Tooltip>
            </div>{" "}
          </div>
              {(modules?.podcast_delete_podcast || isSuperAdmin) && (
                <div
                  has-permission="podcast_delete_podcast"
                  onClick={() => {
                    handleDeleteModalOpen();
                  }}
                >
                  <Tooltip title={t("Profile.delete")}>
                    <DeleteIcon className="icons_S" />
                  </Tooltip>
                </div>
              )}
        </>
      ) : (
        <>
          <div
            has-permission="podcast_delete_podcast"
            onClick={() => {
              handleDeleteFromTrashPodcast();
            }}
          >
            <Tooltip title={t("Profile.delete")}>
              <DeleteOutlineIcon className="icons_S" />
            </Tooltip>
          </div>
          <div
            has-permission="podcast_delete_podcast"
            onClick={() => {
              handleRestorePodcast();
            }}
          >
            <Tooltip title={t("Podcast.restore-btn")}>
              <SettingsBackupRestoreIcon className="icons_S" />
            </Tooltip>
          </div>
        </>
      )}
      {/* rss modal */}
      {success_rss ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ height: "50%" }}>
            <ClipLoader
              color="inherit"
              thickness={1.6}
              style={{ marginTop: "6px" }}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <ModalInputs
          maxWidth="md"
          open={open}
          dividers={true}
          onCancel={handleClose}
          onClose={handleClose}
          className="modal-inputs-h2"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          CancelBtnName={t("Profile.close")}
          title={`${t("Podcast.add-edit.RSS file link of")}: ${record?.Title}`}
          children={
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="modal_container">
                <div className="modal_first_row">
                  <b className="channel_b">
                    {t("media_center.export_media.permalink")}
                  </b>
                  <div
                    className="rrs_channel"
                    style={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "6px",
                      color: "rgba(0,0,0,.85)",
                      padding: "0.5em",
                    }}
                  >
                    {" "}
                    {exportLinks?.URLExportChannel}
                  </div>
                  <CopyToClipboard
                    text={exportLinks?.URLExportChannel}
                    onCopy={() => {
                      setcopiedExportChannel(true);
                      setTimeout(() => {
                        setcopiedExportChannel(false);
                      }, 1000);
                    }}
                  >
                    {copiedExportChannel ? (
                      <CheckCircleOutlineIcon sx={{ color: "green" }} />
                    ) : (
                      <ContentCopyIcon />
                    )}
                  </CopyToClipboard>
                </div>
                <div className="modal_second_row">
                  <b className="integ_link_b">
                    {t("Podcast.add-edit.Integration link")}
                  </b>
                  <div
                    className="rrs_export"
                    style={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "6px",
                      color: "rgba(0,0,0,.85)",
                      padding: "0.5em",
                    }}
                  >
                    {" "}
                    {exportLinks?.URLExportPodcast}
                  </div>

                  <CopyToClipboard
                    text={exportLinks?.URLExportPodcast}
                    onCopy={() => {
                      setcopiedExportPodcast(true);
                      setTimeout(() => {
                        setcopiedExportPodcast(false);
                      }, 1000);
                    }}
                  >
                    {copiedExportPodcast ? (
                      <CheckCircleOutlineIcon sx={{ color: "green" }} />
                    ) : (
                      <ContentCopyIcon />
                    )}
                  </CopyToClipboard>
                </div>
                <div className="embed_code">
                  <b className="embed_title">{t("Podcast.Code_Embed")}</b>
                  <EmbedCodeTemplate link={exportLinks?.URLExportChannel} />
                </div>
              </div>
            </Typography>
          }
        />
      )}
      {/* Details Modal */}
      <ModalInputs
        maxWidth="sm"
        className="modal-inputs-h2"
        open={ViewDetailsModal}
        dividers={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleDetailsModalClosing}
        onCancel={handleDetailsModalClosing}
        CancelBtnName={t("Profile.close")}
        title={`${t("Podcast.add-edit.Podcast detail")} : ${record?.Title}`}
        children={
          <>
            <div className="Podcast-details-modal">
              <div className="Podcast-details-modal-container">
                <img
                  className="thumbnail-container"
                  src={record?.Vignette}
                  alt=""
                />
              </div>
              <div className="Podcast-details-modal-container">
                <span className="Podcast-details-modal-title">
                  {t("Podcast.Identifier")}
                </span>
                <span className="Podcast-details-modal-value">
                  {record?.id ? record?.id : "--"}
                </span>
              </div>
              <div className="Podcast-details-modal-container">
                <span className="Podcast-details-modal-title">{t("Podcast.Title")}</span>
                <span className="Podcast-details-modal-value">
                  {record?.Title ? record?.Title : "--"}
                </span>
              </div>
              <div className="Podcast-details-modal-container">
                <span className="Podcast-details-modal-title">
                  {t("Profile.description")}
                </span>
                <span
                  className="Podcast-details-modal-value"
                  style={{
                    height: "auto",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  {record?.Description ? record?.Description : "--"}
                </span>
              </div>
              <div className="Podcast-details-modal-container">
                <span className="Podcast-details-modal-title">
                  {t("Podcast.add-edit.Category")}
                </span>
                <span
                  className="Podcast-details-modal-value"
                  style={{
                    height: "auto",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  {record?.Themes ? (
                    record?.Themes?.map((theme) => (
                      <Chip key={theme.ThemeId} label={theme.ThemeTitle} />
                    ))
                  ) : (
                    <Typography variant="body2">--</Typography>
                  )}
                </span>
              </div>
            </div>
          </>
        }
      />
      {/*  Delete Dialog */}
      {doneDeleteSinglePod ? null : singlePodcastDelete ? (
        <Dialog
          open={deleteModalOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ height: "50%" }}>
            <ClipLoader
              color="inherit"
              thickness={1.6}
              style={{ marginTop: "6px" }}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <ModalConfirm
          className="modal-delete-podcast"
          fullWidth={true}
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title={t("Podcast.delete-trash")}
          content={t("Podcast.moved-trash")}
          onCancel={handleDeleteModalClose}
          CancelBtnName={t("Podcast.add-edit.cancel")}
          ConfirmBtnName={t("Podcast.add-edit.Delete")}
          onConfirm={() => {
            ArchivePodcasts(record.id);
            handleDeleteModalClose();
          }}
        />
      )}
      {
        //deleteFromTrashOpen
        <ModalConfirm
          className="modal-delete-podcast"
          fullWidth={true}
          open={deleteFromTrashOpen}
          onClose={closeDeleteFromTrashPodcast}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title={t("Podcast.Are you sure you want to delete this podcast ?")}
          content={t("Podcast.add-edit.Podcast will be deleted")}
          CancelBtnName={t("Podcast.add-edit.cancel")}
          onCancel={closeDeleteFromTrashPodcast}
          ConfirmBtnName={t("Podcast.add-edit.Delete")}
          onConfirm={() => {
            DeleteMultiplePodcasts(record.id);
            closeDeleteFromTrashPodcast();
          }}
        />
      }
      {
        <ModalConfirm
          fullWidth={true}
          className="modal-delete-podcast"
          open={restoreModalOpen}
          onClose={handleCloseRestorePodcast}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title={t("Podcast.restor-pod")}
          content={t("Podcast.restored-pod")}
          CancelBtnName={t("Podcast.add-edit.cancel")}
          onCancel={handleCloseRestorePodcast}
          ConfirmBtnName={t("Podcast.restore-btn")}
          onConfirm={() => {
            UnArchivePodcasts(record.id);
            handleCloseRestorePodcast();
          }}
        />
      }
      {/* Apercu Modal */}
      {success_preview ? (
        <Dialog
          open={apercu_modal}
          onClose={handleCloseApercuModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ height: "50%" }}>
            <ClipLoader
              color="inherit"
              thickness={1.6}
              style={{ marginTop: "6px" }}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <ModalInputs
          open={apercu_modal}
          maxWidth="md"
          className="modal-inputs-styling modal-inputs-podcast"
          onClose={handleCloseApercuModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          content={
            <Typography id="modal-modal-description" sx={{ mt: 0, mb: 0 }}>
              <iframe
                className="iframe_container"
                title={playerpod?.podcast?.title}
                src={`https:${playerpod?.link}`}
                width="900"
                allow="clipboard-read; clipboard-write" 
              ></iframe>
            </Typography>
          }
        />
      )}
    </div>
  );
};

export default Icons;
