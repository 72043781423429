import { Button, Modal } from "antd";
import Hooks from '../Medias/utils/hooks';
import { useAppSelector } from '../redux/hooks';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const DeleteAudioDescription = ({ idVideo }) => {
    const { t } = useTranslation();
  const modalToOpen = useAppSelector((state) => state.MediaReducer.modalToOpen);
  const { handleCancel } = Hooks();

  const deleteDesc = async() =>{
    await axios.get(
        `${window.process.env.API_HOST_GLOBAL}/video/delete-audio-description/${idVideo}`,
        {
          headers: {
            Authorization: `Bearer ${window.process.env.API_TOKEN}`,
          },
        }
      );
      handleCancel()
  }

  return (
    <>
        <Modal
        footer={[
            <Button key="back" onClick={handleCancel}>
             {t("media.close-modal")}
            </Button>,
            <Button key="submit" onClick={deleteDesc}>
            {t("media.delete-desc")}
          </Button>
          ]}
          onCancel={handleCancel}
          title={t("media.title-desc")}
          open={modalToOpen === "deleteDescription"}
        >
            <p>{t("media.confirm-desc")}</p>
        </Modal>
    </>
  )
}

export default DeleteAudioDescription
