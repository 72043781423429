import { useDispatch, useSelector } from "react-redux";
import {
  AddPodcast,
  PodcastCondition,
  PodcastConditionAdd,
  SetLoading,
} from "./action";
import { PodcastGraphQlFetchData } from "../graph-ql-podcast/GraphQlFetchData";
import { Form } from "antd";
import { TYPES } from "./types";
import { useHistory } from "react-router-dom";

const Hooks = () => {
  const {
    UPDATEPODCAST,
    ADDPODCAST,
    DELETEPODCAST,
    MULTIPODCAST,
    ARCHIVEPODCAST,
    UNARCHIVEPODCASTS,
    GETLINKSEXPORTS
  } = PodcastGraphQlFetchData();
  const [formAddPodcast] = Form.useForm();
  const history = useHistory();

  const dispatch = useDispatch();
  const PodValues = useSelector(
    (state: any) => state.ReducerGetPodcastData.AddPodcastItems
  );

  const PodcastId = useSelector(
    (state: any) => state.ReducerGetPodcastData.PodcastCondition.elementSelected
  );
  const videoToken = useSelector((state: any) => state.ReducerGetPodcastData.podcastVideoToken);
  const tokenMP3 = videoToken.toString().replace(/\.[^.]+$/i, '.mp3');
  const vignetteUrl = PodValues?.Vignette?.substring(PodValues?.Vignette?.lastIndexOf("/") + 1);
  const HandleAddPodcast = () => {
    ADDPODCAST({ variables: { input: {
      ...PodValues,  
      Vignette: PodValues?.Vignette.split('/').pop(),
      PodcastItems:{
        Title: videoToken.toString(),
        Audio: tokenMP3,
        Description:PodValues?.Description,
        Video: tokenMP3,
        Vignette:vignetteUrl
      },
      Themes: PodValues?.Themes?.map((theme: any)=> {
      return {
        ThemeId:theme.ThemeId
      }
    })}
    } });
  };
  const handleUpdatePodcast = () => {
    UPDATEPODCAST({
      variables: {
        input: {
          ...PodValues,
          Id: PodcastId,
          Vignette: PodValues?.Vignette.split('/').pop(),
          Themes: PodValues?.Themes?.map((theme: any) => {
            return {
              ThemeId: theme.ThemeId,
            };
          }),
        },
      },
    })
      .then(() => {
        history.push("/ws-media/podcasts");
      })
      .catch((error) => {
        console.error("Mutation failed:", error);
  
      });
  };
  

  const onResetPodcast = () => {
    dispatch(
      PodcastCondition({
        PodcastConditionsName: "editPodcast",
        PodcastConditionsValue: false,
      })
    );
    dispatch(
      PodcastCondition({
        PodcastConditionsName: "addPodcast",
        PodcastConditionsValue: false,
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "CreatedBy",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "Title",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "Description",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "Vignette",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "Lang",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "Explicite",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "IsDownloadable",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "IsReady",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "StartDate",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "EndDate",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "IsAccessible",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "CreatedAt",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "UpdatedAt",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "PodcastCategoryId",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "Author",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "Editor",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "Encoded_By",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "Copyright",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "Keywords",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "IsArchived",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "ArchivedDate",
        addPodcastDataValueChange: "",
      })
    );
    dispatch(
      AddPodcast({
        addPodcastDataNameChange: "Themes",
        addPodcastDataValueChange: "",
      })
    );
  };

  const GoAddPodcast = () => {
    formAddPodcast.setFieldsValue({
      Title: "",
      Description: "",
      Vignette: "",
      Lang: "Français",
      Explicite: true,
      IsReady: true,
      StartDate: "",
      EndDate: "",
      IsAccessible: "",
      IsDownloadable:false,
      CreatedAt: "",
      UpdatedAt: "",
      PodcastCategoryId: "",
      Author: "",
      Editor: "",
      Encoded_By: "",
      Copyright: "",
      Keywords: "",
      IsArchived: "",
      ArchivedDate: "",
      Themes: [],
    });
    dispatch(
      PodcastCondition({
        PodcastConditionsName: "addPodcast",
        PodcastConditionsValue: true,
      })
    );
  };

  const GetPodcastItemInfo = (record: any) => {
    dispatch(
      PodcastCondition({
        PodcastConditionsName: "editPodcast",
        PodcastConditionsValue: true,
      })
    );
    dispatch(
      PodcastCondition({
        PodcastConditionsName: "elementSelected",
        PodcastConditionsValue: record.id,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Title",
        addPodcastDataValueChange: record.Title ,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "CreatedBy",
        addPodcastDataValueChange: record.CreatedBy ,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Description",
        addPodcastDataValueChange: record.Description,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Vignette",
        addPodcastDataValueChange: record.Vignette,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Lang",
        addPodcastDataValueChange: record.Lang ,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Explicite",
        addPodcastDataValueChange: record.Explicite ,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "IsDownloadable",
        addPodcastDataValueChange: record.IsDownloadable ,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "IsReady",
        addPodcastDataValueChange: record.IsReady ,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "StartDate",
        addPodcastDataValueChange: record.StartDate,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "EndDate",
        addPodcastDataValueChange: record.EndDate,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "IsAccessible",
        addPodcastDataValueChange: record.IsAccessible,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "CreatedAt",
        addPodcastDataValueChange: record.CreatedAt ,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "UpdatedAt",
        addPodcastDataValueChange: record.UpdatedAt ,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "PodcastCategoryId",
        addPodcastDataValueChange: record.PodcastCategoryId,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Author",
        addPodcastDataValueChange: record.Author,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Editor",
        addPodcastDataValueChange: record.Editor,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Encoded_By",
        addPodcastDataValueChange: record.Encoded_By,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Copyright",
        addPodcastDataValueChange: record.Copyright,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Keywords",
        addPodcastDataValueChange: record.Keywords,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "IsArchived",
        addPodcastDataValueChange: record.IsArchived ,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "ArchivedDate",
        addPodcastDataValueChange: record.ArchivedDate,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Themes",
        addPodcastDataValueChange: record.Themes ,
      })
    );
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "PodcastItems",
        addPodcastDataValueChange: record.PodcastItems ,
      })
    );
  };

  const DeleteSelectedPodcast = (id: any) => {
    DELETEPODCAST({ variables: { id: id } });
  };

  const DeleteMultiplePodcasts = (array: any) => {
    MULTIPODCAST({ variables: { ids: array} });
  };

  const ArchivePodcasts = (array: any) => {
    ARCHIVEPODCAST({ variables: { ids: array} });
  };

  const UnArchivePodcasts = (array: any) => {
    UNARCHIVEPODCASTS({ variables: { ids: array} });
  };

  const GetExportsLinks = (PodcastId: any) => {
    GETLINKSEXPORTS({variables: {where : {id:PodcastId},pagination: {
      limit: 0,
      offset: 0
    }}})
  }

  const onDisableSubmit = (type: any) => {
    if (type === "isDisabled") {
      dispatch(
        PodcastCondition({
          PodcastConditionsName: "disableSubmitForm",
          PodcastConditionsValue: false,
        })
      );
    } else if (type === "isNotDisabled") {
      dispatch(
        PodcastCondition({
          PodcastConditionsName: "disableSubmitForm",
          PodcastConditionsValue: true,
        })
      );
    }
  };

  return {
    HandleAddPodcast,
    handleUpdatePodcast,
    GoAddPodcast,
    GetPodcastItemInfo,
    DeleteSelectedPodcast,
    onResetPodcast,
    DeleteMultiplePodcasts,
    ArchivePodcasts,
    UnArchivePodcasts,
    PodValues,
    GetExportsLinks,
    onDisableSubmit
  };
};

export default Hooks;
