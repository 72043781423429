import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom'
import { useAppSelector } from "../../redux/hooks";
  import Hooks from "../utils/hooks";
import { Spin } from 'antd';

const spinnerStyles:any = {
    position:"absolute",
    left: 0,
    right: 0, 
    top:'40%',
    marginLeft: "auto", 
    marginRight: "auto",
  }
const Montage = () => {
  const params = useParams<any>()
  const [loadingMontage, setLoadingMontage] = useState(true)
  const {  getMontage } = Hooks();
  const urlMontage = useAppSelector((state) => state.MediaReducer.urlMedia);
  useEffect(()=>{
    (async()=>{
        await getMontage(params?.id);
        setLoadingMontage(false)
    })()
  },[params?.id])
  return (
    <>
        {loadingMontage 
      ? <Spin style={spinnerStyles} size="large" />
      : <iframe
          title={urlMontage}
          className="iframeVideo"
          id="iframe_list"
          allowFullScreen
          frameBorder="0"
          src={urlMontage}
        ></iframe>
      }
    </>
  )
}

export default Montage