import React from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const PlayListActions = ({url}) => {
  const { t } = useTranslation();
  const { modules } = useSelector((state) => ({
    ...state.Dashboard,
  }));
  const handleClick = (path) => {
    const parentUrl = window.parent.location.href;
    const newUrl = parentUrl.replace('/dashboard', `/${path}`);
    window.parent.location.href = newUrl;
  };
  return (
    <div className="action actionModification">
      {modules?.playlist_playlist_show_media_action?.authorized && (
        <div has-permission="playlist_playlist_show_media">
          <Tooltip placement="top" title={t("Dashboard.watch")}>
            <PlayArrowIcon 
              color="action"
              fontSize="small"
              className={"icon_action"}
              onClick={() => handleClick(url)}
            />
          </Tooltip>
        </div>
      )}
      {modules?.playlist_post_edit_action?.authorized && (
        <div has-permission="playlist_post_edit">
          <Tooltip placement="top" title={t("Dashboard.edit")}>
            <ModeEditIcon
              color="action"
              fontSize="small"
              className={"icon_action"}
              onClick={() => handleClick(url)}

            />
          </Tooltip>
        </div>
      )}{" "}
      {modules?.playlist_get_detail_action?.authorized && (
        <div has-permission="playlist_get_detail">
          <Tooltip placement="top" title={t("Dashboard.details")}>
            <RemoveRedEyeIcon
              color="action"
              fontSize="small"
              className={"icon_action"}
              onClick={() => handleClick(url)}

            />
          </Tooltip>
        </div>
      )}
      {modules?.playlist_delete_playlist_action?.authorized && (
        <div has-permission="playlist_delete_playlist">
          <Tooltip placement="top" title={t("Dashboard.delete")}>
            <DeleteIcon
              color="action"
              fontSize="small"
              className={"icon_action"}
              onClick={() => handleClick(url)}

            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default PlayListActions;
