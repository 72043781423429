import { createSlice } from "@reduxjs/toolkit";
import {
  getPlayList,
  deletePlayList,
  DeleteMultiple,
  addPlayList,
  editPlaylist,
} from "../actions";

export const PlayListSlice = createSlice({
  name: "playlist",
  initialState: {
    AllPlayListsData: {},
    errorMessage: "",
    action: false,
    isLoadingData: false,
  },
  extraReducers: {
    [getPlayList.pending]: (state) => {
      state.isLoadingData = true;
    },
    [getPlayList.fulfilled]: (state, { payload }) => {
      state.isLoadingData = false;
      state.AllPlayListsData = payload;
    },
    [getPlayList.rejected]: (state, { payload }) => {
      state.isLoadingData = false;
      state.errorMessage = payload;
    },
    [deletePlayList.pending]: (state) => {},
    [deletePlayList.fulfilled]: (state, { payload }) => {
      state.action = !state.action;
    },
    [deletePlayList.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
    [DeleteMultiple.pending]: (state) => {},
    [DeleteMultiple.fulfilled]: (state, { payload }) => {
      state.action = !state.action;
    },
    [DeleteMultiple.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
    [addPlayList.pending]: (state) => {},
    [addPlayList.fulfilled]: (state, { payload }) => {
      state.action = !state.action;
    },
    [addPlayList.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
    [editPlaylist.pending]: (state) => {},
    [editPlaylist.fulfilled]: (state, { payload }) => {
      state.action = !state.action;
    },
    [editPlaylist.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
  },
});

export default PlayListSlice.reducer;
