export const MediaList = () => {


    const paginationPropsGroup = () => {
        return({
            order: 'descend',
            pageSize: 10,
            columnKey: 0,
            current: 1,
            id: [],
            idLive: 0
        })
    }

    const showDivsConditionsMedia = () => {
        return({elementSelected: 0, addGroup: false, editGroup: false, disablesubmitform: true})
    }

    const AddMediaConstraintsData = () => {

        return({title: "", StartDate: "", EndDate: "", ParentId: null})
    }
    const FilterGroupe = () => {
        return({
            search: "",
            searchFake: "",
            parent: "",
            date: ["", ""]
        })
    }

    return({paginationPropsGroup, showDivsConditionsMedia, AddMediaConstraintsData, FilterGroupe})
}
