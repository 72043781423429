import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { useSelector, useDispatch } from "react-redux";
import { PodcastConditionAdd, setPodcastThemes } from "../../utils/action";
import { useTranslation } from "react-i18next";
import langues from "../../utils/langues";
import { SelectList } from "@empreinte/components.select-list";
import "../../../index.css";
import { AutoComplete } from "@empreinte/components.autocomplete";
import { TextField } from "@mui/material";
import { Label } from "@empreinte/components.label";
import { Select, MenuItem } from "@mui/material";
import { Autocomplete, Chip,InputLabel } from "@mui/material";
const DropdownMenu = ({ items }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const generateRandomString = () => {
    return Math.random().toString(36);
  };
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChangeSelect = (e, newValue) => {
    let selectedValue = newValue.map((item) => {
      return { value: item?.value, label: item?.label };
    });

    setSelectedValues(selectedValue.map((item) => item.value));


    dispatch(
      setPodcastThemes({
        newValue: selectedValue,
      })
    );
  };

  const renderMenuItems = (items, level = 0) => {
    return items.reverse().map((item,index) => ({
      value: item?.id,
      label: item?.title,
      level: level,
      children:
        item?.themes?.length > 0
          ? renderMenuItems(item?.themes?.reverse(), level + 1)
          : [],
      key: `${item?.id ? item?.id : 0}-${level}-${index}-${generateRandomString()}`
    }));
  };

  const flattenItems = (items) => {
    const flattened = [];
    const stack = [...items];

    while (stack.length) {
      const item = stack.pop();
      flattened.push(item);

      if (item.children) {
        stack.push(...item.children);
        delete item.children;
      }
    }

    return flattened;
  };
  const values = useSelector(
    (state) => state.ReducerGetPodcastData.AddPodcastItems
  );
  const themesValues = values?.Themes?.map((res,i) => {
    return { value: res?.ThemeId, label: res?.ThemeTitle , key:i};
  });
  const isOptionSelected = (option) => {
    return selectedValues.includes(option.value);
  };
  const flattenedItems = flattenItems(renderMenuItems(items));

  useEffect(() => {
    if (themesValues && themesValues.length > 0) {
      setSelectedValues(themesValues.map((item) => item.value));
    }
  }, []);
  return (
    <Autocomplete
      multiple
      options={flattenedItems}
      getOptionLabel={(option) => option.label ? option.label : ""}
      onChange={handleChangeSelect}
      value={themesValues || []}
      classes={{
        paper: "options-list-container"
      }}
      renderInput={(params,i) => (
        <div key={`input-${i}`} style={{ marginBottom: '20px' }}>
        <InputLabel sx={{fontSize:"24px",fontWeight:"bold"}} shrink>{t('Podcast.add-edit.Categories')}</InputLabel>
        <TextField
          {...params}
          placeholder={t('Podcast.add-edit.Categories')}
          fullWidth
        />
      </div>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
          key={option.key}
            label={option.label}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          key={option.key}
          disabled={option.level === 0 || isOptionSelected(option)}
          style={{ marginLeft: `${option.level * 20}px` }}
        >
          {option.label}
        </MenuItem>
      )}
    />
  );
};


export const CategorieSelect = () => {
  const themesList = useSelector(
    (state) => state.ReducerGetPodcastData.SelectChoices
  );
  return (
    <div className="categoriesPodcast">
      <DropdownMenu items={themesList?.channels ? Object.values(themesList?.channels) : Object.values(themesList)} />
    </div>
  );
};


export const LanguageSelect = ({ editedValue }) => {
  const dispatch = useDispatch();
  const values = useSelector(
    (state) => state.ReducerGetPodcastData.AddPodcastItems
  );
  const [lang, setLang] = useState(langues[0]);
  const { t } = useTranslation();

  const handleChange = (e) => {
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: e.target.name,
        addPodcastDataValueChange: e.target.value,
      })
    );
  };

  useEffect(() => {
    setLang(values?.Lang);
  }, [values?.Lang, lang, editedValue]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100%",
        paddingBottom: "20px",
        paddingTop: "20px",
      }}
    >
      <FormControl fullWidth>
        <SelectList
          name="Lang"
          style={{ width: "100%" }}
          className="select-list-bit"
          labelText={`${t("Podcast.add-edit.Language")}`}
          handleChange={handleChange}
          defaultValue={langues[0]}
          value={values?.Lang}
          data={langues?.map((l) => {
            return { value: l, label: l };
          })}
        />
      </FormControl>
    </Box>
  );
};
