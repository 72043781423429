import { Suspense } from "react";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./i18n";
import { Spin } from "antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
	uri: window.process.env.API_HOST_DAM + "/query", // 'https://8080-empreintedam-damapiv2-g3npq0ecdqi.ws-eu47.gitpod.io/query',
	method: "post",
});

const authLink = setContext(() => {
	const token = localStorage.getItem("authToken");
	return {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
});

const client = new ApolloClient({ link: authLink.concat(httpLink), cache: new InMemoryCache() });
const queryClient = new QueryClient();

export default (
	<QueryClientProvider client={queryClient}>
		<Suspense
			fallback={
				<Spin className="Spin">
					<div></div>
				</Spin>
			}
		>
			<Provider store={store}>
				<ApolloProvider client={client}>
					<App />
				</ApolloProvider>
			</Provider>
		</Suspense>
	</QueryClientProvider>
);
