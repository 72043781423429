import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Drawer, Menu, Dropdown, Checkbox, Radio } from "antd";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { ModalToOpen, setIdClicked } from "../../Medias/store/mediasActions";
import { useAppSelector } from "../../redux/hooks";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import TheatersIcon from "@mui/icons-material/Theaters";
import { DownOutlined } from "@ant-design/icons";
import BarChartIcon from "@mui/icons-material/BarChart";
import PeopleIcon from "@mui/icons-material/People";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FlipToFrontIcon from "@mui/icons-material/FlipToFront";
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import CommentIcon from "@mui/icons-material/Comment";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import QuizIcon from "@mui/icons-material/Quiz";
import DescriptionIcon from "@mui/icons-material/Description";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import { useHistory } from "react-router-dom";
import "./drawer.scss";
import {
  setVideoId,
  setMediaId,
} from "../../AddAudioDescription/utils/actions";
import { useTranslation } from "react-i18next";
import Hooks from "../utils/hooks";
import { mdiArrangeBringToFront,mdiArrangeSendBackward } from '@mdi/js';
import {Icon} from '@mdi/react';

export function DetailsModal({ onCancel }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [isEditMenuVisible, setIsEditMenuVisible] = useState(false);
  const [isAudioMenuVisible, setIsAudioMenuVisible] = useState(false);
  const [isSettingsMenuVisible, setIsSettingsMenuVisible] = useState(false);
  const [isSkinPlayerMenuVisible, setIsSkinPlayerMenuVisible] = useState(false);
  const [defaultSkin, setDefaultSkin] = useState();
  const [isPush, setIsPush] = useState();
  const modalToOpen = useAppSelector((state) => state.MediaReducer.modalToOpen);
  const {  pushVideo } = Hooks();
  const togglePushVideo = async (type) => {
    const updatedModal = { ...modalToOpen.value, isPush: !modalToOpen.value.isPush };
    setIsPush(type==="enable-push-video"? true : false)
    modalToOpen.value = updatedModal;
        await pushVideo(modalToOpen.value, type);
  };
  const iconStyle = {
    color: "gray",
    position: "relative",
    marginRight: "8px",
    cursor: "pointer",
  };
  const textStyle = {
    color: "gray",
    fontSize: "17px",
  };
  useEffect(() => {
    modalToOpen &&
      modalToOpen.value &&
      modalToOpen.value.defaultSkin &&
      setDefaultSkin(modalToOpen.value.defaultSkin);
  }, [modalToOpen]);

  const redirectMenu = (option) => {
    switch (option) {
      case "edit": {
        history.push(
          "/ws-media/video/" +
            modalToOpen?.value?.fileTitle +
            "/" +
            modalToOpen?.value?.id +
            "/edit"
        );
        break;
      }
      case "montage": {
        history.push(
          `/ws-media/video/${modalToOpen?.value?.fileTitle}/${modalToOpen?.value?.id}/edit/montage`
        );
        break;
      }
      case "soustitrage": {
        history.push({
          pathname: `/ws-media/video/${modalToOpen?.value?.fileTitle}/${modalToOpen?.value?.id}/edit/sous-titrages`,
          label: "2",
        });
        break;
      }
      case "Rich-média": {
        history.push({
          pathname: `/ws-media/video/${modalToOpen?.value?.fileTitle}/${modalToOpen?.value?.id}/edit/rich-media`,
          label: "3",
        });
        break;
      }
      case "Change-src": {
        history.push(
          `/ws-media/addMedia?id=${modalToOpen?.value?.id}&mediaid=${modalToOpen?.value?.medias[0].id}&changeSource=true`
        );
      }
      case "addDescription": {
        dispatch(setVideoId(modalToOpen.value.id));
        dispatch(setMediaId(modalToOpen.value.medias[0].id));
        window.parent.postMessage(
          {
            info: "addDescription",
            value: modalToOpen.value,
          },
          "*"
        );
      }
      case "showModalForDownloadVideos": {
        dispatch(setIdClicked(modalToOpen.value.id));
        dispatch(ModalToOpen(option));
        break;
      }
      default:
        if (window.location !== window.parent.location) {
          window.parent.postMessage(
            { info: option, value: modalToOpen?.value },
            "*"
          );
        }
    }
  };
  const generateKey = (key) => `${key}_${new Date().getTime()}`;
  const editMenu = (
    <Menu>
      <Menu.Item key={() => generateKey("edit")}>
        <Button type="text" onClick={() => redirectMenu("edit")}>
          Modifier
        </Button>
      </Menu.Item>
      <Menu.Item key={() => generateKey("montage")}>
        <Button onClick={() => redirectMenu("montage")} type="text">
          Montage
        </Button>
      </Menu.Item>
      <Menu.Item key={() => generateKey("soustitrage")}>
        <Button onClick={() => redirectMenu("soustitrage")} type="text">
          Sous-titrage
        </Button>
      </Menu.Item>
      <Menu.Item key={() => generateKey("richMedia")}>
        <Button onClick={() => redirectMenu("Rich-média")} type="text">
          Rich-média
        </Button>
      </Menu.Item>
    </Menu>
  );

  const audioDescriptionMenu = (
    <Menu>
      <Menu.Item key={() => generateKey("addDescription")}>
        <Button
          onClick={() => redirectMenu("addDescription")}
          disabled={modalToOpen.value.audioDescription === 2}
          type="text"
        >
          {t("video.add_description")}
        </Button>
      </Menu.Item>
      <Menu.Item key={() => generateKey("editDescription")}>
        <Button
          onClick={() => redirectMenu("editDescription")}
          disabled={modalToOpen.value.audioDescription === 0}
          type="text"
        >
          {t("video.edit_description")}
        </Button>
      </Menu.Item>
      <Menu.Item key={() => generateKey("deleteDescription")}>
        <Button
          onClick={() => redirectMenu("deleteDescription")}
          disabled={modalToOpen.value.audioDescription === 0}
          type="text"
        >
          {t("video.delete_description")}
        </Button>
      </Menu.Item>
    </Menu>
  );
  const settingsMenu = (
    <Menu style={{ padding: "20px" }}>
      <Menu.Item key={() => generateKey("allow_share")}>
        <Checkbox
          onClick={() => redirectMenu("allow_share")}
          checked={modalToOpen.value.allow_share}
          type="text"
        >
          {t("video.setting_share")}
        </Checkbox>
      </Menu.Item>
      <Menu.Item key={() => generateKey("allow_rating")}>
        <Checkbox
          onClick={() => redirectMenu("allow_rating")}
          checked={modalToOpen.value.allow_rating}
          type="text"
        >
          {t("video.setting_rating")}
        </Checkbox>
      </Menu.Item>
      <Menu.Item key={() => generateKey("allow_comment")}>
        <Checkbox
          onClick={() => redirectMenu("allow_comment")}
          checked={modalToOpen.value.allow_comment}
          type="text"
        >
          {t("video.setting_comment")}
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  const onSkinChange = (e) => {
    setDefaultSkin(e.target.value);
  };
  const skinPLayerMenu = (
    <Menu style={{ padding: "20px" }}>
      <Radio.Group onChange={onSkinChange} value={defaultSkin}>
        <Menu.Item key={() => generateKey("simple_skin_player")}>
          <Radio
            onClick={() => redirectMenu("simple_skin_player")}
            value="simple"
          >
            Simple
          </Radio>
        </Menu.Item>
        <Menu.Item key={() => generateKey("navigation_skin_player")}>
          <Radio
            onClick={() => redirectMenu("navigation_skin_player")}
            value="navigation"
          >
            Navigation
          </Radio>
        </Menu.Item>
        <Menu.Item
          key={() => generateKey("interactivity_with_timeline_skin_player")}
        >
          <Radio
            onClick={() =>
              redirectMenu("interactivity_with_timeline_skin_player")
            }
            value="interactivity_with_timeline"
          >
            {t("video.skin_interactivity_with_timeLine")}
          </Radio>
        </Menu.Item>
        <Menu.Item
          key={() => generateKey("interactivity_without_timeline_skin_player")}
        >
          <Radio
            onClick={() =>
              redirectMenu("interactivity_without_timeline_skin_player")
            }
            value="skin_interactivity_without_timeLine"
          >
            {t("video.skin_interactivity_without_timeLine")}
          </Radio>
        </Menu.Item>
      </Radio.Group>
    </Menu>
  );
  return (
    <>
      {modalToOpen &&
        modalToOpen.type &&
        modalToOpen.type == "showListAction" && (
          <Drawer
            style={{ overflowX: "hidden" }}
            title="Apercu"
            placement="right"
            closable={false}
            onClose={() => dispatch(ModalToOpen(""))}
            open={modalToOpen.type == "showListAction"}
            getContainer={false}
          >
            <img src={modalToOpen?.value?.logo} alt="logo" className="logo" />
            <div className="square">
              <span className="titledetail">{t("video.details")}</span>
            </div>
            <div>
              <span style={textStyle}>{t("video.title")}:</span>{" "}
              {modalToOpen?.value?.title}
            </div>
            <div>
              <span style={textStyle}>{t("video.contributeur")}:</span>
              {modalToOpen?.value?.contributor.firstName +
                modalToOpen?.value?.contributor.lastName}
            </div>
            <div>
              <span style={textStyle}>{t("video.Themes")}:</span>
              {modalToOpen?.value?.themes?.map((theme, index) => (
                <span key={theme?.title + index}>{theme?.title}</span>
              ))}
            </div>
            <div>
              <span style={textStyle}>{t("video.number_visitors")}:</span> -
            </div>
            <div>
              <span style={textStyle}>{t("video.startdate")}:</span>
              {moment(modalToOpen?.value?.startDate).format("DD/MM/YYYY")}
            </div>
            <div className="square">
              <span className="titledetail">{t("Actions")}</span>
            </div>
            <div
              onMouseLeave={() => {
                setIsAudioMenuVisible(false);
                setIsEditMenuVisible(false);
                setIsSettingsMenuVisible(false);
                setIsSkinPlayerMenuVisible(false);
              }}
              style={{
                position: "relative",
                marginTop: "10px",
                cursor: "pointer",
              }}
            >
              <>
                <ModeEditIcon
                  sx={{ color: "gray" }}
                  onMouseEnter={() => {
                    setIsEditMenuVisible(true);
                    setIsAudioMenuVisible(false);
                    setIsSettingsMenuVisible(false);
                    setIsSkinPlayerMenuVisible(false);
                  }}
                />
                <Dropdown
                  overlay={editMenu}
                  visible={isEditMenuVisible}
                  onMouseEnter={() => setIsEditMenuVisible(true)}
                  onMouseLeave={() => setIsEditMenuVisible(false)}
                >
                  <DownOutlined
                    style={{
                      color: "gray",
                      fontSize: "10px",
                      position: "relative",
                      marginRight: "10px",
                      bottom: "4px",
                      left: "-1px",
                    }}
                  />
                </Dropdown>
              </>
              <Tooltip title={t("video.change_source")}>
                <TheatersIcon
                  sx={iconStyle}
                  onClick={() => redirectMenu("Change-src")}
                />
              </Tooltip>
              <Tooltip title={t("video.statistics")}>
                <BarChartIcon
                  sx={iconStyle}
                  onClick={() => redirectMenu("statsPerVideo")}
                />
              </Tooltip>
              <Tooltip title={t("video.gerer_abonnees")}>
                <PeopleIcon
                  sx={iconStyle}
                  onClick={() => redirectMenu("addSubscribers")}
                />
              </Tooltip>
              <Tooltip
                title={t("video.download_video")}
                onClick={() => redirectMenu("showModalForDownloadVideos")}
              >
                <FileDownloadIcon sx={iconStyle} />
              </Tooltip>
              <Tooltip title={t("video.metadonne_video")}>
              <svg
                   xmlns="http://www.w3.org/2000/svg"  width="25" height="25" viewBox="0 0 20 12"  fill="none"
                   style={iconStyle}
                   onClick={() => redirectMenu("showVideoDetail")}
                   >
                  <ellipse cx="7.5" cy="7.05882" rx="7.5" ry="7.05882" fill="#808080"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.1915 7.4256C2.06888 7.31271 2 7.15962 2 7C2 6.84038 2.06888 6.68729 2.1915 6.5744L5.89155 3.16898C6.01491 3.05933 6.18012 2.99865 6.35162 3.00002C6.52311 3.00139 6.68716 3.0647 6.80843 3.17632C6.9297 3.28793 6.99848 3.43891 6.99997 3.59675C7.00147 3.75459 6.93554 3.90665 6.8164 4.02019L3.57877 7L6.8164 9.97981C6.93554 10.0933 7.00147 10.2454 6.99998 10.4032C6.99848 10.5611 6.9297 10.7121 6.80843 10.8237C6.68716 10.9353 6.52311 10.9986 6.35162 11C6.18012 11.0013 6.01491 10.9407 5.89155 10.831L2.1915 7.4256Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2792 6.5744C13.4018 6.68729 13.4707 6.84038 13.4707 7C13.4707 7.15962 13.4018 7.31271 13.2792 7.4256L9.57916 10.831C9.4558 10.9407 9.29058 11.0013 9.11908 11C8.94759 10.9986 8.78354 10.9353 8.66227 10.8237C8.54101 10.7121 8.47222 10.5611 8.47073 10.4032C8.46924 10.2454 8.53516 10.0933 8.65431 9.97981L11.8919 7L8.65431 4.02019C8.53516 3.90665 8.46924 3.75459 8.47073 3.59675C8.47222 3.43891 8.54101 3.28793 8.66227 3.17632C8.78354 3.0647 8.94759 3.00139 9.11908 3.00002C9.29058 2.99865 9.4558 3.05933 9.57916 3.16898L13.2792 6.5744Z" fill="white"/>
               </svg>
              </Tooltip>
              <Tooltip title={
                modalToOpen.value.isPush
                  ? t("video.mettre_arriere") 
                  : t("video.mettre_avant")
              }
              >
                {
                isPush ? (
              <span style={{marginRight:"8px"}}  > 
              <Icon path={mdiArrangeBringToFront}
               size={"1.5em"}
               color="#616263"
         onClick={() =>  togglePushVideo("disable-push-video")}
      /></span>
                ) : (
           <span style={{paddingRight:"6px"}}>    
          <Icon path={mdiArrangeSendBackward}
            size={"1.5em"}
            color="#616263"
         onClick={() =>  togglePushVideo("enable-push-video")}
      /></span>
                )}
                
              </Tooltip>
              <Tooltip title={t("video.chronologie")}>
                <TimerOutlinedIcon
                  sx={iconStyle}
                  onClick={() => redirectMenu("chronologieVideo")}
                />
              </Tooltip>
              <Tooltip title={t("video.lien_video")}>
                <InsertLinkOutlinedIcon
                  sx={iconStyle}
                  onClick={() => redirectMenu("showModalExport")}
                />
              </Tooltip>
              <Tooltip title={t("video.delete")}>
                <DeleteIcon
                  sx={iconStyle}
                  onClick={() => redirectMenu("deleteVideoConfirm")}
                />
              </Tooltip>
              <Tooltip
                title={
                  modalToOpen.value.offline
                    ? t("video.online")
                    : t("video.offline")
                }
              >
                {modalToOpen &&
                modalToOpen.value &&
                modalToOpen.value.offline ? (
                  <VisibilityIcon
                    onClick={() => redirectMenu("putVideoOnline")}
                    sx={iconStyle}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => redirectMenu("putVideoOffline")}
                    sx={iconStyle}
                  />
                )}
              </Tooltip>
              <Tooltip title="Questions\Quiz">
                <QuizIcon
                  sx={iconStyle}
                  onClick={() => redirectMenu("responsesVideo")}
                />
              </Tooltip>
              <Tooltip title={t("video.comment_list")}>
                <CommentIcon
                  sx={iconStyle}
                  onClick={() => redirectMenu("listComments")}
                />
              </Tooltip>
              <Tooltip
                title={t("video.audio_description")}
                onMouseEnter={() => {
                  setIsAudioMenuVisible(true);
                  setIsEditMenuVisible(false);
                  setIsSettingsMenuVisible(false);
                  setIsSkinPlayerMenuVisible(false);
                }}
              >
                <DescriptionIcon sx={iconStyle} />
              </Tooltip>
              <Dropdown
                onMouseEnter={() => setIsAudioMenuVisible(true)}
                onMouseLeave={() => setIsAudioMenuVisible(false)}
                overlay={audioDescriptionMenu}
                visible={isAudioMenuVisible}
              >
                <DownOutlined
                  style={{
                    color: "gray",
                    fontSize: "10px",
                    position: "relative",
                    marginRight: "5px",
                    bottom: "4px",
                    left: "-8px",
                  }}
                />
              </Dropdown>
              <Tooltip
                title={t("video.settings")}
                onMouseEnter={() => {
                  setIsSettingsMenuVisible(true);
                  setIsAudioMenuVisible(false);
                  setIsEditMenuVisible(false);
                  setIsSkinPlayerMenuVisible(false);
                }}
              >
                <SettingsIcon sx={iconStyle} />
              </Tooltip>
              <Dropdown
                onMouseEnter={() => {
                  setIsSettingsMenuVisible(true);
                  setIsSkinPlayerMenuVisible(false);
                  setIsAudioMenuVisible(false);
                  setIsEditMenuVisible(false);
                }}
                overlay={settingsMenu}
                visible={isSettingsMenuVisible}
              >
                <DownOutlined
                  style={{
                    color: "gray",
                    fontSize: "10px",
                    position: "relative",
                    marginRight: "5px",
                    bottom: "4px",
                    left: "-8px",
                  }}
                />
              </Dropdown>
              <Tooltip
                title={t("video.skin_player")}
                onMouseEnter={() => {
                  setIsSkinPlayerMenuVisible(true);
                  setIsSettingsMenuVisible(false);
                  setIsAudioMenuVisible(false);
                  setIsEditMenuVisible(false);
                }}
              >
                <VideoSettingsIcon sx={iconStyle} />
              </Tooltip>
              <Dropdown
                onMouseEnter={() => {
                  setIsSkinPlayerMenuVisible(true);
                  setIsSettingsMenuVisible(false);
                  setIsAudioMenuVisible(false);
                  setIsEditMenuVisible(false);
                }}
                overlay={skinPLayerMenu}
                visible={isSkinPlayerMenuVisible}
              >
                <DownOutlined
                  style={{
                    color: "gray",
                    fontSize: "10px",
                    position: "relative",
                    marginRight: "5px",
                    bottom: "4px",
                    left: "-8px",
                  }}
                />
              </Dropdown>
            </div>
          </Drawer>
        )}
    </>
  );
}
