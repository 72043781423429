import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
	setAddGroupActions,
	setFilterGroupeActions,
	getUrlEditor,
	setshowDivsConditionsMedia,
	setIdClicked,
	deleteSubscriptionSuccess,
	ActionError,
	getListVideos,
	getUrlVideos,
	getUrlMedia,
	setTimeVideo,
	ModalToOpen,
	detailsEventsModal,
	ActioniframeKey,
	getTokenSkin
} from "../store/mediasActions";
import { Form } from "antd";
import { GraphQLFetchData } from "./graphQLFetchData";
import { useHistory } from "react-router-dom";
import { GetData, PostData } from "../../APIcalls";
import i18next from "i18next";
import { setMediaId, setVideoId,setTypeVideo } from "../../AddAudioDescription/utils/actions";
import { AddHooks } from "../../addMedia/utils/hooks";
import { setAddMediaActions } from "../../addMedia/store/addMediaActions";

const Hooks = () => {
	const history = useHistory();
	const [defaultChecked, setdefaultChecked] = useState([]);
	const { GetListVideos, createSubGroup, deleteSubGroup } = GraphQLFetchData();
	const [filterIcon, setFilterIcon] = useState(false);
	const [filterIconDate] = useState("");
	const [ShowFilter, SetShowFilter] = useState(false); // state pour afficher le div de fltrage si on clique sur l'icon de filtrage
	const [visible, setVisible] = useState(false);
	const [ExportAPICalled, setExportAPICalled] = useState(false);
	const [opneDownloadModal, setOpenDownloadModal] = useState(false);
	const [opneDetailModal, setOpneDetailModal] = useState(false);
	const [nameVideo, setNameVideo] = useState("");
	const [visibleMetadataModal, setVisibleMetadataModal] = useState(false);
	const [openAudioDescModal, setOpenAudioDescModal] = useState(false);
	const [idVideo, setIdVideo] = useState(null);
	const dispatch = useDispatch();
	const [formAddGroup] = Form.useForm();

	const handlClickSuffix = () => {
		setFilterIcon(true);
		SetShowFilter(!ShowFilter);
	};

	useEffect(() => {
		function goto(event) {
			let noRedirect =
				" .filter_icon , .filter_icon *, .ant-input " +
				", .ant-select-selector * , .ant-select-dropdown * ," +
				" .ant-select-item-option-content , .ant-picker-cell-inner , .ant-picker-dropdown * , " +
				".div_filter_avance * , .div_Filter_global , .div_Filter , .div1_div_Filter * , .div_button_filter , .btn_1";
			if (!event.target.matches(noRedirect)) {
				SetShowFilter(false);
			}
		}
		document.body.addEventListener("click", goto);
	}, []);
	const eventActionList = ["statsPerVideo", "listComments", "chronologieVideo"];
	useEffect(() => {
		window.addEventListener("message", function (event) {
			if (eventActionList.includes(event.data?.info)) window.parent.postMessage(event.data, "*");
			else if (event && event.data) initHelp(event.data);
		});
	}, [ExportAPICalled]);
	const addSubscriptionUsers = async (id, ids_Video) => {
		await GetData(
			"/video-api/video/add-subscriptionUsers/" +
				id +
				"?idsSubscriptionUsers=[" +
				Array.from(ids_Video, ({ id }) => id) +
				"]"
		).then((res) => {
			dispatch(ModalToOpen());
		});
	};

	const getExportYoutubeLink = async (id) => {
		await GetData("/oauth?id=" + id).then((res) => {
			if (res?.data && typeof res?.data !== typeof undefined) window.open(res?.data?.urlAuth);
		});
	};
	const getUrlVideo = async (id) => {
		!ExportAPICalled &&
			(await GetData("/video/video/url-export/" + id).then((res) =>
				dispatch(getUrlVideos(res?.data?.video))
			));
	};

	const getsousTitrageUrl = async (id) => {
		await GetData("/video/video/preview-editor-link/" + id + "?preview=caption&y=bo").then(
			(res) => {
				dispatch(getUrlEditor(res?.data?.link));
			}
		);
	};
	const archiveVideo = async (id) => {
		await GetData("video/archivate/" + id).then((res) => {});
	};
	const pushVideo = async (id, type) => {
		await PostData(`/video/${type}/${id.id}`)
	  };
	const getRichMediaUrl = async (id) => {
		await GetData("/video/video/preview-editor-link/" + id + "?isRm=1&y=bo").then((res) => {
			dispatch(getUrlMedia(res?.data?.link));
		});
	};
	const getMontage = async (id) => {
		await GetData(
			"/video/video/preview-link-by-source-montage-video/" + id + "?isMontage=1&y=bo"
		).then((res) => {
			dispatch(getUrlMedia(res?.data?.link));
		});
	};
	const showModalExport = async (id, visible) => {
		setExportAPICalled(true);
		ExportAPICalled && getUrlVideo(id).then(showModal());
	};

	function initHelp(type) {
		if (typeof type.info !== typeof undefined && type.info !== "sendDataToParent") {
			if (
				type.info !== "addVideo" &&
				type.info !== "showListAction" &&
				type.info !== "moveToNextChapSld"
			) {
				dispatch(setIdClicked(type.value?.id));
				dispatch(ModalToOpen(type.info));
				if (!window.location.pathname.includes("/addMedia")) setIdVideo(type.value?.id);
				if (type.info === "updateLang") {
					i18next.changeLanguage(type.lang);
					localStorage.setItem("lang", type.lang);
				}
				// metadata modal
				if (type.info === "showVideoDetail") {
					setVisibleMetadataModal(true);
					setNameVideo(type.value.title);
				}
				// abonnés modal
				if (type.info === "addSubscribers") {
					// setVisibleAbonneModal(true)
				}
				// modal pour le téléchargement de fichier source et transcodé
				if (type.info === "showModalForDownloadVideos") {
					setOpenDownloadModal(true);
				}

				// modal pour les details d'un video
				if (type.info === "showModalForDetailVideo") {
					setIdVideo(type.value?.row?.id);
					dispatch(ModalToOpen("showModalForDetailVideo"));
				}
			}

			if (type) {
				if (type.info === "addVideo") {
					handleAddMedia();
					window.parent.postMessage({ info: "edit_video", value: "upload" }, "*");
				}
				if(type.info ==="showCadenasModal"){
					setIdVideo(type.value?.row.id);
					dispatch(ModalToOpen("showCadenasModal"));
				}
				if(type.info ==="ChangeToEmptyPassword"){
					dispatch(
						setAddMediaActions({
						  addMediaConstraintsDataNameChange: "passwordInternet",
						  addMediaConstraintsDataValueChange:  "",
				  
						})

					  );    
				}

				if (type.info === "addDescription") {
					dispatch(setVideoId(type.value.id));
					dispatch(setMediaId(type.value.medias[0].id));
					history.push(`/ws-media/addAudioDescription/${type.value.id}/${type.value.medias[0].id}`);
				}
				if (type.info === "editDescription") {
					dispatch(setVideoId(type.value.id));
					dispatch(setMediaId(type.value.medias[0].id));
					history.push(
						`/ws-media/editAudioDescription/${type.value.id}/${type.value.medias[0].id}`
					);
				}
				if (type.info === "deleteDescription") {
					setOpenAudioDescModal(true);
				}
				if (type.info === "editVideo") {
					const typeVideo = type?.type
					history.push("/ws-media/video/" + type.value.fileTitle + "/" + type.value.id + "/edit");
   					//  window.parent.postMessage({ info: "diffusion", value: typeVideo ? typeVideo : "ongoing" }, "*")

				}
				if (type.info === "moveToNextChapSld") {
					dispatch(setTimeVideo(type.value ? type.value[0] : 0));
				}
				if (type.info === "sousTitragesVideo") {
					history.push({
						pathname: `/ws-media/video/${type.value.fileTitle}/${type.value.id}/edit/sous-titrages`,
						label: "2",
					});
					window.parent.postMessage({ info: "edit_video", value: "caption" }, "*");
				}
				if (type.info === "richMediaVideo") {
					history.push({
						pathname: `/ws-media/video/${type.value.fileTitle}/${type.value.id}/edit/rich-media`,
						label: "3",
					});
					window.parent.postMessage({ info: "edit_video", value: "rich_media" }, "*");
				}
				if (type.info === "showModalExport") {
					showModalExport(type.value.id, ExportAPICalled);
				}
				if (type.info === "montageVideo") {
					history.push(`/ws-media/video/${type.value.fileTitle}/${type.value.id}/edit/montage`);
					window.parent.postMessage({ info: "edit_video", value: "montage" }, "*");
				}
				if (type.info === "redirectHomepage") {
					history.push("/ws-media/medias");
					dispatch(ActioniframeKey(Math.random()));
				}
				if (type.info === "changeSource") {
					history.push(
						`/ws-media/addMedia?id=${type.value.id}&mediaid=${type.value.medias[0].id}&changeSource=true&fileName=${type.value.fileTitle}`
					);
				}
				if (type.info === "showListAction") {
					dispatch(ModalToOpen({ type: type.info, value: type.value }));
				}
				if (type.info === "videoUpdatedSuccessfully") {
					dispatch(ModalToOpen({ type: "showListAction", value: type.value }));
				}
				if (type.info === "diffusion" && window?.parent !== window){
				    window.parent.postMessage({info:type.info,value:type.value}, "*");
				}
			}
		}
	}
	let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
	let eventer = window[eventMethod];
	let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
	eventer(messageEvent, function (e) {});

	// /************show Modal **************/////////
	const showModal = () => {
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
		setOpenDownloadModal(false);
		setExportAPICalled(false);
		setOpneDetailModal(false);
		setOpenAudioDescModal(false);
		setdefaultChecked([]);
		dispatch(ModalToOpen());
		dispatch(detailsEventsModal(""));
	};

	// /************ Get list group subscriptions */
	const getMediaList = async () => {
		await GetData("/video/video/get-url-list-video?typeList=ongoing")
			.then((res) => {
				dispatch(getListVideos(res?.data?.url));
			})
			.catch((err) => dispatch({ type: "UNAUTHORIZED" }));
	};
	const getVideosList = async () => {
		await GetListVideos();
	};

	// /**************** ADD Group *********************/////////
	const handleAddMedia = () => {
		history.push("/ws-media/addMedia");
		dispatch(
			setshowDivsConditionsMedia({
				showDivsConditionsName: "addMedia",
				showDivsConditionsValue: true,
			})
		);
	};
	const handleSecondAddMedia = () => {
		dispatch(
			setshowDivsConditionsMedia({
				showDivsConditionsName: "SecondaddMedia",
				showDivsConditionsValue: true,
			})
		);
	};

	const editGroup = () => {
		dispatch(
			setshowDivsConditionsMedia({
				showDivsConditionsName: "editGroup",
				showDivsConditionsValue: true,
			})
		);
	};
	const onResetGroup = () => {
		dispatch(
			setshowDivsConditionsMedia({
				showDivsConditionsName: "addGroup",
				showDivsConditionsValue: false,
			})
		);
		dispatch(
			setshowDivsConditionsMedia({
				showDivsConditionsName: "editGroup",
				showDivsConditionsValue: false,
			})
		);
	};
	// ****************** FILTER GROUPE ************************//
	const handleSearchRow = (event) => {
		if (event.key === "Enter") {
			dispatch(
				setFilterGroupeActions({
					FilterGroupNameChange: "search",
					FilterGroupValueChange: event.target.value,
				})
			);
		}
	};
	const handleChangeSelect = (e) => {
		dispatch(
			setFilterGroupeActions({ FilterGroupNameChange: "parent", FilterGroupValueChange: e })
		);
	};
	const handleChangeRangePicker = (date, dateString) => {
		dispatch(
			setFilterGroupeActions({ FilterGroupNameChange: "date", FilterGroupValueChange: dateString })
		);
	};

	// deleteSubGroup
	const onDeleteGroup = (Id) => {
		deleteSubGroup({
			variables: {
				id: Id,
			},
		})
			.then((res) => {
				dispatch(deleteSubscriptionSuccess(Id));
			})
			.catch((e) => dispatch(ActionError(e)));
	};

	// //****************** Form fonctions ***************//////////
	const onFinishAddMedia = (values) => {
		createSubGroup({
			variables: {
				input: {
					...values,
					ModDate: "2020-01-02T15:04:05Z",
					Uid: "1235",
				},
			},
		});
	};

	const onValueFormUserchangeGroup = (val) => {
		if (val.nameGroup) {
			dispatch(
				setAddGroupActions({
					addGroupConstraintsDataNameChange: "nameGroup",
					addGroupConstraintsDataValueChange: val.nameGroup,
				})
			);
		} else if (val.startDate) {
			dispatch(
				setAddGroupActions({
					addGroupConstraintsDataNameChange: "startDate",
					addGroupConstraintsDataValueChange: val.startDate,
				})
			);
		} else if (val.endDate) {
			dispatch(
				setAddGroupActions({
					addGroupConstraintsDataNameChange: "endDate",
					addGroupConstraintsDataValueChange: val.endDate,
				})
			);
		} else if (val.parent) {
			dispatch(
				setAddGroupActions({
					addGroupConstraintsDataNameChange: "parent",
					addGroupConstraintsDataValueChange: val.parent,
				})
			);
		}
	};
	const disabledsubmitbutton = async (type) => {
		if (type === "disabled") {
			dispatch(
				setshowDivsConditionsMedia({
					showDivsConditionsName: "disablesubmitform",
					showDivsConditionsValue: false,
				})
			);
		} else if (type === "notdisabled") {
			dispatch(
				setshowDivsConditionsMedia({
					showDivsConditionsName: "disablesubmitform",
					showDivsConditionsValue: true,
				})
			);
		}
	};
	const getSkinToken=async ()=>{
		await GetData(`/video-api/video/skin/list`).then((res)=>{
			const tokenList = res?.data?.data.map(item => ({
				label: item.name,
				value: item.token
			}));
			dispatch(getTokenSkin(tokenList))
		}).catch((err)=>err)
	}

	return {
		getVideosList,
		defaultChecked,
		archiveVideo,
		idVideo,
		getMediaList,
		onDeleteGroup,
		handlClickSuffix,
		filterIcon,
		filterIconDate,
		ShowFilter,
		showModal,
		handleCancel,
		visible,
		handleAddMedia,
		handleSearchRow,
		handleChangeSelect,
		handleChangeRangePicker,
		formAddGroup,
		onFinishAddMedia,
		onValueFormUserchangeGroup,
		editGroup,
		disabledsubmitbutton,
		onResetGroup,
		handleSecondAddMedia,
		getUrlEditor,
		getsousTitrageUrl,
		getRichMediaUrl,
		opneDownloadModal,
		getMontage,
		nameVideo,
		visibleMetadataModal,
		getExportYoutubeLink,
		addSubscriptionUsers,
		opneDetailModal,
		openAudioDescModal,
		setIdVideo,
		getSkinToken,
		pushVideo
	};
};
export default Hooks;
