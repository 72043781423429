import { useEffect } from 'react'
import { Row, Col, Card, message } from "antd";
import { useParams } from "react-router-dom";
import CommonHeader from "./components/CommonHeader";
import { useSelector, useDispatch } from 'react-redux'
import { resetState, setAddMediaActions } from '../addMedia/store/addMediaActions'
import { AddHooks } from './utils/hooks';
import { ListForm } from './components/listForm';
import { useTranslation } from "react-i18next";
import { useIsValidResult } from "customfields";
import LoadingScreen from '../Podcast/utils/LoadingScreen';

export const ControleMedia = () => {
    const { t } = useTranslation();
    let { id, filename } = useParams();
    const dispatch = useDispatch()
    const { onFinishAddMedia } = AddHooks();
    const values = useSelector((state) => state.addMediaReducer.AddMediaConstraintsData)
    if (id.includes("?")) { id = id.split("?")[0] }
    const validCustomFields = useIsValidResult(+id)

    const isSuperAdmin = useSelector(
        (state) => state.ReducerUserData.isSuperAdmin
      );

    const handleSaveVideo = () => {
        dispatch({ type: 'SET_UPDATE_CUSTOM_FIELDS' })
        if (validCustomFields && values.title && values.startDate < values.endDate && values.themes.length > 0) {
            onFinishAddMedia(id)
        } else if (!validCustomFields) {
            message.error(`${t("video.invalidCustomFields")}`)
        }else if (!isSuperAdmin && values.themes.length === 0)  {
            message.error(`${t("library_required")}`)
        }else {
            onFinishAddMedia(id)
        }
    }
    useEffect(() => {
        dispatch(setAddMediaActions({ addMediaConstraintsDataNameChange: "fileTitle", addMediaConstraintsDataValueChange: filename }));
    }, [filename])

    const loadingUserData = useSelector((state) => state.ReducerUserData.loading)

    useEffect(() => {
        return () => {
            dispatch(resetState())
        }
    }, [])

    if (loadingUserData) {
        return <div className='center'>
            <LoadingScreen />
        </div>
    }

    return (
        <div>
            <Card className="cardparent">
                <Row gutter={
                    [0, 30]
                }>
                    <Col span={24}
                        style={
                            {
                                padding: "1.3%",
                                paddingTop: "1.3%",
                                paddingBottom: "1.3%",
                                backgroundColor: "rgba(0, 0, 0, 0.04)"
                            }
                        }>
                        <CommonHeader awaitThumbStatus={true} hasIcons={false} cancelBtnLocation={"/"} SubmitAction={handleSaveVideo} id={id} filename={filename} />
                    </Col>
                    <Col span={24}>
                        <ListForm activeComponent="1" filename={filename} id={id} />
                    </Col>
                </Row>
            </Card>
        </div>


    );
};