import axios from "axios";

// window.process.env.
const headers = {
    Authorization: `Bearer ` + localStorage.getItem("authToken"),
    // withCredentials: false,
};

//send access stat
export const SendAccess = async (sessionId,action) => {
    return await axios.post(`https:${
        window.process.env.API_HOST_GLOBAL + "/stat/acces_send"
    }`, {   "EmpStatsQuery" :{ "host_api": "https:"+ window.process.env.API_HOST_GLOBAL , "session_id":sessionId ,"action":action }}, {headers});
};

// get the choices for category select when adding a podcast

export const getSelectChoices = async () => {
    return await axios.get(`${
        window.process.env.API_HOST_GLOBAL + "/podcastCategory/select-choices"
    }`, {headers});
};

// upload a thumbnail for the podcast

export const UploadThumbnail = async (data) => {
    return await axios.post(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/upload/thumb"
    }`, data, {headers});
};

export const uploadSecondThumb = async (data) => {
    return await axios.post(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/upload/thumb"
    }`, data, {headers});
};

// check if a podcast done transcoding or not

export const AretheyDoneTranscoding = async (payload) => {
    return await axios.post(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/podcast/are-they-done-transcoding"
    }`, payload, {headers});
};

// add Podcast to the list

export const PostPodcastData = async (data) => {
    return await axios.post(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/add"
    }`, data, {headers});
};


// select multiple podcasts and delete them

export const multiDelete = async (path) => {
    return axios.delete(`${
        window.process.env.API_HOST_GLOBAL + "/multiDelete/podcasts/"
    }${
        path.join(",")
    }`, {headers});
};
export const multisoftDelete = async (path) => {
    return axios.post(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/archivate-podcasts"
    }`,{ids:path }, {headers});
};

// get each podcast by id to edit or display data

export const Get_Podcast_ById = async (path) => {
    return await axios.get(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/"
    }${path}`, {headers});
};

// get the video of each podcast

export const getVideo = async (path) => {
    return await axios.get(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/get-link-podcast/"
    }${path}`, {
        headers: {
            Authorization: `Bearer ` + localStorage.getItem("authToken")
        }
    });
};

// get SSR links for each podcast

export const GetDataSSR = async (path) => {
    return await axios.get(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/url-export-podcast/"
    }${path}`, {headers});
};

// get Podcast details (title,desc...)

export const GetDetails = async (path) => {
    return await axios.get(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/"
    }${path}`, {headers});
};

// delete a podcast

export const DeletePodcastData = async (path) => {
    return await axios.delete(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/delete/"
    }${path}`, {headers});
};
export const SoftDeletePodcast = async (path) => {
    return await axios.post(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/archivate-podcasts"
    }`,{ids:[path]}, {headers});
};

export const RestoreDeletedPodcast = async (path) => {
    return await axios.post(`${
        window.process.env.API_HOST_GLOBAL + `/podcast/restore/${path}`
    }`,{}, {headers});
};
// update podcast
export const UpdateDataPodcast = async (path, data) => {
    return await axios.post(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/edit/"
    }${path}`, data, {headers});
};

export const uploadThumbnail = async (data) => {
    return await axios.post(`${
        window.process.env.API_HOST_GLOBAL + "/podcast/upload/thumb"
    }`, data, {headers});
};

export const downloadPodcast = async (id) => {
    axios.get(
        `${window.process.env.VIDEO_API}/podcast/download/${id}`, {
        headers: {
            authorization: `Bearer ${
                localStorage.getItem("authToken")
            }`
        }
    }).then(res => {
        if (res?.data?.data?.exist) {
            let link = document.createElement("a")
            link.href = res?.data?.data?.file
            link.click();
        }
    })
}

// Get Podcast Player By ID
export const getPlayerPodcastById = async (id) => {
    return await axios.get(`${
        'https:'+window.process.env.API_HOST_GLOBAL + `/podcast/get-link-podcast/${id}`
    }`, { headers });
};

