import RecentLivesReducer from "./features/RecentLivesSlice";
import RecentPlayListReducer from "./features/RecentPlayListSlice";
import RecentPodcastReducer from "./features/RecentPodcastSlice";
import RecentVideosReducer from "./features/RecentVideosSlice";
import DashboardReducer from "./features/DashboardSlice";
import HeaderReducer from "./features/HeaderSlice";
const DashboardSlices = {
  RecentLives: RecentLivesReducer,
  RecentPlaylist: RecentPlayListReducer,
  RecentPodcast: RecentPodcastReducer,
  RecentVideos: RecentVideosReducer,
  Dashboard: DashboardReducer,
  Header: HeaderReducer,
};

export default DashboardSlices;
