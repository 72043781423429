import { createSlice } from "@reduxjs/toolkit";
import { getDirectsPlannedNumber } from "../actions";
export const HeaderSlice = createSlice({
  name: "Header",
  initialState: {
    HeaderImage: "",
    HeaderNumberDirects: 0,
    action: false,
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getDirectsPlannedNumber.pending]: (state) => {
      state.action = true;
    },
    [getDirectsPlannedNumber.fulfilled]: (state, { payload }) => {
      state.action = false;
      state.HeaderNumberDirects = payload.recordsFiltered;
    },
    [getDirectsPlannedNumber.rejected]: (state, { payload }) => {
      state.action = false;
    },
  },
});
export const {} = HeaderSlice.actions;
export default HeaderSlice.reducer;
