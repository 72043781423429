import React, { useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ModalInputs } from "@empreinte/components.modal-inputs";
import { ModalConfirm } from "@empreinte/components.modal-confirm";
import { useDispatch, useSelector } from "react-redux";
import { CancelView, showModal } from "../../Redux/features/AddEditSlice";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  getPlayListToEdit,
  getPlayListToView,
  deletePlayList,
  playerVideoById,
} from "../../Redux/actions";
import { CurrentChange } from "../../Redux/features/PaginationSlice";
import { Helpers } from "../../../utils/helpers";
function useActionMenu({ record }) {
  const { modules } = useSelector((state) => ({
    ...state.Pagination,
  }));

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { visible, DetailedPlayList } = useSelector((state) => ({
    ...state.addEdit,
  }));
  const detail = t("Profile.details");
  const edit = t("Profile.edit");
  const delet = t("Profile.delete");
  const watch = t("PlayList.watch");
  const {acces_send} =Helpers()

  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [currentPage,setCurrentPage] = useState(1)
  return (
    <div className="playlist-actions">
      <div className="action">
        {modules?.playlist_playlist_show_media_action?.authorized && (
          <div has-permission="playlist_playlist_show_media">
            <Tooltip style={{margin:0}} placement="top" title={watch}>
              <PlayArrowIcon
                color="action"
                fontSize="small"
                className={"icon_action"}
                onClick={() =>
                  {
                   dispatch(playerVideoById({ id: record.row.Id }))}
                  
                  }
              />
            </Tooltip>
          </div>
        )}
        {modules?.playlist_post_edit_action?.authorized && (
          <div has-permission="playlist_post_edit">
            <Tooltip style={{margin:0}} placement="top" title={edit}>
              <ModeEditIcon
                color="action"
                fontSize="small"
                className={"icon_action"}
                onClick={() =>
                  {acces_send("playlist_post_edit_action");
                  dispatch(getPlayListToEdit({ id: record.row.Id }))}
                }
              />
            </Tooltip>
          </div>
        )}{" "}
        {modules?.playlist_get_detail_action?.authorized && (
          <div has-permission="playlist_get_detail">
            <Tooltip style={{margin:0}} placement="top" title={detail}>
              <RemoveRedEyeIcon
                color="action"
                fontSize="small"
                className={"icon_action"}
                onClick={() => {acces_send("playlist_get_detail_action") ;
                dispatch(getPlayListToView({ id: record.row.Id }))}
              }
              />
            </Tooltip>
          </div>
        )}
        {modules?.playlist_delete_playlist_action?.authorized && (
          <div has-permission="playlist_delete_playlist">
            <Tooltip style={{margin:0}} placement="top" title={delet}>
              <DeleteIcon
                color="action"
                fontSize="small"
                className={"icon_action"}
                onClick={() => {acces_send("playlist_delete_playlist_action");
                 setConfirmationDelete(true)}}
              />
            </Tooltip>
          </div>
        )}
      </div>
      {confirmationDelete ? (
        <div className="confirmation-playlist-delete">
          <ModalConfirm
            // sx={{height:"52px"}}
            className="blacker"
            open={confirmationDelete}
            fullWidth
            onClose={() => setConfirmationDelete(false)}
            title={t("PlayList.DeleteConfirmation")}
            content={t("PlayList.DeleteConfirmationContent")}
            CancelBtnName={t("PlayList.no")}
            onCancel={() => setConfirmationDelete(false)}
            ConfirmBtnName={t("PlayList.yes")}
            onConfirm={() => {
              dispatch(CurrentChange(1))
              setCurrentPage(1);
              dispatch(deletePlayList({ id: record?.row?.Id }));
              setConfirmationDelete(false);
            }}
          />
        </div>
      ) : (
        <></>
      )}

      <ModalInputs
        className="modal-playlist-details"
        open={visible}
        dividers
        title={`${t("PlayList.modaledittitle")} ${DetailedPlayList?.playlist?.title}`}
        onClose={() => dispatch(CancelView({}))}
        CancelBtnName={t("Profile.close")}
        onCancel={() => dispatch(CancelView({}))}
        children={
          <div className="playlist-details-modal">
            <div className="playlist-details-modal-container">
              <span className="playlist-details-modal-title">{t("PlayList.id")}</span>
              <span className="playlist-details-modal-value">
                {DetailedPlayList?.playlist?.id ? DetailedPlayList?.playlist?.id : "--"}
              </span>
            </div>
            <div className="playlist-details-modal-container">
              <span className="playlist-details-modal-title">
                {t("PlayList.title")}
              </span>
              <span className="playlist-details-modal-value">
                {DetailedPlayList?.playlist?.title ? DetailedPlayList?.playlist?.title : "--"}
              </span>
            </div>
            <div className="playlist-details-modal-container">
              <span className="playlist-details-modal-title">
                {t("PlayList.Durationofbroadcast")}
              </span>
              <span className="playlist-details-modal-value">
                {DetailedPlayList?.playlist?.maxDuration
                  ? DetailedPlayList?.playlist?.maxDuration
                  : "--"}
              </span>
            </div>
            <div className="playlist-details-modal-container">
              <span className="playlist-details-modal-title">
                {t("PlayList.Readinloop")}
              </span>
              <span className="playlist-details-modal-value">
                {DetailedPlayList?.playlist?.loopp ? (
                  <CheckCircleRoundedIcon className="vert" />
                ) : (
                  <CancelIcon className="rouge" />
                )}
              </span>
            </div>
            <div className="playlist-details-modal-container">
              <span className="playlist-details-modal-title">
                {t("PlayList.RespectedLordreParameterized")}
              </span>
              <span className="playlist-details-modal-value">
                {DetailedPlayList?.playlist?.autoSort ? (
                  <CheckCircleRoundedIcon className="vert" />
                ) : (
                  <CancelIcon className="rouge" />
                )}
              </span>
            </div>
            <div className="playlist-details-modal-container">
              <span className="playlist-details-modal-title">
                {t("PlayList.url_playlist_permalien")}
              </span>
              <span className="playlist-details-modal-value">
                {DetailedPlayList?.url_playlist_permalien
                  ? DetailedPlayList?.url_playlist_permalien
                  : "--"}
              </span>
            </div>
            <div className="playlist-details-modal-container">
              <span className="playlist-details-modal-title">
                {t("PlayList.url_playlist_embed")}
              </span>
              <span className="playlist-details-modal-value">
                {DetailedPlayList?.url_playlist_embed
                  ? DetailedPlayList?.url_playlist_embed
                  : "--"}
              </span>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default useActionMenu;
