import {combineReducers} from 'redux'
// import {GroupeReducer} from "../MenuProfil/group/store/groupReducer";
// import {IPReducer} from "../MenuProfil/ip/store/ipReducer";
 import {MediaReducer} from "../Medias/store/mediaReducer";
 import { addMediaReducer } from '../addMedia/store/addMediaReducer';
 import ReducerGetPodcastData from "../Podcast/utils/reducer";
import ReducerUserData from "../userData/reducer"
import reducerAddAudioDescription from '../AddAudioDescription/utils/reducer';
import slices from "../PlayList/Redux/store"
import DashboardSlices from "../Dashboard/Redux/store"
export const rootReducer = combineReducers({
       reducerAddAudioDescription,
       MediaReducer,
       addMediaReducer,
       ReducerGetPodcastData,
       ReducerUserData,
       ...Object.fromEntries(
              Object.entries(slices).map(([key, slice]) => [key, slice])
            ),
       ...Object.fromEntries(
              Object.entries(DashboardSlices).map(([key, slice]) => [key, slice])
            ),
})

