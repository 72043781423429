import { AddMedia } from "./addMediaConstants";

const { AddMediaConstraintsData } = AddMedia();

const addMediaINITIALSTATE = {
    AddMediaConstraintsData: AddMediaConstraintsData(),
    externalData: {},
    contributoList: [],
    themes: [],
    pendingVideo: [],
    previewLink: "",
    visible: false,
    storyboard: "",
    linkVideo: "",
    subsGroups: "",
    idvideo: null,
    thumbs: {},
    thumbsscreen: {},
    uploadenter: false,
    visibledelete: false,
    visibleDeleteMultiple: false,
    filename: "",
    metaData: {},
    thumbstheme: {},
    showthemes: [],
    loading: false,
    loadingBtn: false,
    thumbnailPathloaded: false,
    errorForm: false,
    catcherros: {
        value: false,
        message: ""
    },
    videoRecords: 0,
    automatic_add: false,
    idVideoAdded: "",
    update: 0,
    redirectDeletePendingVideo: false,
    clickedTab: 0,
    loadingVideoData: true,
    videoLinkLoading: true,
};

export const addMediaReducer = (state = addMediaINITIALSTATE, action) => {
    switch (action.type) {
        case "SET_UPDATE_CUSTOM_FIELDS":
            return {
                ...state,
                update: action.payload === 0 ? action.payload : state.update + 1
            }
        case "SET_CLICKED_TAB":
            return {
                ...state,
                clickedTab: action.payload
            }
        case "SET_LOADING_BTN":
            return {
                ...state,
                loadingBtn: action.payload
            }
        case "SET_AddMEDIAActions":
            const { addMediaConstraintsDataNameChange, addMediaConstraintsDataValueChange } = action.payload;


            return {
                ...state,
                AddMediaConstraintsData: {
                    ...state.AddMediaConstraintsData,
                    [addMediaConstraintsDataNameChange]: addMediaConstraintsDataValueChange
                }
            };

        case "SET_catchError":
            return {
                ...state,
                catcherros: action.payload
            };
        case "SET_Loading_Delete":
            return {
                ...state,
                redirectDeletePendingVideo: action.payload
            }

        case "SET_ErrorForm":

            return {
                ...state,
                errorForm: action.payload
            };
        case "SET_themesShow":
            return {
                ...state,
                showthemes: action.payload
            };
        // variable to disable button of add form if the thumbnails don't exist
        case "SET_PathThumbnailExist":
            return {
                ...state,
                thumbnailPathloaded: action.payload
            };
        case "SET_ID_VIDEO_ADDED":
            return {
                ...state,
                idVideoAdded: action.payload
            };
        case "SET_loading":
            return {
                ...state,
                loading: action.payload
            };
        case "SET_thumbsthemes":
            return {
                ...state,
                thumbstheme: action.payload
            };

        case "SET_screenthumbs":
            return {
                ...state,
                thumbsscreen: action.payload
            };
        case "SET_thumbs":
            return {
                ...state,
                thumbs: action.payload
            };
        case "SET_MetadataVideo":
            return {
                ...state,
                metaData: action.payload
            };
        case "SET_ExternalvideoData":
            return {
                ...state,
                externalData: action.payload
            };
        case "SET_SubsGroups":
            return {
                ...state,
                subsGroups: action.payload
            };
        case "SET_VideoFile":

            return {
                ...state,
                linkVideo: action.payload
            };
        case "SET_videoname":
            return {
                ...state,
                filename: action.payload
            };
        case "SET_StoryboardUrl":
            return {
                ...state,
                storyboard: action.payload
            };
        case "SET_PendingVideoData":
            return {
                ...state,
                pendingVideo: action.payload
            };
        case "GET_VIDEOS_RECORD":
            return {
                ...state,
                videoRecords: action.payload
            }
        case "SET_PreviewLink":
            return {
                ...state,
                previewLink: action.payload
            };
        case "SET_ModalVisible":
            return {
                ...state,
                visible: action.payload
            };
        case "SET_deleteModalVisible":
            return {
                ...state,
                visibledelete: action.payload
            };
        case "SET_deleteMultipleModalVisible":
            return {
                ...state,
                visibleDeleteMultiple: action.payload
            };
        case "SET_ContributorData":
            return {
                ...state,
                contributoList: action.payload
            };
        case "SET_Themes":
            return {
                ...state,
                themes: action.payload
            };
        case "SET_UPLOADSTATE":
            return {
                ...state,
                uploadenter: action.payload
            };

        case "SET_AUTOMATIC_ADD":
            return {
                ...state,
                automatic_add: action.payload
            }
        case "SET_VIDEO_DATA_LOADING":
            return {
                ...state,
                loadingVideoData: action.payload
            }
        case "SET_VIDEO_LINK_LOADING":
            return {
                ...state,
                videoLinkLoading: action.payload
            }
        case "RESET_STATE":
            return addMediaINITIALSTATE
        default:
            {
                return state;
            }
    }
};
