import { createSlice } from "@reduxjs/toolkit";
import { getVideoNumberForStat, getVideos } from "../actions";
export const RecentVideoSlice = createSlice({
  name: "RecentVideo",
  initialState: {
    name: "Vidéos",
    RecentVideoData: {},
    totalRecentVideoNumber: 0,
    action: false,
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getVideoNumberForStat.pending]: (state) => {
      state.action = true;
    },
    [getVideoNumberForStat.fulfilled]: (state, { payload }) => {
      state.action = false;
      state.totalRecentVideoNumber = payload.data.NbVideos;
    },
    [getVideoNumberForStat.rejected]: (state, { payload }) => {
      state.action = false;
    },
    [getVideos.pending]: (state) => {
      state.action = true;
    },
    [getVideos.fulfilled]: (state, { payload }) => {
      state.action = false;
      state.RecentVideoData = payload.data.videos;
    },
    [getVideos.rejected]: (state, { payload }) => {
      state.action = false;
    },
  },
});
export const {} = RecentVideoSlice.actions;
export default RecentVideoSlice.reducer;
