import React, { useEffect , useState} from 'react'
import { Divider,Button, Modal,Row, Col, Image } from "antd";
import Hooks from '../utils/hooks';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { GetData } from '../../APIcalls';
import { getVideoDetails } from "../store/mediasActions";
import { setmetadataVideo } from '../../addMedia/store/addMediaActions';
import { AddHooks } from '../../addMedia/utils/hooks';

const VideoDetails = ({ idVideo}) => {
    const { handleCancel } = Hooks();
    const {secondsToHms} = AddHooks()
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const modalToOpen = useAppSelector((state) => state.MediaReducer.modalToOpen);
    const VideoDetail = useAppSelector((state) => state.MediaReducer.VideoDetails);
    const metadata = useAppSelector((state) => state.addMediaReducer.metaData);
    const getMetadataInfo = async(Id) => {
      await GetData(`/video/video/${Id}/get-meta-data`).then((res) => {
          dispatch(setmetadataVideo(res.data?.metaData?.data?.contentJson))
    
        });
      }
    const getVideoByOne = async (id) => {
      await GetData("/video/" + id).then((res) => {
        dispatch(getVideoDetails(res?.data))
      })
    }

    useEffect(() => {
      idVideo && getVideoByOne(idVideo)
      idVideo && getMetadataInfo(idVideo)
    }, [idVideo])
  return (
    <Modal
        footer={[
            <Button key="back" onClick={handleCancel}>
              {t('close')}
            </Button>
        ]}
        onCancel={handleCancel}
        title={`${t("video.details_video")}: ${VideoDetail.title}`}
        open={modalToOpen === "showModalForDetailVideo"}>
          <Row>
          <Col span={23}>
            <Image width={200} src={VideoDetail.logo}/>
          </Col>
        </Row>
        <Row>
          <Col span={23} style={{marginTop: "14px"}}>
            <b>{t("encodeur.identifier")}</b>
          </Col>
        </Row>
        {VideoDetail.id}
        <Divider />
        <Row>
          <Col span={23}>
            <b>{t("video.title")}</b>
          </Col>
          {VideoDetail.title}
        </Row>
        <Divider />
        <Row>
          <Col span={23}>
            <b>{t("Podcast.Description")}</b>
          </Col>
          <Col span={24}>
            <span>
              {VideoDetail.description?.length > "200" ? `${VideoDetail.description.slice(0, 200)}...` : "--" }
            </span>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={23}>
            <b>{VideoDetail.themes?.length > 1 ? t("video.themes") : t("Podcast.Theme")}</b>
          </Col>
          <Col span={24}>
            {VideoDetail.themes?.length ? (
              VideoDetail.themes.map((item) => {
                return (
                  <div key={item.id}>{item.title}<br /></div>
                );
              })
            ) : (
              <span>--</span>
            )}
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={23}>
            <b>{t("video.duration")}</b>
          </Col>
          {secondsToHms(metadata?.format?.duration)}
        </Row>
    </Modal>
  )
}

export default VideoDetails
