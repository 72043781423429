import {SET_MEDIA_ID,SET_VIDEO_ID,SET_TYPE} from './types'

export function setMediaId(e){
    return{
        type: SET_MEDIA_ID,
        payload:e,
    }
}
export function setVideoId(e){
    return{
        type: SET_VIDEO_ID,
        payload:e,
    }
}
export function setTypeVideo(e){
    return{
        type:SET_TYPE,
        payload:e
    }
}
