import { useDispatch } from "react-redux";
import { ModalToOpen } from "../store/mediasActions";
export const EventHandler = () => {
  const dispatch = useDispatch();
  const EventListener = () => {
    window.addEventListener(
      "message",
      function (event) {
        if (window.location !== window.parent.location) {
          if (event.data.info === "responsesVideo") {
            window.parent.postMessage(event.data, "*");
          }
        }

        if (event.data.info === "showListAction") {
          dispatch(
            ModalToOpen({ type: event.data.info, value: event.data.value })
          );
        }
      },
      false
    );
  };
  return { EventListener };
};
