import { TYPES } from "./types";

const initState = {
  loading: true,
  userData: {},
  roles: [],
  modules: {},
  unauthorizedUser: true,
  isSuperAdmin: false,
};
const ReducerUserData = (state = initState, action) => {
  switch (action.type) {
    case TYPES.GET_USER_DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_USER_DATA:
      return {
        ...state,
        loading: false,
        unauthorizedUser: false,
        userData: action.payload?.userData?.user,
        roles: action.payload?.userData?.roles,
        modules: action.payload?.userData?.modules,
      };
    case TYPES.GET_USER_DATA_FAILED:
      return {
        ...state,
        loading: false,
        unauthorizedUser: true,
      };
    case TYPES.USER_SUPER_ADMIN:
      return {
        ...state,
        isSuperAdmin: true,
      };
    default:
      return state;
  }
};
export default ReducerUserData;
