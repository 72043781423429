import { Row, Col, Card, message } from "antd";
import "../addMedia.scss";
import { Formeditvideo } from "./formeditvideo";
import { useParams, Route, useLocation } from "react-router-dom";
import RichMedia from "../../Medias/Components/RichMedia";
import SousTitrage from "../../Medias/Components/SousTitrage";
import Montage from "../../Medias/Components/Montage";
import { useDispatch, useSelector } from "react-redux";
import CommonHeader from "../components/CommonHeader";
import { AddHooks } from "../utils/hooks";
import { useIsValidResult } from "customfields";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import axios from "axios";
import { resetState } from "../store/addMediaActions";
import LoadingScreen from "../../Podcast/utils/LoadingScreen";

export const EditVideo = () => {
  const { getVideoDataById,
    handleChangeDate,
    editupload,
    getVideoLink,
  } = AddHooks();
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { filename, id } = useParams();
  const validCustomFields = useIsValidResult(+id);
  const customFieldsAPI = useSelector(
    (state) => state.MediaReducer.customFieldsAPI
  );
  const cancelToken = axios.CancelToken.source();
  const clientName = useSelector((state) => state.MediaReducer.clientName);
  const values = useSelector((state) => state.addMediaReducer.AddMediaConstraintsData)
  const options = [];
  const location = useLocation();
  const currentLocation = location.pathname.split("/");

  const isEdit = currentLocation[currentLocation.length - 1] === "edit";

  for (let i = 0; i < 100; i++) {
    const value = `${i.toString(36)}${i}`;
    options.push({
      label: value,
      value,
      disabled: i === 10,
    });
  }

  const loadingUserData = useSelector((state) => state.ReducerUserData.loading)
  const loadingDataVideo = useSelector((state) => state.addMediaReducer.loadingVideoData)
  const videoLinkLoading = useSelector((state) => state.addMediaReducer.videoLinkLoading)
  const isSuperAdmin = useSelector(
    (state) => state.ReducerUserData.isSuperAdmin
  );

  const edit = () => {
    dispatch({ type: 'SET_UPDATE_CUSTOM_FIELDS' })
    if (isSuperAdmin || (validCustomFields && values.themes.length > 0 && values.title && values.startDate < values.endDate)) {
      editupload(id,values.hashSource)
    } else if (!validCustomFields) {
      message.error(`${t("video.invalidCustomFields")}`)
    }else if (!isSuperAdmin && values.themes.length === 0)  {
      message.error(`${t("library_required")}`)
    } else if (!values.title || !(values.startDate < values.endDate)) {
      editupload(id)
      dispatch({ type: 'SET_LOADING_BTN', payload: false })
    }
  };


  useEffect(() => {
    handleChangeDate("", "startDate");
    handleChangeDate("", "endDate");
    getVideoDataById(id, filename, "edit", cancelToken);
    getVideoLink(id);
  }, [id, filename])

  useEffect(() => {
    return () => {
      dispatch(resetState())
      cancelToken.cancel("metadata request canceled");
    }
  }, [])
  if (loadingUserData || loadingDataVideo || videoLinkLoading) {
    return <div className="center">
      < LoadingScreen />
    </div>
  }
  return (
    <div>
      <Card className="cardparent">
        <Row gutter={[0, 30]}>
          <Col
            span={24}
            style={{
              padding: "1.3%",
              paddingTop: "1.3%",
              paddingBottom: "1.3%",
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            }}
          >
            <CommonHeader
              SubmitAction={edit}
              editCancelpath={"/ws-media"}
              awaitThumbStatus={false}
              cancelBtnLocation={"/ws-media"}
              hasIcons={true}
              id={id}
              filename={filename}
            />
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Route
                  exact
                  path="/ws-media/video/:filename/:id/edit/rich-media"
                  component={RichMedia}
                />
                <Route
                  exact
                  path="/ws-media/video/:filename/:id/edit/sous-titrages"
                  component={SousTitrage}
                />
                <Route
                  exact
                  path="/ws-media/video/:filename/:id/edit/montage"
                  component={Montage}
                />
                <Route
                  exact
                  path="/ws-media/video/:filename/:id/edit"
                  render={() => <Formeditvideo />}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {clientName && customFieldsAPI && isEdit && <Card></Card>}
      </Card>
    </div>
  );
};
