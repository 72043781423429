import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import Skeleton from "@mui/joy/Skeleton";
import Typography from "@mui/joy/Typography";

const skeleton = () => {
	return (
		<Card variant="outlined" sx={{ display: "flex", gap: 2 }}>
			<AspectRatio ratio="16/9">
				<Skeleton variant="overlay" height={400}>
					<img
						alt=""
						src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
						height={300}
					/>
				</Skeleton>
			</AspectRatio>
		</Card>
	);
};

export default skeleton;
