import { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import FileUpload from "./FileUpload";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { CategorieSelect, LanguageSelect } from "./Select";
import { useTranslation } from "react-i18next";
import "../../../index.css";
import EditToggle from "./EditToggle";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "@empreinte/components.checkbox";
import {
  GetSelectChoices,
  cleanDataById,
  cleanPodcastItemDataById,
  cleanPodcastItemData,
  cleanPodcastitems,
  PodcastConditionAdd,
  cleanSrc,
  cleanPodcastThemes,
} from "../../utils/action";
import { getUserData } from "../../../userData/userDataActions";
import { CustomFields } from "customfields";
import { TextArea } from "@empreinte/components.text-area";
import { InputText } from "@empreinte/components.input-text";
import { Header } from "@empreinte/components.header";
import { DatePickers } from "@empreinte/components.date-pickers";
import { SelectRadio } from "@empreinte/components.select-radio";
import { InputTags } from "@empreinte/components.input-tags";
import "../../Pages/podcast.scss";
import Hooks from "../../utils/hooks";

const EditPodcast = () => {
  const values = useSelector(
    (state) => state.ReducerGetPodcastData.AddPodcastItems
  );

  const disableBtn = useSelector(
    (state) => state.ReducerGetPodcastData.PodcastCondition.disableSubmitForm
  );

  const modules = useSelector((state) => state.ReducerUserData.modules);
  const isSuperAdmin = useSelector(
    (state) => state.ReducerUserData.isSuperAdmin
  );
  const customFieldsAPI = useSelector(
    (state) => state.MediaReducer.customFieldsAPI
  );
  const clientName = useSelector((state) => state.MediaReducer.clientName);
  const { params } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleUpdatePodcast, onDisableSubmit, PodValues } = Hooks();
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const [update, setUpdate] = useState(0);
  const [valueStart, setValueStart] = useState(values.StartDate);
  const [valueEnd, setValueEnd] = useState(values.EndDate);
  const editInputRef = useRef(null);
  const [isValid, setisValid] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false)

  let convertBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };
  useEffect(() => {
    if(values?.Title?.length){
      setDisabledBtn(false)
    }else {
      setDisabledBtn(true)
    }
  }, [values?.Title, disabledBtn])
  useEffect(() => {
    dispatch(GetSelectChoices());
  }, [dispatch]);
  useEffect(() => {
    if (valueEnd < valueStart) {
      setisValid(false);
    } else {
      setisValid(true);
    }
  }, [valueStart, valueEnd]);


  useEffect(() => {
    if (values?.Keywords?.length && typeof values?.Keywords === "string")
      setTags(values?.Keywords?.split(","));
  }, [values?.Keywords]);

  const handleChangeForm = (e) => {
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: e.target.name,
        addPodcastDataValueChange: e.target.value,
      })
    );
  };
  const handleChecksForm = (e) => {
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: e.target.name,
        addPodcastDataValueChange: convertBoolean(e.target.checked),
      })
    );
  };

  const handleRadioForm = (e) => {
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: e.target.name,
        addPodcastDataValueChange: convertBoolean(e.target.value),
      })
    );
  };

  const handleStartDateChange = (newStartDate) => {
    setValueStart(newStartDate);
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "StartDate",
        addPodcastDataValueChange: newStartDate,
      })
    );
  };

  const handleEndDateChange = (newEndDate) => {
    setValueEnd(newEndDate);
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "EndDate",
        addPodcastDataValueChange: newEndDate,
      })
    );
  };

  const onRevertMainPage = () => {
    dispatch(cleanSrc());
    dispatch(cleanDataById());
    dispatch(cleanPodcastItemDataById());
    dispatch(cleanPodcastItemData());
    dispatch(cleanPodcastitems());
    dispatch(cleanPodcastThemes());
    history.push("/ws-media/podcasts");
  };

  const handleEditInputConfirm = (e) => {
    e.preventDefault();
    const tagValue = editInputRef.current.value.trim();
    if (tags?.includes(tagValue) || tagValue === " " || tagValue === "") return;
    const newTags = [...tags, tagValue];
    setTags(newTags);
    const tagsString = newTags.join(",");
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Keywords",
        addPodcastDataValueChange: tagsString,
      })
    );
    editInputRef.current.value = "";
  };

  const handleTagDelete = (value) => {
    const newtags = tags.filter((val) => val !== value);
    setTags(newtags);
    dispatch(
      PodcastConditionAdd({
        addPodcastDataNameChange: "Keywords",
        addPodcastDataValueChange: newtags.join(","),
      })
    );
  };

  return (
    <div className="pod-header-container">
      <Header
        thirdBtnTitle={t("Profile.register")}
        secondBtnTitle={t("Podcast.add-edit.cancel")}
        CancelBtnAction={onRevertMainPage}
        arrowBackAction={() => {
          history.goBack();
          dispatch(cleanSrc());
          dispatch(cleanDataById());
          dispatch(cleanPodcastItemDataById());
          dispatch(cleanPodcastItemData());
          dispatch(cleanPodcastitems());
          dispatch(cleanPodcastThemes());
        }}
        title={t("Podcast.add-edit.Edit the podcast")}
        onMainPage={false}
        hasPermission={true}
        addBtnAction={() => {
          dispatch(handleUpdatePodcast);
          dispatch(cleanSrc());
          dispatch(cleanDataById());
          dispatch(cleanPodcastItemDataById());
          dispatch(cleanPodcastItemData());
          dispatch(cleanPodcastitems());
          dispatch(cleanPodcastThemes());
        }}
        isDisabled={disabledBtn}
      />

      <div className="edit_podcast_container">
        <div className="edit_vignette">
          <div className="file_upload_container">
            <FileUpload
              fileURL={values?.Vignette}
              content={t("Podcast.add-edit.CHOOSE A THUMB")}
            />
          </div>
        </div>
        <span className="maximum_upload_size">
          {t("Podcast.add-edit.The maximum thumbnail size is 2MB.")}
        </span>
        <span className="acceptedDimenssion">
          <div> {t("Dimensions accepted:")}</div>
          <span>480p (SD) : 720 x 480</span>
          <span>720p (HD) : 1280 x 720</span>
          <span>1080p (HD) : 1920 x 1080</span>
        </span>
        <div className="upload_file_inputs">
          <Box
            sx={{
              width: "100%",

              maxWidth: "100%",
              paddingBottom: "20px",
            }}
          >
            <InputText
              style={{ width: "100%" }}
              fullWidth
              required
              name="Title"
              labelInput={t("Podcast.Title")}
              placeholder={t("Podcast.Title")}
              handleChange={handleChangeForm}
              value={values?.Title}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              paddingBottom: "30px",
            }}
          >
            <TextArea
              className="podcast-text-area"
              rows={15}
              maxRows={25}
              labelInput={t("Podcast.Description")}
              id="fullWidth"
              name="Description"
              placeholder={t("Podcast.Description")}
              handleChange={handleChangeForm}
              value={values?.Description}
            />
          </Box>
        </div>
        {(modules?.podcast_form_date || isSuperAdmin) && (
          <div
            has-permission={"podcast_form_date"}
            className="podcast-date-picker-edit"
          >
            <DatePickers
              className="podcast-date-picker1"
              inputFormat="DD/MM/YYYY"
              labelTop={t("Podcast.add-edit.Start date")}
              openTo="year"
              views={["year", "month", "day"]}
              value={values?.StartDate}
              maxDate={new Date(valueEnd)}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} name="valueStart"/>}
            />
            <DatePickers
              className="podcast-date-picker2"
              inputFormat="DD/MM/YYYY"
              labelTop={t("Podcast.add-edit.End Date")}
              openTo="year"
              views={["year", "month", "day"]}
              value={values?.EndDate}
              minDate={new Date(valueStart)}
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} name="EndDate"/>}
              
            />
          </div>
        )}
        <div
          className="new-fields-style-podcast"
          style={{ paddingTop: "20px" }}
        >
          <InputText
            style={{ width: "100%" }}
            fullWidth
            labelInput={t("Podcast.add-edit.Editor")}
            handleChange={handleChangeForm}
            value={values?.Editor}
            name="Editor"
            placeholder={`${t("Podcast.add-edit.Editor")}`}
          />

          <InputText
            style={{ width: "100%" }}
            fullWidth
            labelInput={t("Podcast.add-edit.ArtistAuthor")}
            handleChange={handleChangeForm}
            value={values?.Author}
            name="Author"
            placeholder={`${t("Podcast.add-edit.ArtistAuthor")}`}
          />
        </div>
        <div className="new-fields-style-podcast">
          <InputText
            style={{ width: "100%" }}
            fullWidth
            labelInput={t("Podcast.add-edit.Copyright")}
            handleChange={handleChangeForm}
            value={values?.Copyright}
            name="Copyright"
            placeholder={`${t("Podcast.add-edit.Copyright")}`}
          />
          <InputText
            style={{ width: "100%" }}
            fullWidth
            labelInput={t("Podcast.add-edit.EncodedBy")}
            handleChange={handleChangeForm}
            value={values?.Encoded_By}
            name="Encoded_By"
            placeholder={`${t("Podcast.add-edit.EncodedBy")}`}
          />
        </div>
        <InputTags
          name="Keywords"
          label={t("video.keywords")}
          tags={tags}
          className="input-tags-podcast"
          handleOnSubmit={handleEditInputConfirm}
          onBlur={handleEditInputConfirm}
          handleDelete={handleTagDelete}
          tagRef={editInputRef}
          value
          placeholder={t("Podcast.add-edit.Keywords")}
        />

        <div className="select_options">
          {(modules?.podcast_form_langue || isSuperAdmin) && (
            <div has-permission="podcast_form_langue">
              <LanguageSelect />
            </div>
          )}
          <div className="contenu_container_Edit">
            {(modules?.podcast_form_is_accessible || isSuperAdmin) && (
              <div
                has-permission="podcast_form_is_accessible"
                className="checkboxes"
              >
                <Checkbox
                  name="IsAccessible"
                  label={t(
                    "Podcast.add-edit.Make the podcast visible on the internet"
                  )}
                  checked={values?.IsAccessible ? values?.IsAccessible : false}
                  handleChange={handleChecksForm}
                />
              </div>
            )}
            {(modules?.podcast_form_explicit || isSuperAdmin) && (
              <div
                has-permission="podcast_form_explicit"
                className="contenu_container"
              >
                <h2 style={{ marginTop: "7px" }}>
                  {t(
                    "Podcast.add-edit.Does the podcast contain explicit content?"
                  )}
                </h2>
                <div className="radio_btn_container">
                  <SelectRadio
                    name="Explicite"
                    radioElementClass="radioButtons"
                    value={values?.Explicite}
                    onChange={handleRadioForm}
                    datalist={[
                      {
                        label: t("Podcast.add-edit.No"),
                        value: "false",
                      },
                      {
                        label: t("Podcast.add-edit.Yes"),
                        value: "true",
                      },
                    ]}
                  />
                </div>
              </div>
            )}
          </div>
          <div
                className="contenu_container"
              >
                <h2 style={{ marginTop: "7px" }}>
                  {t(
                    "Podcast.add-edit.podcast_downloadable"
                  )}
                </h2>
                <div className="radio_btn_container">
                  <SelectRadio
                    name="IsDownloadable"
                    radioElementClass="radioButtons"
                    value={values?.IsDownloadable}
                    onChange={handleRadioForm}
                    datalist={[
                      {
                        label: t("Podcast.add-edit.No"),
                        value: "false",
                      },
                      {
                        label: t("Podcast.add-edit.Yes"),
                        value: "true",
                      },
                    ]}
                  />
                </div>
              </div>

          {(modules?.podcast_form_category || isSuperAdmin) && (
            <div has-permission="podcast_form_category">
              <CategorieSelect />
            </div>
          )}
        </div>
        <div className="tgl_container">
          <EditToggle />
        </div>
        {customFieldsAPI && (
          <CustomFields
            apiUrl={customFieldsAPI}
            interface="Podcast"
            token={localStorage.getItem("authToken")}
            itemId={+params}
            language={localStorage.getItem("i18nextLng")}
            update={update}
          />
        )}
      </div>
    </div>
  );
};

export default EditPodcast;
