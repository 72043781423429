import {MediaList} from "./mediaConstants";

const {showDivsConditionsMedia, paginationPropsGroup, AddMediaConstraintsData, FilterGroupe} = MediaList();

const GroupListINITIALSTATE = {
    showDivsConditionsMedia: showDivsConditionsMedia(),
    paginationPropsGroup: paginationPropsGroup(),
    AddMediaConstraintsData: AddMediaConstraintsData(),
    filterGroupe: FilterGroupe(),
    listGroup: {
        edges: [],
        totalCount: 0
    },
    listMedia: {},
    listVideos: "",
    urlVideo: {},
    iframeKey:1,
    urlEditor: "",
    urlMedia: "",
    timevideo: 0,
    idRow: null,
    youtubeLink: '',
    unautorizedMedia: true,
    mediaLoading: true,
    modalToOpen: '',
    subscriptionList: [],
    metaData: {},
    eventModalOpen:"",
    clientName: window.process.env.CLIENT_NAME? window.process.env.CLIENT_NAME : "",
    customFieldsAPI: window.process.env.CUSTOM_FIELD_API? window.process.env.CUSTOM_FIELD_API : "",
    VideoDetails: [],
    entities:'',
    tokenList: []
};

export const MediaReducer = (state = GroupListINITIALSTATE, action) => {
    switch (action.type) {
        case "SET_detail_event_To_Open":
            return {
                ...state,
                eventModalOpen: action.payload
            };
        case "SET_FilterGroup":
            const {FilterGroupNameChange, FilterGroupValueChange} = action.payload;
            return {
                ...state,
                filterGroupe: {
                    ...state.filterGroupe,
                    [FilterGroupNameChange]: FilterGroupValueChange
                }
            };
        case "SET_MetadataVideo":
            return {
                ...state,
                metadata: action.payload
            };
        case "SET_SubscriptionList":
            return {
                ...state,
                subscriptionList: action.payload
            };
        case "SET_Modal_To_Open":
            return {
                ...state,
                modalToOpen: action.payload
            };
        case "SET_urlEditor":
            return {
                ...state,
                urlEditor: action.payload
            };
        case "SET_LINK_EXPORT":
            return {
                ...state,
                youtubeLink: action.payload
            };
        case "SET_urlVideos":
            return {
                ...state,
                urlVideo: action.payload
            };
        case "SET_videotime":
            return {
                ...state,
                timevideo: action.payload
            };

        case "SET_ID":
            return {
                ...state,
                idRow: action.payload
            };
        case "SET_urlMedia":
            return {
                ...state,
                urlMedia: action.payload
            };
        case "SET_ListMedias":
            return {
                ...state,
                listMedia: action.payload
            };
        case "SET_ListVideos":
            return {
                ...state,
                listVideos: action.payload,
                unautorizedMedia: false,
                mediaLoading: false
            };
        case "UNAUTHORIZED":
            return {
                ...state,
                unautorizedMedia: true,
                mediaLoading: false
            }
        case "SET_SUCCESS_DELETE":

            return {
                ...state
            };
        case "SET_ERROR":
            return {
                ...state,
                error: action.payload
            };

        case "SET_PaginationPropsGroup":
            const {PaginationPropsNameChange, PaginationPropsValueChange} = action.payload;
            return {
                ...state,
                paginationPropsGroup: {
                    ...state.paginationPropsGroup,
                    [PaginationPropsNameChange]: PaginationPropsValueChange
                }
            };
        case "SET_showDivsConditionsMedia":
            const {showDivsConditionsName, showDivsConditionsValue} = action.payload;
            const showDivsConditionsObj = {
                ...state.showDivsConditionsMedia,
                [showDivsConditionsName]: showDivsConditionsValue
            };
            return {
                ...state,
                showDivsConditionsMedia: showDivsConditionsObj
            };
        case "SET_AddGroupActions":
            const {addGroupConstraintsDataNameChange, addGroupConstraintsDataValueChange} = action.payload;
            return {
                ...state,
                addGroupConstraintsData: {
                    ...state.addGroupConstraintsData,
                    [addGroupConstraintsDataNameChange]: addGroupConstraintsDataValueChange
                }
            };
        case "SET_IFRAME_KEY":
            return{
                ...state,
                iframeKey:action.payload
            }

        case "SET_VIDEO_DETAIL":
            return {
                ...state,
                VideoDetails:action.payload
            }
        case "GET_ENTITIES":
            return {
                ...state,
                entities:action.payload
            } 
        case "GET_SKIN_TOKEN":
            return {
                ...state,
                tokenList:action.payload
            }       
        default:
            {
                return state;
            }
    }
};
