import { useEffect, useState } from "react";
import { Modal, Button, Tooltip } from "antd";
import UseActionMenu from "./ActionPendingTable";
import { AddHooks } from "../utils/hooks";
import { Datagrid } from "@empreinte/components.datagrid";
import { useTranslation } from "react-i18next";
// redux
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { setVisible, setVisibleDelete, setVisibleDeleteMultiple } from "../store/addMediaActions";
import { DeleteOutlined } from "@ant-design/icons";
import { ClipLoader } from "react-spinners";
import './pendingTable.scss'

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;

function useDataTablePendingVideo({ columns, dataSource, updateEntityPath }) {
    const { getPendingVideos, deletePendingVideo, deletePendingMultipleVideos } =
        AddHooks();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const linkapercu = useAppSelector(
        (state) => state.addMediaReducer.previewLink
    );
    const visible = useAppSelector((state) => state.addMediaReducer.visible);
    const videoRecords = useAppSelector(
        (state) => state.addMediaReducer.videoRecords
    );
    const visibledelete = useAppSelector(
        (state) => state.addMediaReducer.visibledelete
    );
    const visibleDeleteMultiple = useAppSelector(
        (state) => state.addMediaReducer.visibleDeleteMultiple
    );
    const file = useAppSelector((state) => state.addMediaReducer.filename);
    const clickedTab = useAppSelector((state) => state.addMediaReducer.clickedTab);
    const loading = useAppSelector(
        (state) => state.addMediaReducer.redirectDeletePendingVideo
    );
    const history = useHistory();
    const modules = useSelector((state) => state.ReducerUserData.modules);
    const isSuperAdmin = useSelector(
        (state) => state.ReducerUserData.isSuperAdmin
    );
    useEffect(() => {
        if (clickedTab == 2) getPendingVideos(currentPage, pageSize);
    }, [currentPage, pageSize, clickedTab]);
    useEffect(() => {
        if (loading) {
            history.push("/ws-media/addMedia");
        }
    }, [loading]);
    const onSelectChange = (selectedRowKeys, record) => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const updatedColumns = [
        ...columns,
        {
            headerName: "Actions",
            key: "action",
            width: 250,
            align: "center",
            renderCell: (action, record) => {
                return <UseActionMenu key={"record.id"} record={action} />;
            },
        },
    ];

    const resetPagination = () => {
        setCurrentPage(DEFAULT_PAGE_NUMBER);
    };

    const DataTable = () => (
        <div className="DataTable">
            {(modules.media_video_multi_delete || isSuperAdmin) && <Tooltip placement="leftBottom" title="Supprimer" has-permission="media_video_multi_delete"  >

                    <Button
                        type="text"
                        has-permission="media_video_multi_delete"
                        onClick={() => {
                            dispatch(setVisibleDeleteMultiple(true));
                        }
                    }
                    style={
                        {
                            display: !selectedRowKeys.length ? "none" : "inline-block"
                        }
                    }
                    icon={<DeleteOutlined />}>{selectedRowKeys?.length === 1 ? selectedRowKeys?.length  +t('PendingVideo.element') : selectedRowKeys?.length  + t('PendingVideo.elements')}</Button>
            </Tooltip>}
            <Datagrid
                getRowId={(row) => row.id}
                rows={dataSource}
                columns={updatedColumns}
                EntriesName={`${t("Podcast.entries")}`}
                OfPageName={`${t("Podcast.of")}`}
                ShowName={`${t("Podcast.Show")}`}
                totalEntities={videoRecords}
                selectionModel={selectedRowKeys}
                checkboxSelect={(ids) => {
                    setSelectedRowKeys(ids);
                }}
                height={"calc(100vh - 150px)"}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                checkboxSelection={modules?.podcast_multi_delete || isSuperAdmin}
                localeText={{
                    columnHeaderSortIconLabel: `${t("media_center.table.Sort")}`,
                }}
            />

            <Modal
                onCancel={() => dispatch(setVisibleDelete(false))}
                bodyStyle={{ height: 100 }}
                closable={true}
                width={500}
                title={t('PendingVideo.deletedOneItem')}
                open={visibledelete}
                footer={
                    [
                        <Button key="back"
                            onClick={
                                () => dispatch(setVisibleDelete(false))
                            }>{t('PendingVideo.cancel')}</Button>,
                        <Button onClick={
                            () => {
                                deletePendingVideo(file.id);
                            }
                        }
                            key="submit"
                            type="primary"
                            loading={loading}>{t('PendingVideo.delete')}</Button>,
                    ]
                }>
                <b>
                    {
                        file?.row?.fileTitle + " "
                    }</b>{t('PendingVideo.deleteContent')}
            </Modal>

            <Modal
                open={loading}
                footer={null}
                width={"auto"}
                centered
                closable={false}
            >
                <ClipLoader />

            </Modal>

            <Modal
                headerHeight="0"
                bodyStyle={{ height: "100%" }}
                closable={true}
                className="iframe-video-pending"
                onCancel={
                    () => dispatch(setVisible(false))
                }
                open={visible}
                footer={null}>
                <iframe id="iframe-player"
                    title={linkapercu}
                    className="iframe-video-pending"
                    style={
                        {
                            position: "absolute",
                            top: "0",
                            left: "0",
                            border: "0"
                        }
                    }
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    webkitallowfullscreen=""
                    mozallowfullscreen=""
                    allowFullscreen=""
                    src={linkapercu}></iframe>
            </Modal>
            <Modal onCancel={
                () => dispatch(setVisibleDeleteMultiple(false))
            }
                bodyStyle={
                    { height: 100 }
                }
                closable={true}
                width={500}
                title={selectedRowKeys.length === 1 ? t('PendingVideo.confirmOneDelete') : t('PendingVideo.confirmDelete') }
                open={visibleDeleteMultiple}
                footer={
                    [
                        <Button key="back"
                            onClick={
                                () => dispatch(setVisibleDeleteMultiple(false))
                            }>
                           {t('PendingVideo.cancel')}
                        </Button>,
                        <Button onClick={
                            () => {
                                deletePendingMultipleVideos(selectedRowKeys);
                            }
                        }
                            key="submit"
                            type="primary"
                        >
                           {t('PendingVideo.delete')}
                        </Button>,
                    ]
                }>
                <b>{selectedRowKeys.length === 1 ? t('PendingVideo.deletedOneItem') : t('PendingVideo.deletedItems')}
                </b>
            </Modal>
        </div>
    );

    return {
        DataTable,
        selectedRow,
        selectedRowKeys,
        currentPage,
        pageSize,
        resetPagination,
    };
}
export default useDataTablePendingVideo;
