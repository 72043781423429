import React from "react";
import PlayListActions from "./PlayListActions";
import { useWindowSize } from "../Utils/resizeEvent";
import { Hooks } from "../Utils/Hooks";
const BodyDemoTablesContainCheckbox = ({ data, s, check,url }) => {
  const { descriptionminimize } = Hooks();
  const capitalize = (str) => {
    return str[0].toUpperCase() + str.slice(1);
  };
  const windowSize = useWindowSize();
  const falsy = (num) => {
    if (num < 500 || (num > 900 && num < 1250)) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div
      className={`bodyDemo ${
        s === "first"
          ? "fullHeight"
          : check === 2 && data.length > 0
          ? "fullHeight"
          : ""
      } `}
    >
      <table>
        {data.length &&
          data.map((e) => (
            <tr className="displayPlayList">
              <td>
                <div className="checkBoxWithTitle">
                  <input type="checkbox" name="" id="" />
                  <span className="bigger">
                    {descriptionminimize(e.title, falsy(windowSize))}
                  </span>{" "}
                </div>
              </td>
              <td className="center">
                <span className="biggerNumber">{e.maxDuration}</span>
              </td>
              {windowSize > 500 && (
                <td className="center">
                  <span className="biggerNumber">
                    {capitalize(String(e.loopp))}
                  </span>
                </td>
              )}
              <td>
                <PlayListActions url={url} />
              </td>
            </tr>
          ))}
      </table>
    </div>
  );
};

export default BodyDemoTablesContainCheckbox;
