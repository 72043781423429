export function getListMedias(e) {
    return {type: "SET_ListMedias", payload: e};
}
export function getListVideos(e) {
    return {type: "SET_ListVideos", payload: e};
}
export function ModalToOpen(e) {
    return {type: "SET_Modal_To_Open", payload: e};
}
export function detailsEventsModal(e) {
    return {type: "SET_detail_event_To_Open", payload: e};
}
export function setSubscriptionList(e) {
    return {type: "SET_SubscriptionList", payload: e};
}
export function setTimeVideo(e) {
    return {type: "SET_videotime", payload: e};
}

export function getUrlVideos(e) {
    return {type: "SET_urlVideos", payload: e};
}

export function getUrlEditor(e) {
    return {type: "SET_urlEditor", payload: e};
}

export function getUrlMedia(e) {
    return {type: "SET_urlMedia", payload: e};
}

export function setIdClicked(e) {
    return {type: "SET_ID", payload: e};
}

export function setshowDivsConditionsMedia(e) {
    return {type: "SET_showDivsConditionsMedia", payload: e};
}

export function setAddGroupActions(e) {
    return {type: "SET_AddGroupActions", payload: e};
}

export function setFilterGroupeActions(e) {
    return {type: "SET_FilterGroup", payload: e};
}

export function deleteSubscriptionSuccess(e) {

    return {type: "SET_SUCCESS_DELETE", payload: e};
}
export function ActionError(e) {
    return {type: "SET_ERROR", payload: e};
}
export function ActioniframeKey (e){
    return {
        type:"SET_IFRAME_KEY", payload:e
    }
}

export function getVideoDetails(e) {
    return {
        type: "SET_VIDEO_DETAIL",
        payload: e
    }
}
export function getEntitiy(e){
    return{
        type:"GET_ENTITIES",
        payload:e
    }
}
export function getTokenSkin(e){
    return {
        type:"GET_SKIN_TOKEN",
        payload:e
    }
}