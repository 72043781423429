import React from "react";
import { useSelector } from "react-redux";
import "../Dashboard.scss";
import PodcastsAndVideosActions from "./PodcastsAndVideosActions";
import LivesActions from "./LivesActions";
import { useWindowSize } from "../Utils/resizeEvent";
import { Hooks } from "../Utils/Hooks";

const BodyDemoTables = ({ data, s, check, title,url }) => {
    function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  const { descriptionminimize } = Hooks();
  const windowSize = useWindowSize();
  const falsy = (num) => {
    if (num < 550 || (num > 900 && num < 1250)) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div
      className={`bodyDemo ${
        s === "first" && check <= 3
          ? "fullHeight"
          : check === 2 && data.length > 0
          ? "fullHeight"
          : ""
      } `}
    >
      {data.length &&
        data.map((e) => (
                        <div className="display">
            <img
              className="image"
              src={e?.node?.Vignette ? e?.node?.Vignette : e?.thumbnail ? e?.thumbnail : e?.logo}
              alt={e?.node?.Title || e.title }
            />
            <div className="selectinnedVideoData">
              <span>{descriptionminimize(e?.node?.Title || e.title , falsy(windowSize))}</span>
              <span className="timeDisplay">
                {e.created_at
                  ? formatDate(e.created_at):
                  e?.node?.CreatedAt
                  ? formatDate(e?.node?.CreatedAt)
                  : e.dateStart
                  ? formatDate(e.dateStart)
                  : formatDate(e.insertDate)}
              </span>
            </div>
            <div className="getOffColomun">
              {title === "Direct" ? (
                <LivesActions url={url} />
              ) : (
                <PodcastsAndVideosActions url={url} title={title} data={e} />
              )}
            </div>
          </div>
                  ))}
    </div>
  );
};

export default BodyDemoTables;
