import { createSlice } from "@reduxjs/toolkit";
import { getPlayList, getModules, DeleteMultiple } from "../actions";

export const PaginationSlice = createSlice({
  name: "Pagination",
  initialState: {
    modules: [],
    Authorized: false,
    GlobalLoading: true,
    Loading: false,
    totalCount: 0,
    pageSize: 10,
    current: 1,
    elementsSelected: [],
    desc:"desc",
    order:0
  },
  reducers: {
    CurrentChange: (state, action) => {
      state.current = action.payload;
    },
    pageSizeChange: (state, action) => {
      state.pageSize = action.payload;
    },
    elementsSelectedChange: (state, action) => {
      state.elementsSelected = action.payload;
    },
    getWithoutText: (state, action) => {
      state.Loading = true;
    },
    changeOrder:(state,action)=>{
      state.order = action.payload
    },
    changeDir: (state,action)=>{
      state.desc = action.payload
    }
  },
  extraReducers: {
    [getPlayList.pending]: (state) => {},
    [getPlayList.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.totalCount = payload.totalCount;
    },
    [getPlayList.rejected]: (state, { payload }) => {
      state.isSuccess = false;
      state.errorMessage = payload;
    },
    [getModules.pending]: (state) => {
      state.GlobalLoading = true;
    },
    [getModules.fulfilled]: (state, { payload }) => {
      state.Authorized = true;
      state.GlobalLoading = false;
      state.modules = payload.userData.modules.playlist_module;
    },
    [getModules.rejected]: (state, { payload }) => {
      state.Authorized = false;
      state.GlobalLoading = false;
    },
    [DeleteMultiple.pending]: (state) => {},
    [DeleteMultiple.fulfilled]: (state, { payload }) => {
      state.elementsSelected = [];
    },
    [DeleteMultiple.rejected]: (state, { payload }) => {
      state.errorMessage = payload;
    },
  },
});
export const {
  elementsSelectedChange,
  pageSizeChange,
  CurrentChange,
  getWithoutText,
  changeOrder,
  changeDir,
} = PaginationSlice.actions;
export default PaginationSlice.reducer;
