import { Row, Col, Card } from "antd";
import "../addMedia.scss";
import { useParams } from "react-router-dom";
import { Formeditvideo } from "./formeditvideo";
import CommonHeader from "./CommonHeader";
import { AddHooks } from "../utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { resetState } from "../store/addMediaActions";
import axios from "axios";
import LoadingScreen from "../../Podcast/utils/LoadingScreen";
export const AddPendingVideo = () => {
  const { id, filename } = useParams();
  const dispatch = useDispatch();
  const { getSecondStepUpload,
    handleChangeDate,
    onFinishAddMediaUpload,
    getVideoLink,
  } = AddHooks();
  const cancelToken = axios.CancelToken.source();
  const loadingUserData = useSelector((state) => state.ReducerUserData.loading)
  const loadingDataVideo = useSelector((state) => state.addMediaReducer.loadingVideoData)
  const videoLinkLoading = useSelector((state) => state.addMediaReducer.videoLinkLoading)


  useEffect(() => {
    getSecondStepUpload(id, filename, cancelToken);
    getVideoLink(id)
    handleChangeDate("", "startDate");
    handleChangeDate("", "endDate");
  }, [id, filename])

  useEffect(() => {
    return () => {
      dispatch(resetState())
      cancelToken.cancel("metadata request canceled");
    }
  }, [])
  if (loadingUserData || loadingDataVideo || videoLinkLoading) {
    return <div className="center">
      <LoadingScreen />
    </div>
  }

  return (
    <div>
      <Card className="cardparent">
        <Row gutter={[0, 30]}>
          <Col
            span={24}
            style={{
              padding: "1.3%",
              paddingTop: "1.3%",
              paddingBottom: "1.3%",
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            }}
          >
            <CommonHeader
              awaitThumbStatus={false}
              cancelBtnLocation={"/addmedia"}
              SubmitAction={() => {
                onFinishAddMediaUpload(id);
              }}
              hasIcons={false}
              id={id}
              filename={filename}
            />
          </Col>
          <Col span={24}>
            <Row>
              <Card style={{ width: "100%" }}>
                <Col span={24}>
                  <Formeditvideo add={true} />
                </Col>
              </Card>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
