import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Tooltip,
  Input,
  Radio,
  PageHeader,
  Row,
  Col,
} from "antd";
import {
  HomeOutlined,
  FacebookOutlined,
  LinkedinFilled,
  LinkedinOutlined,
  TwitterOutlined,
  MailFilled,
  CopyOutlined,
  ShareAltOutlined,
  FacebookFilled,
  TwitterSquareFilled,
  InstagramFilled,
} from "@ant-design/icons";

import { FacebookShareButton } from "react-share";

import { useTranslation } from "react-i18next";

const { TextArea } = Input;

function ExportMedia() {
  const { t } = useTranslation();

  const [selectedSocial, setselectedSocial] = useState(1);

  const onChange = (e) => {
    setselectedSocial(e.target.value);
  };

  return (
    <>
      <div className="export_media">
        <Breadcrumb>
          <Breadcrumb.Item href="">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="">
            {t("media_center.bread_crumb.medias")}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("media_center.bread_crumb.all")}</Breadcrumb.Item>
          <Breadcrumb.Item>
            {" "}
            {t("media_center.bread_crumb.export")}
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          className="site-page-header media_page_header"
          onBack={() => null}
          title={t("media_center.bread_crumb.export")}
        />
        <div className="media_file">
          <img
            src="https://cdn.pixabay.com/photo/2022/02/12/13/29/desert-7008952_960_720.jpg"
            className="export_file"
            alt=""
          />
          <span className="image_name">image.png</span>
        </div>
        <span className="export_link">
          {" "}
          {t("media_center.export_media.export_link")}{" "}
        </span>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12, offset: 6 }}
            lg={{ span: 12, offset: 6 }}
          >
            <div className="separator mb-16"></div>
          </Col>
          <Col
            className="mb-16"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 2, offset: 6 }}
            lg={{ span: 2, offset: 6 }}
          >
            {" "}
            <span className="export_label">
              {" "}
              {t("media_center.export_media.social_networks")}{" "}
            </span>{" "}
          </Col>
          <Col
            className="mb-16"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 10 }}
            lg={{ span: 10 }}
          >
            <Radio.Group onChange={onChange} value={selectedSocial}>
              <Radio value={1}>
                <FacebookOutlined className="social_network_icon" />
              </Radio>
              <Radio value={2}>
                <LinkedinOutlined className="social_network_icon" />
              </Radio>
              <Radio value={3}>
                <div className="social_icon">
                  <TwitterOutlined className="social_network_icon" />
                </div>
              </Radio>
              <Radio value={4}>
                <div className="social_icon">
                  <MailFilled className="social_network_icon" />
                </div>
              </Radio>
            </Radio.Group>
          </Col>
          <Col
            className="mb-16"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 2, offset: 6 }}
            lg={{ span: 2, offset: 6 }}
          >
            {" "}
            <span className="export_label">
              {" "}
              {t("media_center.export_media.permalink")}{" "}
            </span>{" "}
          </Col>
          <Col
            className="mb-16"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 10 }}
            lg={{ span: 10 }}
          >
            <Input.Group compact>
              <Input
                style={{ width: "calc(100% - 42px)" }}
                defaultValue="git@github.com:ant-design/ant-design.git"
              />
              <Tooltip title="copy git url">
                <Button
                  style={{ marginLeft: "10px" }}
                  icon={<CopyOutlined />}
                />
              </Tooltip>
            </Input.Group>
          </Col>
          <Col
            className="mb-16"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 2, offset: 6 }}
            lg={{ span: 2, offset: 6 }}
          >
            {" "}
            <span className="export_label"> Code Embed</span>{" "}
          </Col>
          <Col
            className="mb-16"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 10 }}
            lg={{ span: 10 }}
          >
            <Input.Group compact>
              <TextArea
                style={{ width: "calc(100% - 42px)" }}
                rows={4}
                defaultValue="git@github.com:ant-design/ant-design.git"
              />
              <Tooltip title="copy git url">
                <Button
                  style={{ marginLeft: "10px" }}
                  icon={<CopyOutlined />}
                />
              </Tooltip>
            </Input.Group>
          </Col>
        </Row>
        <span className="export_link">
          {" "}
          {t("media_center.export_media.share")}{" "}
        </span>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12, offset: 6 }}
            lg={{ span: 12, offset: 6 }}
          >
            <div className="separator mb-16"></div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12, offset: 6 }}
            lg={{ span: 12, offset: 6 }}
          >
            <div className="share_section">
              <ShareAltOutlined className="share_icon" />
              <span className="share_text">
                {" "}
                0 {t("media_center.export_media.sharing")}{" "}
              </span>
              <Tooltip
                placement="bottom"
                title={`${t("media_center.export_media.share_on")}facebook`}
              >
                <div className="share_button">
                  <FacebookShareButton style={{ marginTop: "5px" }}>
                    <FacebookFilled className="share_button_icon" />
                  </FacebookShareButton>
                </div>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title={`${t("media_center.export_media.share_on")}linkedin`}
              >
                <div className="share_button">
                  <FacebookShareButton style={{ marginTop: "5px" }}>
                    <LinkedinFilled className="share_button_icon" />
                  </FacebookShareButton>
                </div>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title={`${t("media_center.export_media.share_on")}twitter`}
              >
                <div className="share_button">
                  <FacebookShareButton style={{ marginTop: "5px" }}>
                    <TwitterSquareFilled className="share_button_icon" />
                  </FacebookShareButton>
                </div>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title={`${t("media_center.export_media.share_on")}instagram`}
              >
                <div className="share_button">
                  <FacebookShareButton style={{ marginTop: "5px" }}>
                    <InstagramFilled className="share_button_icon" />
                  </FacebookShareButton>
                </div>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title={`${t("media_center.export_media.share_on")}email`}
              >
                <div className="share_button">
                  <FacebookShareButton style={{ marginTop: "5px" }}>
                    <MailFilled className="share_button_icon" />
                  </FacebookShareButton>
                </div>
              </Tooltip>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default ExportMedia;
